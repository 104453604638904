import { useContext } from 'react';
import { OverlayContext } from './overlay.context';

export const useOverlayContext = () => {
    return useContext(OverlayContext);
};

/** @deprecated */
export const useOverlayRenderableElements = () => {
    const { modals, panel } = useContext(OverlayContext);

    return {
        modals,
        panel,
    };
};
