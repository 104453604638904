import { Checkbox, NdsButton, NdsInput, SidePanel } from '@gonitro/rcl';
import { NdsButtonTypesEnum, NdsInputTypes, SizesEnums } from '@gonitro/rcl/lib/_types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { ContactsApi } from '~api/contacts.api';
import Error from '~components/error';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { Permission } from '~constants';
import { countryInfoList } from '~constants/country-info';
import { useApi } from '~contexts/api';
import { useHasPermission, useUserInfo } from '~contexts/auth';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { ValidateUtils } from '~lib';
import { DateUtil } from '~lib/date.utils';
import { CountryInfo, Guid, LanguageDataLabel } from '~models';
import { ContactModel, ContactProperties } from '~models/contacts.models';
import AditionalContactProperties from './additional-contact-properties';
import OptionalFields from './optional-fields';
import './contactForm.scss';

export interface ContactFormProps {
    open: boolean;
    contact?: ContactModel;
    onClose: (data?: FormValues) => void;
}

export interface ContactCustomField {
    name: string;
    value: string;
}

export type FormValues = {
    contactId: Guid;
    emailAddress: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    title: string;
    prefix: string;
    birthDate: string;
    language: string;
    externalReference: string;
    phoneNumberCountry: string;
    isShared: boolean;
    properties: ContactProperties[];
};

function ContactForm({ onClose, open, contact }: ContactFormProps) {
    const { t } = useTranslation('contacts');
    const userInfo = useUserInfo();
    const { language } = useLanguages();
    const contactsApi = useApi(ContactsApi);
    const hasCreateSharedContactPermission = useHasPermission(Permission.General_ActionCreateSharedContact);
    const formRef = useRef<HTMLFormElement>(null);
    const [inputFields, setInputFields] = useState<ContactCustomField[]>(
        contact && contact.properties ? contact.properties : [],
    );
    const [contactCountryInfo, setContactCountryInfo] = useState<CountryInfo | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const [selectedPhoneNumberCountry, setSelectedPhoneNumberCountry] =
        useState<CountryInfo | undefined>(undefined);

    const handlePhoneNumberCountryChange = (countryInfo: CountryInfo | undefined) => {
        setSelectedPhoneNumberCountry(countryInfo);
    };

    /// Memoized function to get phone number information based on contact or userInfo
    const getPhoneNumberInfo = useMemo(() => {
        // Get the prefix either from contact or userInfo
        const prefix = contact?.phoneNumberCountry
            ? contact.phoneNumberCountry
            : userInfo?.defaultPhoneNumberCountry;

        // Check if the prefix is a countryCode or phonePrefix
        const countryPhoneInfo = countryInfoList.find(
            (el: CountryInfo) =>
                el.countryCode === prefix || el.phonePrefix === prefix || el.phonePrefix === `+${prefix}`,
        );

        // Set countryPhoneInfo if found
        setContactCountryInfo(countryPhoneInfo);

        // Checking the phone number
        if (contact?.phoneNumber && countryPhoneInfo?.phonePrefix) {
            // Normalize both phone number and phone prefix by removing any leading '+'
            const normalizedPhonePrefix = countryPhoneInfo.phonePrefix.replace('+', '');
            const normalizedPhoneNumber = contact.phoneNumber.replace('+', '');

            // Check if the phone number starts with the normalized prefix
            if (normalizedPhoneNumber.startsWith(normalizedPhonePrefix)) {
                return normalizedPhoneNumber.slice(normalizedPhonePrefix.length);
            }
        }

        return '';
    }, [contact, userInfo?.defaultPhoneNumberCountry]);

    // Initialize form methods with enhanced form and default values
    const formMethods = useEnhancedForm<FormValues>({
        mode: 'all',
        defaultValues: {
            contactId: contact?.contactId,
            language: contact ? contact?.language : language.isoCultureCode,
            emailAddress: contact?.emailAddress,
            firstName: contact?.firstName,
            lastName: contact?.lastName,
            title: contact?.title,
            birthDate: contact?.birthDate ? DateUtil.format(contact?.birthDate, 'dd/MM/yyyy') : '',
            phoneNumber: getPhoneNumberInfo,
            phoneNumberCountry: contact?.phoneNumberCountry,
            externalReference: contact?.externalReference,
            isShared: contact?.isShared,
        },
    });

    // Update/Reset form values when the modal opens
    useEffect(() => {
        setError(undefined);
        setIsLoading(false);

        // Reset form with contact's information
        if (contact) {
            formMethods.reset({
                contactId: contact.contactId,
                language: contact.language,
                emailAddress: contact.emailAddress,
                firstName: contact.firstName,
                lastName: contact.lastName,
                title: contact.title,
                birthDate: contact.birthDate ? DateUtil.format(contact.birthDate, 'dd/MM/yyyy') : '',
                phoneNumber: getPhoneNumberInfo,
                phoneNumberCountry: contact.phoneNumberCountry,
                externalReference: contact.externalReference,
                isShared: contact.isShared,
            });
            setInputFields(contact.properties ? contact.properties : []);
        } else {
            // Reset form with default values
            formMethods.reset({
                contactId: '',
                language: language.isoCultureCode,
                emailAddress: '',
                firstName: '',
                lastName: '',
                title: '',
                birthDate: '',
                phoneNumber: '',
                phoneNumberCountry: '',
                externalReference: '',
                isShared: false,
            });
            setInputFields([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact, open]);

    const {
        registerNdsInput,
        handleSubmit,
        registerCheckbox,
    } = formMethods;

    const buildPhoneNumber = (phoneNumber: string) => {
        const countryInfo = selectedPhoneNumberCountry || contactCountryInfo;

        return `${countryInfo?.phonePrefix}${phoneNumber}`;
    };

    const handleAddClick = async (formData: FormValues) => {
        setIsLoading(true);

        formData.properties = inputFields;

        // Prepend phone prefix if phone number exists
        if (formData.phoneNumber) {
            formData.phoneNumber = buildPhoneNumber(formData.phoneNumber);
        }

        if (formData.birthDate) {
            formData.birthDate = DateUtil.formatToIso(formData.birthDate);
        }

        try {
            if (contact) {
                await contactsApi.editContact(formData);
                onClose(formData);
                setError(undefined);
            } else {
                const contactId = await contactsApi.createContact(formData);

                onClose({
                    ...formData,
                    contactId: contactId.contactId.toString(),
                });
                setError(undefined);
            }
        } catch (error) {
            setError('something-went-wrong');
        } finally {
            setIsLoading(false);
        }
    };

    // Add a custom field to the form
    const handleAddCustomField = (field: ContactCustomField) => {
        setInputFields((prevFields) => [...prevFields, field]);
    };

    const handleCustomFieldInputChange = (index: number, value: string) => {
        setInputFields((prevFields) => {
            const newFields = [...prevFields];

            newFields[index].value = value;

            return newFields;
        });
    };

    // Remove a custom field from
    const handleRemoveCustomField = (index: number) => {
        setInputFields((prevFields) => {
            const newFields = [...prevFields];

            newFields.splice(index, 1);

            return newFields;
        });
    };

    const handleClose = () => {
        // Clear input custom fields and close the modal
        setInputFields([]);
        onClose();
    };

    return (
        <SidePanel
            className='edit-user-panel'
            onClosePanel={handleClose}
            footer={
                <Footer
                    contact={contact}
                    isLoading={isLoading}
                    onAddClick={() => formRef?.current?.requestSubmit()}
                    onClose={handleClose}
                />
            }
        >
            {open
                ? (
                    <div className={'c-contact-form'}>
                        <div>{error && <Error className={'c-extend-quick-action__error-text'} i18nKey={error} />}</div>
                        <Typography
                            token={TypographyToken.DesktopHeaderXs}
                            text={contact ? t('edit-contact-title') : t('add-contact-title')}
                            tagName='div'
                        />
                        <FormProvider {...formMethods}>
                            <form ref={formRef} onSubmit={handleSubmit(handleAddClick)}>
                                <div className={'c-contact-form__form'}>
                                    <NdsInput
                                        {...registerNdsInput('emailAddress', {
                                            required: true,
                                            pattern: {
                                                value: ValidateUtils.emailRegex,
                                                message: 'pattern-email',
                                            },
                                        })}
                                        label={t('email-label')}
                                        size={SizesEnums.SMALL}
                                        inputType={NdsInputTypes.TEXT}
                                        required
                                    />
                                    <NdsInput
                                        {...registerNdsInput('firstName')}
                                        label={t('first-name-label')}
                                        size={SizesEnums.SMALL}
                                        inputType={NdsInputTypes.TEXT}
                                    />
                                    <NdsInput
                                        {...registerNdsInput('lastName', { required: true })}
                                        label={t('last-name-label')}
                                        size={SizesEnums.SMALL}
                                        inputType={NdsInputTypes.TEXT}
                                        required
                                    />
                                    <div>
                                        <OptionalFields
                                            contactCountryInfo={contactCountryInfo}
                                            onPhoneNumberCountryChange={handlePhoneNumberCountryChange}
                                        />
                                    </div>
                                    <div>
                                        <AditionalContactProperties
                                            inputFields={inputFields}
                                            onAddField={handleAddCustomField}
                                            onInputChange={handleCustomFieldInputChange}
                                            onRemoveField={handleRemoveCustomField}
                                        />
                                    </div>
                                    {hasCreateSharedContactPermission && (
                                        <div className='c-contact-form__shared-wrapper'>
                                            <Checkbox size={SizesEnums.SMALL} {...registerCheckbox('isShared')} />
                                            <div className='c-contact-form__shared-label'>
                                                <Typography
                                                    token={TypographyToken.DesktopBylineSm}
                                                    text={t('shared-label')}
                                                    tagName='div'
                                                />
                                                <Typography
                                                    token={TypographyToken.UiFormsLabelXs}
                                                    text={t('shared-subtitle')}
                                                    tagName='div'
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </FormProvider>
                    </div>
                )
                : (
                    <div className='c-package-details-overview-panel__loader'>
                        <Loader center size={SizesEnums.XLARGE} />
                    </div>
                )}
        </SidePanel>
    );
}

export default ContactForm;

interface FooterProps {
    contact?: ContactModel;
    isLoading: boolean;
    onAddClick: () => void;
    onClose: () => void;
}

const Footer = ({ contact, isLoading, onAddClick, onClose }: FooterProps) => {
    const { t } = useTranslation('contacts');

    return (
        <div className={'c-contact-form__footer'}>
            <NdsButton
                size={SizesEnums.SMALL}
                label={t('cancel-button')}
                buttonType={NdsButtonTypesEnum.NEUTRAL}
                onClick={onClose}
            />
            {isLoading
                ? (
                    <div className={'c-contact-form__footer-loader'} style={{}}>
                        <Loader size={SizesEnums.SMALL} />
                    </div>
                )
                : (
                    <NdsButton
                        size={SizesEnums.SMALL}
                        label={contact ? t('save-button') : t('add-button')}
                        buttonType={NdsButtonTypesEnum.PRIMARY}
                        onClick={onAddClick}
                    />
                )}
        </div>
    );
};
