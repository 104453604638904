import './flowEditorErrors.scss';

export interface FlowEditorErrorsProps {

}

function FlowEditorErrors(props: FlowEditorErrorsProps) {
    return (
        <div className={'c-flow-editor-errors'}>
            flow-editor-errors
        </div>
    );
}

export default FlowEditorErrors;
