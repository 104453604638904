import { NdsIconFont } from '@gonitro/rcl';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { useState } from 'react';
import { useFlowContext } from '~contexts/flow';
import FlowEditorDocumentsPreview from './flow-editor-documents-preview';
import FlowEditorErrors from './flow-editor-errors';
import FlowEditorRecipients from './flow-editor-recipients';
import './flowEditorLeftPane.scss';
import './flowEditorLeftPaneComponent.scss';

export interface FlowEditorLeftPaneProps {

}

const Tabs = [
    {
        icon: 'fa-regular-users',
        Component: FlowEditorRecipients,
        IconBadge: null,
    },
    {
        icon: 'fa-regular-files',
        Component: FlowEditorDocumentsPreview,
        IconBadge: null,
    },
    {
        icon: 'fa-regular-circle-exclamation',
        Component: FlowEditorErrors,
        IconBadge: () => {
            const { hasError } = useFlowContext();

            return hasError() ? <Badge themeColor={'error'} size={'small'} style={{ position: 'absolute' }} /> : null;
        },

    },
];

function FlowEditorLeftPane(props: FlowEditorLeftPaneProps) {
    const [selectedTab, setSelectedTab] = useState(1);

    return (
        <div className={'c-flow-editor-left-pane'}>
            <div className={'c-flow-editor-left-pane__selector'}>
                <div className={'c-flow-editor-left-pane__selector-ghost'} style={{ top: `${selectedTab * 2.5}rem` }} />
                {Tabs.map(({ icon, IconBadge }, idx) => (
                    <button
                        type={'button'}
                        key={idx}
                        className={'c-flow-editor-left-pane__selector-button'}
                        onClick={() => setSelectedTab(idx)}
                        disabled={idx === selectedTab}
                    >
                        <BadgeContainer>
                            <NdsIconFont fontName={icon} />
                            {IconBadge && <IconBadge />}
                        </BadgeContainer>
                    </button>
                ))}
            </div>
            <div className={'c-flow-editor-left-pane__content'}>
                {Tabs.map(({ Component }, idx) => (
                    <div
                        key={idx}
                        className={'c-flow-editor-left-pane__content-component'}
                        style={{ left: `${selectedTab * -100}%` }}
                    >
                        <Component />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FlowEditorLeftPane;
