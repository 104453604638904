import { NdsButton } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum } from '@gonitro/rcl/lib/_types';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { ContactFullModel } from '~models/contact.model';
import { MissingFields } from '../reassignContentQuickAction';
import './selectedContactReassign.scss';


export interface SelectedContactReassignProps {
    contact: ContactFullModel;
    missingFields: MissingFields;
    handleUnselectContact: () => void;
    handleEditContact: () => void;
}

const SelectedContactReassign = ({
    contact,
    missingFields,
    handleEditContact,
    handleUnselectContact,
}: SelectedContactReassignProps) => {
    const { t: tFlow } = useTranslation('flow');
    const { t: tError } = useTranslation('error');

    return (
        <div>
            <Typography token={TypographyToken.UiFormsLabelSm} text={tFlow('select-addressbook-label')} />
            <Typography
                type='span'
                token={TypographyToken.UiFormsLabelSm}
                text={'*'}
                className='c-selected-contact-reassign__required-field'
            />
            <div className='c-selected-contact-reassign__wrapper'>
                <Typography
                    token={TypographyToken.UiFormsLabelSm}
                    text={`${contact.firstName} ${contact.lastName}`}
                    className='c-selected-contact-reassign__contact-info'
                ></Typography>
                <Typography
                    token={TypographyToken.UiFormsLabelSm}
                    text={contact.emailAddress}
                    className='c-selected-contact-reassign__contact-info'
                ></Typography>
                <div>
                    <NdsButton
                        buttonStyle={NdsButtonStylesEnum.DEFAULT}
                        buttonType={NdsButtonTypesEnum.NEUTRAL}
                        rightIcon='fa-solid-edit'
                        onClick={handleEditContact}
                    />
                    <NdsButton
                        buttonStyle={NdsButtonStylesEnum.DEFAULT}
                        buttonType={NdsButtonTypesEnum.NEUTRAL}
                        rightIcon='fa-solid-minus-circle'
                        onClick={handleUnselectContact}
                    />
                </div>
            </div>
            {!contact?.phoneNumber && (missingFields.error.length > 0 || missingFields.warning.length > 0) && (
                <Typography
                    token={TypographyToken.UiFormsLabelXs}
                    text={tError('reassign-missing-contact')}
                    tagName='div'
                    className={
                        missingFields.error.length > 0
                            ? 'c-selected-contact-reassign__phone-missing-error'
                            : 'c-selected-contact-reassign__phone-missing-warning'
                    }
                ></Typography>
            )}
        </div>
    );
};

export default SelectedContactReassign;
