import { createContext } from 'react';
import { FeatureToggle, Workspace } from './app.types';

/**
 * General context to store/provide/share some data between pages or page elements
 */
export type AppContextType = {
    actualWorkspaceId?: number;
    actualWorkspacePath?: string;
    workspaces: Workspace[],
    myWorkspace?: Workspace,
    sharedWorkspaces: Workspace[],
    expandedNavWorkspaces: number[];
    toggleNavWorkspace: (id: number) => void;
    features: FeatureToggle[];
    lastPortalPath: string;
    lastWorkspaceId: number | null;
}

export const AppContext = createContext<AppContextType>({} as AppContextType);

export default AppContext;
