import { NdsIconFont } from '@gonitro/rcl';
import './flowEditorSigningFieldProperties.scss';

export interface FlowEditorSigningFieldPropertiesProps {

}

function FlowEditorSigningFieldProperties(props: FlowEditorSigningFieldPropertiesProps) {
    return (
        <div className={'c-flow-editor-signing-field-properties c-flow-editor-right-pane-component'}>
            flow-editor-signing-field-properties
        </div>
    );
}

FlowEditorSigningFieldProperties.Header = () => {
    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                <NdsIconFont fontName={'fa-regular-signature'} />
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                Signature field
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}></div>
        </div>
    );
};
export default FlowEditorSigningFieldProperties;
