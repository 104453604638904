import { createRoutesFromElements, Navigate, Outlet, Route } from 'react-router-dom';
import { IS_DEVELOPMENT_ENV, Permission } from '~constants';
import { BaseRoutes, NestedRoutes, RouteSuffix } from '~constants/routes';
import { AuthorizedPageContainer, UnauthorizedPageContainer } from '~containers/page';
import { IfHasPermission } from '~contexts/auth/auth.components';
import { FlowContextProvider } from '~contexts/flow';
import { FlowStep, FlowType } from '~contexts/flow/flow.types';
import ContactsView from '~views/contacts';
import DashboardView from '~views/dashboard';
import ExampleView from '~views/example';
import PackageDocumentsView from '~views/flow/package-documents';
import PackageEditorView from '~views/flow/package-editor';
import PackageProcessingView from '~views/flow/package-processing';
import TemplateDocumentsView from '~views/flow/template-documents';
import TemplateEditorView from '~views/flow/template-editor';
import TemplateProcessingView from '~views/flow/template-processing';
import NotFound from '~views/not-found';
import DocumentPortalView from '~views/portals/document';
import SignerPortalView from '~views/portals/signer';
import TemplatePortalView from '~views/portals/template';
import DocumentGroupsView from '~views/portals/user-management/document-groups-view/document-groups-portal.view';
import UserGroupsView from '~views/portals/user-management/user-groups-view';
import UsersView from '~views/portals/user-management/users-view/user-management-portal.view';
import ProfileView from '~views/profile';
import ConvertLocalToCloudView from '~views/unauthorized/convert-local-to-cloud';
import LoginView from '~views/unauthorized/login';
import LostPasswordView from '~views/unauthorized/lost-password';
import RegisterView from '~views/unauthorized/register';
import RegisterCloudView from '~views/unauthorized/register-cloud';
import ResetPasswordView from '~views/unauthorized/reset-password/reset-password.view';
import { LayoutContainer } from '../layout';

const NavigateToRoot = () => <Navigate to={NestedRoutes.Users} />;
export const rootRoutes = createRoutesFromElements((
    <Route element={<Outlet />}>
        <Route element={<AuthorizedPageContainer><Outlet /></AuthorizedPageContainer>}>
            <Route element={<LayoutContainer><Outlet /></LayoutContainer>}>
                <Route index element={<DashboardView />} />
                <Route path={BaseRoutes.Contacts} Component={ContactsView} />
                <Route path={BaseRoutes.ContactGroups} Component={ContactsView} />
                <Route path={BaseRoutes.Profile} Component={ProfileView} />
                <Route path={BaseRoutes.NotFound} element={<NotFound />} />
                <Route path={BaseRoutes.NotFoundSettings} element={<NotFound type={'settings'} />} />


                <Route path={BaseRoutes.SignerPortal} Component={SignerPortalView} />
                <Route
                    path={BaseRoutes.Settings}
                    element={(
                        <IfHasPermission
                            permission={Permission.AccessUserManagementPortal}
                            noPermissionRedirectUrl={BaseRoutes.NotFoundSettings}
                        >
                            <Outlet />
                        </IfHasPermission>
                    )}
                >
                    <Route
                        path={NestedRoutes.Users}
                        element={(
                            <IfHasPermission
                                permission={Permission.AccessUserManagementPortal_ActionViewUser}
                                noPermissionRedirectUrl={BaseRoutes.NotFoundSettings}
                            >
                                <UsersView />
                            </IfHasPermission>
                        )}
                    />
                    <Route
                        path={NestedRoutes.UserGroups}
                        element={(
                            <IfHasPermission
                                permission={Permission.AccessUserManagementPortal_ActionViewUserGroup}
                                noPermissionRedirectUrl={BaseRoutes.NotFoundSettings}
                            >
                                <UserGroupsView />
                            </IfHasPermission>
                        )}
                    />
                    <Route
                        path={NestedRoutes.DocumentGroups}
                        element={(
                            <IfHasPermission
                                permission={Permission.AccessUserManagementPortal_ActionViewDocumentGroup}
                                noPermissionRedirectUrl={BaseRoutes.NotFoundSettings}
                            >
                                <DocumentGroupsView />
                            </IfHasPermission>
                        )}
                    />
                    <Route index Component={NavigateToRoot} />
                </Route>
                <Route
                    path={BaseRoutes.DocumentPortal}
                    element={(
                        <IfHasPermission
                            permission={Permission.AccessDocumentPortal}
                            noPermissionRedirectUrl={BaseRoutes.NotFound}
                        >
                            <Outlet />
                        </IfHasPermission>
                    )}
                >
                    <Route path={RouteSuffix.IdParam} Component={DocumentPortalView} />
                    <Route index Component={NotFound} />
                </Route>
                <Route
                    path={BaseRoutes.TemplatePortal}
                    element={(
                        <IfHasPermission
                            permission={Permission.AccessTemplatesPortal}
                            noPermissionRedirectUrl={BaseRoutes.NotFound}
                        >
                            <Outlet />
                        </IfHasPermission>
                    )}
                >
                    <Route path={RouteSuffix.IdParam} Component={TemplatePortalView} />
                    <Route index Component={NotFound} />
                </Route>
            </Route>
            <Route element={<LayoutContainer hideHeader hideSidebar><Outlet /></LayoutContainer>}>
                <Route
                    path={BaseRoutes.Package}
                    element={<FlowContextProvider><Outlet /></FlowContextProvider>}
                >
                    <Route
                        id={FlowType.Package + '-' + FlowStep.Documents}
                        path={`${NestedRoutes.Documents}/${RouteSuffix.IdParam}?`}
                        Component={PackageDocumentsView}
                    />
                    <Route
                        id={FlowType.Package + '-' + FlowStep.Processing}
                        path={`${NestedRoutes.Processing}/${RouteSuffix.IdParam}`}
                        Component={PackageProcessingView}
                    />
                    <Route
                        id={FlowType.Package + '-' + FlowStep.Detail}
                        path={`${NestedRoutes.Flow}/${RouteSuffix.IdParam}`}
                        Component={PackageEditorView}
                    />
                    <Route index Component={NavigateToRoot} />
                </Route>
                <Route
                    path={BaseRoutes.Template}
                    element={<FlowContextProvider><Outlet /></FlowContextProvider>}
                >
                    <Route
                        id={FlowType.Template + '-' + FlowStep.Documents}
                        path={`${NestedRoutes.Documents}/${RouteSuffix.IdParam}?`}
                        Component={TemplateDocumentsView}
                    />
                    <Route
                        id={FlowType.Template + '-' + FlowStep.Processing}
                        path={`${NestedRoutes.Processing}/${RouteSuffix.IdParam}`}
                        Component={TemplateProcessingView}
                    />
                    <Route
                        id={FlowType.Template + '-' + FlowStep.Detail}
                        path={`${NestedRoutes.Flow}/${RouteSuffix.IdParam}`}
                        Component={TemplateEditorView}
                    />
                    <Route index Component={NavigateToRoot} />
                </Route>
            </Route>
        </Route>
        <Route element={<UnauthorizedPageContainer><Outlet /></UnauthorizedPageContainer>}>
            <Route path={BaseRoutes.Login} element={<LoginView />} />
            <Route path={BaseRoutes.Register} element={<RegisterView />} />
            <Route path={BaseRoutes.RegisterCloud} element={<RegisterCloudView />} />
            <Route path={BaseRoutes.LostPassword} element={<LostPasswordView />} />
            <Route path={BaseRoutes.ResetPassword} element={<ResetPasswordView />} />
            <Route path={BaseRoutes.ConvertLocalAccountToCloud} element={<ConvertLocalToCloudView />} />
        </Route>
        {IS_DEVELOPMENT_ENV && <Route path={BaseRoutes.Example} element={<ExampleView />} />}
        <Route path={'*'} element={<Navigate to={BaseRoutes.Index} replace />} />
    </Route>
));
