import { useCallback, useMemo, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import QuickActionsModal from '~components/quick-actions-modal';
import QuickActionsTable from '~components/quick-actions-table';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { DocumentGroupPermission, FlowAction } from '~constants';
import { useApi } from '~contexts/api';
import { useWorkspaceId } from '~contexts/app';
import { useHasDocumentGroupPermissionFunc } from '~contexts/auth';
import { LanguageDataLabel } from '~models';
import { TemplateListTableDataModel } from '~models/portals.models';

export interface TemplatePortalQuickActionsProps {
    data: TemplateListTableDataModel;
    onClick: (actionType: FlowAction, data: TemplateListTableDataModel) => void;
}

type ActionFunctions = {
    [key in FlowAction]?: () => Promise<boolean>;
};

const AdditionalInformationI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
    [FlowAction.Delete]: 'delete-warning-additional-info-template',
    [FlowAction.SoftDelete]: 'delete-warning-additional-info-template',
};

const WarningMessageDialogI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
    [FlowAction.Delete]: 'delete-warning-message-template',
    [FlowAction.SoftDelete]: 'delete-warning-message-template',
};

function TemplatePortalQuickActions({ data, onClick }: TemplatePortalQuickActionsProps) {

    const portalsApi = useApi(PortalsApi);

    const workspaceId = useWorkspaceId()!;
    const hasDocGroupPermission = useHasDocumentGroupPermissionFunc();
    const [quickActionModalData, setQuickActionModalData] = useState<{
        data: TemplateListTableDataModel | undefined,
        actionType: FlowAction | undefined;
    }>({
        data: undefined,
        actionType: undefined,
    });

    const quickActions = useMemo((): QuickAction<FlowAction>[][] => {

        const parentGroup: QuickAction<FlowAction>[][] = [];
        const group1 = [];

        if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.ViewTemplate)) {
            group1.push(
                {
                    icon: 'fa-solid-arrow-right',
                    type: FlowAction.Use,
                },
            );
        }
        if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.CreateTemplate)) {
            group1.push(
                {
                    icon: 'fa-solid-edit',
                    type: FlowAction.Edit,
                },
                {
                    icon: 'fa-solid-copy',
                    type: FlowAction.Duplicate,
                },
            );
        }
        if (group1.length) {
            parentGroup.push(group1);
        }

        if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.DeleteTemplate)) {
            parentGroup.push([
                {
                    icon: 'fa-solid-trash',
                    type: FlowAction.Delete,
                },
            ]);
        }

        return parentGroup;
    }, [hasDocGroupPermission, workspaceId]);


    const handleActionClick = useCallback((actionType: FlowAction) => {
        closeModal();
        const templateId = data.templateId;
        const actionFunctions: ActionFunctions = {
            [FlowAction.Duplicate]: () => portalsApi.duplicateTemplate({ templateId }),
            [FlowAction.Delete]: () => portalsApi.deleteTemplate({ templateId }),
        };
        const actionFunc = actionFunctions[actionType];

        if (actionFunc) {
            actionFunc();
        } else {
            console.error('Unsupported action type:', actionType);
        }
    }, [data, portalsApi]);

    const QuickActionsNoModal = [FlowAction.Duplicate];

    const openQuickActionsModal = (actionType: FlowAction) => {
        if (QuickActionsNoModal.includes(actionType)) {
            handleActionClick(actionType);
        } else {
            setQuickActionModalData({
                data: data,
                actionType: actionType,
            });
        }
    };

    const closeModal = () => {
        setQuickActionModalData({
            data: undefined,
            actionType: undefined,
        });
    };

    return (
        <>
            <QuickActionsTable actions={quickActions} handleActionClick={openQuickActionsModal} />
            <QuickActionsModal
                data={quickActionModalData.data}
                actionType={quickActionModalData.actionType}
                handleCloseModal={closeModal}
                handleClickConfirm={() => {
                    handleActionClick(quickActionModalData.actionType!);
                }}
                additionalInformation={AdditionalInformationI18n[quickActionModalData.actionType!]}
                warningMessage={WarningMessageDialogI18n[quickActionModalData.actionType!]}
            />
        </>
    );
}

export default TemplatePortalQuickActions;