import { NdsMenuItem } from '@gonitro/rcl';
import { BkgModesEnum, StatesEnum } from '@gonitro/rcl/lib/_types';
import { SplitButton } from '@progress/kendo-react-buttons';
import { useMemo } from 'react';
import { getActionsFromPackageState } from 'views/portals/document/quick-action-state';
import { useTranslation } from '~contexts/i18n';
import { PackageDetailDocument, PackageDetails } from '~models/package.models';
import { PackageListItemModel } from '~models/portals.models';
import './package-details-container.scss';

export interface PackageDetailsContainerProps {
    packageDetails: PackageDetails;
    packageData: PackageListItemModel;
    setDocument: (document: any) => void;
    selectedDocument: any;
}

// TODO to be compared with handlers from in-row quickActions task
const PackageDetailsContainer = ({ packageDetails, packageData, selectedDocument, setDocument }: PackageDetailsContainerProps) => {

    const documentActions = useMemo(() => getActionsFromPackageState(packageData), [packageData]);

    // let getActionsFromPackageState return list of all possible actions but either first one or marked with extra prop 'isDefault' on object is an action for main button
    // then onButtonClick would be an event for main button and onItemClick would be an event for item. This would probably need some extra stuff to determine which button was clicked
    // ref https://www.telerik.com/kendo-react-ui/components/buttons/api/SplitButtonProps/

    const { t } = useTranslation('flow');

    return (
        <div className='c-package-details-container'>
            <h3 className='c-package-details-container__header'>{t('documents')}</h3>
            {
                packageDetails?.documents?.map((document: PackageDetailDocument) => {
                    // TODO change this to simple div when refactoring this component
                    return (
                        <div key={document.signingFlowId} className={document.signingFlowId === selectedDocument.signingFlowId ? 'c-package-details-container__item_selected' : 'c-package-details-container__item'}>

                            <NdsMenuItem
                                icon='fa-light-file'
                                primaryText={document.documentName}
                                bgMode={BkgModesEnum.LIGHT}
                                state={document.documentName === selectedDocument.documentName ? StatesEnum.SELECTED: StatesEnum.DEFAULT}
                                onClick={() => setDocument(document)}
                            />
                        </div>
                    );
                })
            }

            {/* TODO: Figure out how to change dropdown arrow icon */}
            {/* TODO: Figure out how to add separator lines between specific menu item groups */}
            {/* TODO: Fix icon margins in main button and menu items */}
            <div
                className={'c-package-details-container__menu__action-button'}
            >
                <SplitButton
                    iconClass={documentActions[0]?.icon}
                    text={t(documentActions[0]?.label)}
                    items={documentActions?.filter(action => documentActions[0]?.label !== action.label).map(({
                        icon,
                        label,
                        groupAction,
                    }) => ({
                        iconClass: icon,
                        text: t(label),
                        groupAction: groupAction,
                    }))}
                    fillMode={'outline'}
                    popupSettings={{
                        animate: false,
                        anchorAlign: {
                            horizontal: 'right',
                            vertical: 'bottom',
                        },
                        popupAlign: {
                            horizontal: 'right',
                            vertical: 'top',
                        },
                    }}
                />

            </div>

            {/* Leaving this custom component here for now as a reference */}

            {/* <div className={'c-package-details-container__menu'}> */}
            {/*     { */}
            {/*         <div */}
            {/*             className={ */}
            {/*                 buttonAction?.actionName === 'Sign' */}
            {/*                     ? */}
            {/*                     `${'c-package-details-container__menu__action-button'} ${'c-package-details-container__menu__action-button--sign'}` */}
            {/*                     : */}
            {/*                     `${'c-package-details-container__menu__action-button'}` */}
            {/*             } */}
            {/*             onClick={buttonAction?.action} */}
            {/*         > */}
            {/*             <NdsIconFont className='c-package-details-container__menu__action-button__icon' fontName={buttonAction?.icon} /> */}
            {/*             <span>{buttonAction?.actionName}</span> */}
            {/*         </div> */}
            {/*     } */}
            {/*     <NdsMenu */}
            {/*         state={StatesEnum.DEFAULT} */}
            {/*         closeOnItemClick */}
            {/*         contentAlign={RelativePositionsEnum.BOTTOM_RIGHT} */}
            {/*         open={isNdsMenuOpen} */}
            {/*     > */}
            {/*         <div */}
            {/*             className={ */}
            {/*                 buttonAction?.actionName === 'Sign' */}
            {/*                     ? */}
            {/*                     `${'c-package-details-container__menu__menu-button'} ${'c-package-details-container__menu__menu-button--sign'}` */}
            {/*                     : */}
            {/*                     `${'c-package-details-container__menu__menu-button'}` */}
            {/*             } */}
            {/*             slot={'button'} */}
            {/*             onClick={() => setIsNdsMenuOpen(!isNdsMenuOpen)} */}
            {/*         > */}
            {/*             <NdsIconFont className='c-package-details-container__menu__menu-button__icon' fontName={'fa-solid-chevron-down'} /> */}
            {/*         </div> */}
            {/*         { */}
            {/*             // Menu group for certain action types */}
            {/*             documentActions.filter(action => action.actionType === 1).length > 0 && */}
            {/*                 <NdsGroup slot={'menu'}> */}
            {/*                     { */}
            {/*                         // This prevents showing the action that is already selected and shown on the bigger button. */}
            {/*                         documentActions.filter(action => action.actionName !== buttonAction?.actionName && action.actionType === 1).map(action => { */}
            {/*                             return ( */}
            {/*                                 <NdsMenuItem */}
            {/*                                     key={action.key} */}
            {/*                                     slot={'items'} */}
            {/*                                     primaryText={action.actionName} */}
            {/*                                     onClick={() => setButtonAction(action)} */}
            {/*                                     icon={action.icon} */}
            {/*                                 /> */}
            {/*                             ); */}
            {/*                         }) */}
            {/*                     } */}
            {/*                 </NdsGroup> */}
            {/*         } */}
            {/*         { */}
            {/*             // show the divider between action groups with conditions */}
            {/*             documentActions.filter(action => action.actionType === 1).length > 0 && */}
            {/*             documentActions.filter(action => action.actionType === 2).length > 0 && */}
            {/*             documentActions.length > 1 && */}
            {/*             <NdsDivider slot={'menu'} bgMode={BkgModesEnum.LIGHT} /> */}
            {/*         } */}
            {/*         { */}
            {/*             // Menu group for certain action types */}
            {/*             documentActions.filter(action => action.actionType === 2).length > 0 && */}
            {/*             <NdsGroup slot={'menu'}> */}
            {/*                 { */}
            {/*                     // This prevents showing the action that is already selected and shown on the bigger button. */}
            {/*                     documentActions.filter(action => action.actionName !== buttonAction?.actionName && action.actionType === 2).map(action => { */}
            {/*                         return ( */}
            {/*                             <NdsMenuItem */}
            {/*                                 key={action.key} */}
            {/*                                 slot={'items'} */}
            {/*                                 primaryText={action.actionName} */}
            {/*                                 onClick={() => setButtonAction(action)} */}
            {/*                                 icon={action.icon} */}
            {/*                             /> */}
            {/*                         ); */}
            {/*                     }) */}
            {/*                 } */}
            {/*             </NdsGroup> */}
            {/*         } */}
            {/*         { */}
            {/*             // show the divider between action groups with conditions */}
            {/*             documentActions.filter(action => action.actionType === 2).length > 0 && */}
            {/*             documentActions.filter(action => action.actionType === 3).length > 0 && */}
            {/*             documentActions.length > 2 && */}
            {/*             <NdsDivider slot={'menu'} bgMode={BkgModesEnum.LIGHT} /> */}
            {/*         } */}
            {/*         { */}
            {/*             // Menu group for certain action types */}
            {/*             documentActions.filter(action => action.actionType === 3).length > 0 && */}
            {/*                 <NdsGroup slot={'menu'}> */}
            {/*                     { */}
            {/*                         // This prevents showing the action that is already selected and shown on the bigger button. */}
            {/*                         documentActions.filter(action => action.actionName !== buttonAction?.actionName && action.actionType === 3).map(action => { */}
            {/*                             return ( */}
            {/*                                 <NdsMenuItem */}
            {/*                                     key={action.key} */}
            {/*                                     slot={'items'} */}
            {/*                                     primaryText={action.actionName} */}
            {/*                                     onClick={() => setButtonAction(action)} */}
            {/*                                     icon={action.icon} */}
            {/*                                 /> */}
            {/*                             ); */}
            {/*                         }) */}
            {/*                     } */}
            {/*                 </NdsGroup> */}
            {/*         } */}
            {/*     </NdsMenu> */}
            {/* </div> */}
        </div>
    );
};
export default PackageDetailsContainer;
