import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { Navigate, useLocation, useMatch } from 'react-router-dom';
import { BaseRoutes } from '~constants/routes';
import { AppContext, AppContextType } from './app.context';
import { mapDocumentGroupsToWorkspaces } from './app.helpers';
import { FeatureToggle } from './app.types';
import { useDocumentGroups, useHasDocumentGroupPermissionFunc, useHasPermissionFunc } from '../auth';

export interface AppContextProviderProps {
    children: ReactNode;
}

export function AppContextProvider({ children, ...props }: AppContextProviderProps) {
    const location = useLocation();

    // navigation & workspaces
    const documentGroups = useDocumentGroups();
    const hasPermissionFunc = useHasPermissionFunc();
    const hasGroupPermissionFunc = useHasDocumentGroupPermissionFunc();

    const matchDocPortalWithId = useMatch(BaseRoutes.DocumentPortal + '/:id');
    const matchTemplatePortalWithId = useMatch(BaseRoutes.TemplatePortal + '/:id');

    const {
        workspaces,
        myWorkspace,
        sharedWorkspaces,
    } = useMemo(() => (
        mapDocumentGroupsToWorkspaces(
            documentGroups, hasPermissionFunc, hasGroupPermissionFunc,
        )
    ), [documentGroups, hasPermissionFunc, hasGroupPermissionFunc]);


    const actualWorkspacePath = (matchDocPortalWithId?.params.id ?? matchTemplatePortalWithId?.params.id) ?? undefined;
    const actualWorkspaceId = actualWorkspacePath ? parseInt(actualWorkspacePath.split('-')[0]) : undefined;
    const [expandedNavWorkspaces, setExpandedNavWorkspaces] = useState<number[]>(actualWorkspaceId ? [actualWorkspaceId] : []);

    const lastPortalPath = useRef<string>('/'); // used when navigating back from fullpage views
    const lastWorkspaceId = useRef<number | null>(
        actualWorkspaceId ?? // initially it takes found id if user entered page directly to some portal which is found
        (
            myWorkspace // if not it takes firstly default workspace if it exists
                ? myWorkspace.workspaceId
                : workspaces.length // or first on the other workspaces list
                    ? workspaces[0].workspaceId
                    : null // without permissions there is no id
        ),
    );

    if (matchDocPortalWithId || matchTemplatePortalWithId) {
        lastPortalPath.current = location.pathname + location.search;
    }
    if (actualWorkspaceId) {
        lastWorkspaceId.current = actualWorkspaceId;
    }

    const toggleNavWorkspace = useCallback((workspaceId: number, forceState?: boolean) => {
        // validate if workspace exist & is accessible = is defined in sharedWorkspaces
        if (!sharedWorkspaces.find(({ workspaceId: id }) => id === workspaceId)) {
            return;
        }
        setExpandedNavWorkspaces(expanded => {
            const add = () => [...expanded, workspaceId];
            const remove = () => expanded.filter((id => id !== workspaceId));

            const isExpanded = expanded.includes(workspaceId);
            const isActuallyUsed = workspaceId === actualWorkspaceId;

            if (forceState !== undefined) { // separately handle forceState
                if (isExpanded && !forceState) {
                    return remove();
                } else if (!isExpanded && forceState) {
                    return add();
                }
            }

            if (isExpanded && !isActuallyUsed) { // collapse only if not actually used
                return remove();
            } else if (!isExpanded) {
                return add();
            }

            return expanded;
        });
    }, [actualWorkspaceId, sharedWorkspaces]);

    // feature toggles... tbd when needed
    const [features] = useState<FeatureToggle[]>([]);

    const contextValue = useMemo<AppContextType>(() => ({
        actualWorkspaceId,
        actualWorkspacePath,
        workspaces,
        myWorkspace,
        sharedWorkspaces,
        expandedNavWorkspaces,
        toggleNavWorkspace,
        features,
        lastPortalPath: lastPortalPath.current,
        lastWorkspaceId: lastWorkspaceId.current,
    }), [
        actualWorkspaceId,
        actualWorkspacePath,
        expandedNavWorkspaces,
        myWorkspace,
        sharedWorkspaces,
        toggleNavWorkspace,
        workspaces,
        features,
    ]);


    if (actualWorkspaceId) {
        const base = matchDocPortalWithId ? BaseRoutes.DocumentPortal : matchTemplatePortalWithId ? BaseRoutes.TemplatePortal : undefined;

        if (!base) { // there is workspace id but route doesn't match docportal nor template portal
            return <Navigate to={{ pathname: BaseRoutes.NotFound }} />;
        }

        const found = workspaces.find(({ workspaceId }) => actualWorkspaceId === workspaceId);

        if (!found) { // workspace id not found in available workspaces so user doesn't have access to it and should be redirected to not found
            return <Navigate to={{ pathname: BaseRoutes.NotFound }} state={{ issuedWorkspaceId: actualWorkspaceId }} />;
        }

        // workspace is accessible but url seems to be different than expected. Let replace that path
        if (actualWorkspacePath !== found.workspaceIdPath) {
            return <Navigate
                to={{
                    pathname: `${base}/${found.workspaceIdPath}`,
                    search: location.search,
                }}
                replace
            />;
        }
    }

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
}
