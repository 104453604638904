import { IApi } from '~contexts/api';
import Http from '~lib/http';
import { DeleteInvitedUserModel, DeleteUserModel, GetMemberUserGroupsModel, GetUserModel, ReinviteUserModel, UpdateMemberUserGroupModel, UpdateUserGroupModel, UpdateUserModel } from './user-management.models';


export class UserManagementApi implements IApi {
    constructor(private http: Http) {
    }

    async getUser({ userId }: GetUserModel.Params, signal?: AbortSignal): Promise<GetUserModel.Result> {
        const response = await this.http
            .get(`/portalapi/v1/config/users/${userId}`)
            .execute(signal);

        const parsed = await response.jsonAs<GetUserModel.Result>();

        parsed.absencePeriods.forEach(item => {
            item.substitutes.forEach(sub => {
                sub.actionTypes = sub.actionTypes.map((str: string) => str.toUpperCase());
            });
        });

        return parsed;
    }


    async updateUser(data: UpdateUserModel.Params, signal?: AbortSignal): Promise<UpdateUserModel.Result> {
        const {
            signatureImageB64,
            avatarb64,
            cloudProviderName,
            company,
            defaultPhoneNumberCountry,
            emailAddress,
            firstName,
            lastName,
            id,
            language,
            absencePeriods,
            isActive,
        } = data;
        const response = await this.http
            .put(`/portalapi/v1/config/users/${id}`)
            .withJsonBody<UpdateUserModel.Body>({
                Avatarb64: avatarb64,
                CloudProviderName: cloudProviderName,
                SignatureImageB64: signatureImageB64,
                Company: company,
                IsActive: isActive,
                DefaultPhoneNumberCountry: defaultPhoneNumberCountry,
                Email: emailAddress,
                FirstName: firstName,
                Id: id,
                Language: language,
                LastName: lastName,
                absencePeriods: absencePeriods,
            })
            .execute(signal);

        return await response.ok;
    }

    async updateUserGroup(data: UpdateUserGroupModel.Params, signal?: AbortSignal): Promise<UpdateUserGroupModel.Result> {
        try {
            const { userGroupId, userId, isMember } = data;
            const response = await this.http
                .put(`portalapi/v1/config/usergroups/${userGroupId}/users/${userId}`)
                .withJsonBody<UpdateUserGroupModel.Body>({ isMember })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }
    async deleteUser(userId: DeleteUserModel.Params, signal?: AbortSignal): Promise<DeleteUserModel.Result> {
        try {
            const response = await this.http.delete(`/portalapi/v1/config/users/${userId.userId}`).execute(signal);

            return response.ok;
        } catch (error) {
            console.warn(error);
            throw(error);
        }
    }

    async reinviteUser(userEmail: ReinviteUserModel.Params, signal?: AbortSignal) {
        const response = await this.http.put('/portalapi/v1/config/users/invite/resend').withJsonBody<ReinviteUserModel.Body>(userEmail).execute(signal);

        return response.ok;
    }

    async deleteInvitedUser(userEmail: DeleteInvitedUserModel.Params, signal?: AbortSignal) {
        const response = await this.http.delete('/portalapi/v1/config/users/invite').withJsonBody<DeleteInvitedUserModel.Body>(userEmail).execute(signal);

        return response.ok;
    }
    async getMemberUserGroups(
        userId: GetMemberUserGroupsModel.Params, signal?: AbortSignal,
    ): Promise<GetMemberUserGroupsModel.Result> {
        try {
            const response = await this.http
                .get(`/portalapi/v1/config/users/${userId.userId}/userGroups`)
                .execute(signal);
            const parsed = await response.jsonAs<GetMemberUserGroupsModel.Result>(true);

            return parsed;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async updateMemberUserGroups(
        data: UpdateMemberUserGroupModel.Params, signal?: AbortSignal,
    ): Promise<UpdateMemberUserGroupModel.Result> {
        try {
            const { userId, userGroupIds } = data;
            const response = await this.http
                .put(`/portalapi/v1/config/users/${userId}/userGroups`)
                .withJsonBody<UpdateMemberUserGroupModel.Body>({ userGroupIds })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }
}
