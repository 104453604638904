import { NdsIconFont } from '@gonitro/rcl';
import './flowEditorCheckboxFieldProperties.scss';

export interface FlowEditorCheckboxFieldPropertiesProps {

}

function FlowEditorCheckboxFieldProperties(props: FlowEditorCheckboxFieldPropertiesProps) {
    return (
        <div className={'c-flow-editor-checkbox-field-properties c-flow-editor-right-pane-component'}>
            flow-editor-checkbox-field-properties
        </div>
    );
}

FlowEditorCheckboxFieldProperties.Header = () => {
    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                <NdsIconFont fontName={'fa-regular-square-check'} />
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                Checkbox
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}></div>
        </div>
    );
};
export default FlowEditorCheckboxFieldProperties;
