import { PropsWithChildren } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import {
    FlowFieldModel,
    FlowRadioGroupFieldModel,
    FlowRadioGroupFieldOptionModel,
    Guid,
} from '~models';
import { ReactComponent as RadioDotChecked } from '~resources/icons/field-radio-checked.svg';
import { ReactComponent as RadioDotUnchecked } from '~resources/icons/field-radio-unchecked.svg';
import './flowEditorRadioFieldObject.scss';

export interface FlowEditorRadioFieldObjectProps extends PropsWithChildren {
    id: Guid,
    data: FlowFieldModel,
}

function FlowEditorRadioFieldObject({ id, data: dataProp, children }: FlowEditorRadioFieldObjectProps) {
    const { t } = useTranslation('flow');
    const data = dataProp as FlowRadioGroupFieldModel;
    const option = data.options.find((option: FlowRadioGroupFieldOptionModel) => option.localId === id);

    return (
        <div className={'c-flow-editor-radio-field-object'}>
            <div className={'c-flow-editor-radio-field-object__ghost c-flow-editor-field-object'}>
                <div className={'c-flow-editor-radio-field-object__content'}>
                    <Typography
                        className={'c-flow-editor-field-object__text'}
                        token={TypographyToken.UiFormsLabelSm}
                        text={data.name && option?.name ? `${data.name} / ${option.name}` : t('field-object-label-radio')}
                    />
                </div>
                {children}
            </div>
            <label className={'c-flow-editor-radio-field-object__input'}>
                {option?.isSelected ? <RadioDotChecked /> : <RadioDotUnchecked />}
            </label>
        </div>
    );
}

export default FlowEditorRadioFieldObject;
