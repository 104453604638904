import { Button } from '@progress/kendo-react-buttons';
import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import { useFlowContext, useFlowState } from '~contexts/flow';
import { FlowEntityType } from '~contexts/flow/flow.types';
import { StakeholderType } from '~models';
import './flowEditorRecipients.scss';

export interface FlowEditorRecipientsProps {

}

const EditButton = ({ onClick }: { onClick: () => void }) => {
    return <Button
        style={{ marginLeft: '1rem' }}
        fillMode={'outline'}
        size={'small'}
        onClick={onClick}
    >Edit</Button>;
};

function FlowEditorRecipients(props: FlowEditorRecipientsProps) {
    const { flow, elements, stakeholders } = useFlowState();
    const { setFocusedEntity } = useFlowContext();

    return (
        <div className={'c-flow-editor-recipients'}>
            <h2>Temporary flow elements selector</h2>
            <p>Package: <EditButton onClick={() => setFocusedEntity(FlowEntityType.Package)} /></p>
            {flow.length === 0 && <p>none</p>}
            <ul style={{ listStyle: 'none' }}>
                {flow.map((step, i) => (
                    <li key={i}>
                        <h3>Step {i + 1}</h3>
                        <ul
                            style={{
                                paddingLeft: '1rem',
                                listStyle: 'none',
                            }}
                        >
                            {step.map((el) => {
                                const sInfo = stakeholders.find(({ localId }) => localId === el.stakeholderLocalId);

                                return (
                                    <li key={el.localId}>
                                        <h4
                                            style={{
                                                height: '1.75rem',
                                                lineHeight: '1.75rem',
                                            }}
                                        >
                                            {!sInfo || sInfo.type === StakeholderType.Undecided ? 'none' : sInfo.type === StakeholderType.Person ? `${sInfo.firstName ?? ''} ${sInfo.lastName}` : sInfo.name}
                                            {sInfo && sInfo.type !== StakeholderType.Undecided &&
                                                <EditButton onClick={() => setFocusedEntity(FlowEntityType.Stakeholder, sInfo.localId)} />}
                                        </h4>

                                        {el.actorType === ActorType.Approver && (
                                            <div style={{ fontSize: '.9rem' }}>
                                                type: Approver
                                                <EditButton onClick={() => setFocusedEntity(FlowEntityType.Approver, el.localId)} />
                                            </div>
                                        )}
                                        {el.actorType === ActorType.Receiver && (
                                            <div style={{ fontSize: '.9rem' }}>
                                                type: Receiver
                                            </div>
                                        )}
                                        {(el.actorType === ActorType.FormFiller || el.actorType === ActorType.Signer) && (
                                            <div>
                                                <div style={{ fontSize: '.9rem' }}>
                                                    {el.actorType === ActorType.FormFiller && 'type: Form filler'}
                                                    {el.actorType === ActorType.Signer && 'type: Signer'}
                                                </div>
                                                <ul
                                                    style={{
                                                        paddingLeft: '1rem',
                                                        listStyle: 'none',
                                                        fontSize: '.9rem',
                                                    }}
                                                >
                                                    {el.elements.map((el) => {
                                                        const det = elements.find(({ localId }) => localId === el);

                                                        let content: any = null;
                                                        switch (det?.type) {
                                                            case FieldType.TextBox:
                                                                content = <>
                                                                    TextBox: {det.name}
                                                                    <EditButton onClick={() => setFocusedEntity(FlowEntityType.TextField, el)} />
                                                                </>;
                                                                break;
                                                            case FieldType.CheckBox:
                                                                content = <>
                                                                    Checkbox: {det.name}
                                                                    <EditButton onClick={() => setFocusedEntity(FlowEntityType.CheckboxField, el)} />
                                                                </>;
                                                                break;
                                                            case FieldType.RadioGroup:
                                                                content = <>
                                                                    RadioGroup: {det.name}
                                                                    <EditButton onClick={() => setFocusedEntity(FlowEntityType.RadioField, el)} />
                                                                </>;
                                                                break;
                                                            case FieldType.SigningField:
                                                                content = <>
                                                                    SigningField
                                                                    <EditButton onClick={() => setFocusedEntity(FlowEntityType.SigningField, el)} />
                                                                </>;
                                                                break;
                                                        }

                                                        if (content) {
                                                            return <li
                                                                key={el}
                                                                style={{ padding: '.25rem 0' }}
                                                            >{content}</li>;
                                                        }

                                                        return null;
                                                    })}
                                                </ul>
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default FlowEditorRecipients;
