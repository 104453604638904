import { PathParam } from '@remix-run/router/utils';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useMatches, useNavigate } from 'react-router-dom';
import { NestedRoutes } from '~constants/routes';
import { Guid } from '~models';
import { FlowContextType, FlowStateType } from './flow.context';
import { FlowError } from './flow.error';
import { FlowEntityType, FlowNavigateToStep, FlowStep, FlowType, SetFlowFocusedEntity } from './flow.types';

export const useFlowInternalErrors = () => {
    const [errors, setErrors] = useState<FlowContextType['errors']>({});

    const setError = useCallback<FlowContextType['setError']>((error: FlowError, errorInfo: object | boolean) => {
        setErrors((errors) => (
            {
                ...errors,
                [error]: errorInfo,
            }
        ));
    }, []);
    const clearError = useCallback<FlowContextType['clearError']>((error: FlowError) => {
        setErrors((errors) => {
            const newErrors = { ...errors };

            delete newErrors[error];

            return newErrors;
        });
    }, []);

    const hasError = useCallback((error?: FlowError) => {
        if (error) {
            return Object.keys(errors).some((key) => key === error as unknown as string);
        }

        return Object.keys(errors).length > 0;

    }, [errors]);

    return {
        setError,
        clearError,
        hasError,
        errors,
    };
};


export const useFlowInternalTypeAndStep: () => { flowType: FlowType, flowStep: FlowStep } = () => {
    const matches = useMatches();

    return useMemo(() => {
        const [type, step] = [...matches].pop()!.id.split('-');

        if (!type || !step) {
            throw new Error('Invalid hook usage. Hook has to be used inside of Flow Routes');
        }

        return {
            flowType: FlowType[type as keyof typeof FlowType],
            flowStep: FlowStep[step as keyof typeof FlowStep],
        };
    }, [matches]);
};


export const useFlowInternalNavigateToStep = ({ flowState, flowTypePath }: {
    flowState: FlowStateType,
    flowTypePath: string,
}) => {
    const navigate = useNavigate();

    return useCallback<FlowNavigateToStep>((
        step,
        { state, flowId: explicitFlowId, replace } = {},
    ) => {
        const generatePathParams: Record<PathParam<'/:flowTypePath/:stepPath/:flowId?'>, string | null> = {
            flowTypePath,
            stepPath: null,
            flowId: null,
        };

        if (explicitFlowId) {
            generatePathParams.flowId = explicitFlowId;
        } else if (flowState.flowId) {
            generatePathParams.flowId = flowState.flowId;
        }

        switch (step) {
            case FlowStep.Documents:
                generatePathParams.stepPath = NestedRoutes.Documents;
                break;
            case FlowStep.Processing:
                generatePathParams.stepPath = NestedRoutes.Processing;
                break;
            case FlowStep.Detail:
                generatePathParams.stepPath = NestedRoutes.Flow;
                break;
        }
        navigate(generatePath(
            '/:flowTypePath/:stepPath/:flowId?',
            generatePathParams,
        ), {
            state,
            replace,
        });
    }, [flowState.flowId, flowTypePath, navigate]);
};

export const useFlowInternalFocusedEntity = () => {
    const [focusedEntityId, setFocusedEntityId] = useState<Guid | null>(null);
    const [focusedEntityType, setFocusedEntityType] = useState<FlowEntityType>(FlowEntityType.Package);

    const setFocusedEntity = useCallback<SetFlowFocusedEntity>((entityType, entityId) => {
        setFocusedEntityType(entityType);
        setFocusedEntityId(entityId ?? null);
    }, []);

    return {
        focusedEntityId,
        focusedEntityType,
        setFocusedEntity,
    };
};
