import { SortDirectionEnum } from '@gonitro/rcl/lib/_types';
import {
    TableColumnSpecificWidth,
    TableDefinition,
    TableFilterValueSubType,
    TableFilterValueType,
} from '~contexts/table/table.types';

export const TemplateTableDefinition: TableDefinition<'templateId'> = {
    rowIdKey: 'templateId',
    itemsPerPage: 20,
    itemsPerPageOptions: [20, 50, 100],
    initialSorting: {
        key: 'name',
        direction: SortDirectionEnum.DESC,
    },
    columns: [
        {
            key: 'name',
            dataKey: 'name',
            i18nLabel: {
                ns: 'portals',
                key: 'portal-table-col-title-name',
            },
            sortable: true,
            width: TableColumnSpecificWidth.Auto,
            useEllipsis: true,
        },
        {
            key: 'code',
            dataKey: 'code',
            i18nLabel: {
                ns: 'portals',
                key: 'portal-table-col-title-code',
            },
            width: TableColumnSpecificWidth.Auto,
            sortable: true,
            useEllipsis: true,
        },
        {
            key: 'createdOn',
            dataKey: 'createdOn',
            i18nLabel: {
                ns: 'portals',
                key: 'portal-table-col-title-added-on',
            },
            width: 20,
            sortable: true,
            useEllipsis: true,
        },
        {
            key: 'action',
            width: TableColumnSpecificWidth.Button,
        },
    ],
    filters: [
        {
            type: TableFilterValueType.Object,
            subType: TableFilterValueSubType.String,
            key: 'dateRange.start',
            queryParamKey: 'afterDate',
            apiKey: 'afterDate',
        },
        {
            type: TableFilterValueType.Object,
            subType: TableFilterValueSubType.String,
            key: 'dateRange.end',
            queryParamKey: 'beforeDate',
            apiKey: 'beforeDate',
        },
        {
            type: TableFilterValueType.Primitive,
            subType: TableFilterValueSubType.String,
            key: 'searchString',
        },
    ],
};
