import { FieldType } from '~constants/field-type';
import { FlowRadioGroupFieldModel, Guid } from '~models';
import { FlowActionType, FlowReducerFunc } from '../flow.types';

export type FlowReducerElementActionPayloads = {
    [FlowActionType.DeleteElement]: {
        localId: Guid;
        type: FieldType;
    };
}

export const DeleteElementReducer: FlowReducerFunc<FlowActionType.DeleteElement> = (
    state,
    { localId, type },
) => {
    const elements = state.elements.slice();

    if (type === FieldType.RadioGroup) { // it can remove whole element or just option
        const index = elements.findIndex((el) => {
            if (el.type !== FieldType.RadioGroup) {
                return false;
            }

            return el.localId === localId || el.options.find((opt) => opt.localId === localId);
        });

        if (index > -1) {
            const element = elements[index] as FlowRadioGroupFieldModel;

            if (element.localId === localId || element.options.length === 1) {
                elements.splice(index, 1);
            } else {
                const optionIndex = element.options.findIndex((opt) => opt.localId === localId);

                if (optionIndex > -1) {
                    const options = element.options.slice();

                    options.splice(optionIndex, 1);
                    element.options = options;
                    elements.splice(index, 1, { ...element });
                }
            }
        }
    } else {
        const index = elements.findIndex((el) => el.localId === localId);

        if (index > -1) {
            elements.splice(index, 1);
        }
    }

    return {
        ...state,
        elements,
    };
};
