import { DocumentActionType } from '~constants';
import { FlowStatusEnum } from '~constants/flow-status';
import { LanguageDataLabel } from '~models';
import { PackageListItemModel } from '~models/portals.models';

export interface QuickAction {
    icon: string;
    label: LanguageDataLabel<'flow'>;
    groupAction: string;
}

// TODO this can be used only in sidepanel and should be edited/deleted when package details panel component is updated

export const getActionsFromPackageState = (packageData: PackageListItemModel): QuickAction[] => {
    let quickActions: QuickAction[];

    const status = packageData.status;
    switch (status) {

        case FlowStatusEnum.Processing:
            quickActions = [
                {
                    icon: 'fa-solid-pencil-square',
                    label: 'action-edit',
                    groupAction: DocumentActionType.ProgressiveActions,
                },
            ];
            break;
        case FlowStatusEnum.ProcessingFailed:
        case FlowStatusEnum.Draft:
            quickActions = [
                {
                    icon: 'fa-solid-pencil-square-o',
                    label: 'action-edit',
                    groupAction: DocumentActionType.ProgressiveActions,
                },
                {
                    icon: 'fa-solid-trash',
                    label: 'action-delete',
                    groupAction: DocumentActionType.DestructiveActions,
                },
            ];
            break;
        case FlowStatusEnum.PendingApproval:
        case FlowStatusEnum.PendingFormFill:
        case FlowStatusEnum.PendingSigning:
            quickActions = [
                {
                    icon: 'fa-solid-eye',
                    label: 'action-preview',
                    groupAction: DocumentActionType.ProgressiveActions,
                },
                {
                    icon: 'fa-solid-bell',
                    label: 'action-send-reminder',
                    groupAction: DocumentActionType.ProgressiveActions,
                },
                {
                    label: 'action-reassign',
                    icon: 'fa-solid-arrow-right',
                    groupAction: DocumentActionType.ProgressiveActions,
                },
                {
                    label: 'action-request-audit-trail',
                    icon: 'fa-solid-file-lines',
                    groupAction: DocumentActionType.DownloadActions,
                },
                {
                    label: 'action-revoke',
                    icon: 'fa-solid-arrow-left-to-line',
                    groupAction: DocumentActionType.DestructiveActions,

                },
            ];

            if (status === FlowStatusEnum.PendingApproval || status === FlowStatusEnum.PendingFormFill) {
                quickActions = [
                    ...quickActions,
                    {
                        label: 'action-send-to-signers',
                        icon: 'fa-solid-paper-plane',
                        groupAction: DocumentActionType.DestructiveActions,
                    },
                ];
            }
            if (status === FlowStatusEnum.PendingSigning) {
                quickActions = [
                    ...quickActions,
                    {
                        label: 'action-sign',
                        icon: 'fa-solid-pen-nib',
                        groupAction: DocumentActionType.ProgressiveActions,
                    },
                    {
                        label: 'action-end',
                        icon: 'fa-solid-stop-circle',
                        groupAction: DocumentActionType.DestructiveActions,

                    },
                ];
            }
            break;
        case FlowStatusEnum.Archived:
        case FlowStatusEnum.Finished:
            quickActions = [
                {
                    icon: 'fa-solid-download',
                    label: 'action-download',
                    groupAction: DocumentActionType.DownloadActions,
                },
                {
                    icon: 'fa-solid-trash',
                    label: 'action-delete',
                    groupAction: DocumentActionType.DestructiveActions,
                },
            ];
            break;
        case FlowStatusEnum.Expired:
            quickActions = [
                {
                    icon: 'fa-solid-calendar-circle-plus',
                    label: 'action-extend',
                    groupAction: DocumentActionType.ProgressiveActions,
                },
                {
                    icon: 'fa-solid-file-lines',
                    label: 'action-request-audit-trail',
                    groupAction: DocumentActionType.DownloadActions,
                },
                {
                    label: 'action-revoke',
                    icon: 'fa-solid-arrow-left-to-line',
                    groupAction: DocumentActionType.DestructiveActions,

                },
            ];
            break;
        case FlowStatusEnum.Rejected:
        case FlowStatusEnum.Revoked:
            quickActions = [
                {
                    icon: 'fa-solid-file-lines',
                    label: 'action-request-audit-trail',
                    groupAction: DocumentActionType.DownloadActions,
                },
                {
                    icon: 'fa-solid-trash',
                    label: 'action-delete',
                    groupAction: DocumentActionType.DestructiveActions,
                },
            ];
            break;
        case FlowStatusEnum.Failed:
            quickActions = [
                {
                    icon: 'fa-solid-file-lines',
                    label: 'action-request-audit-trail',
                    groupAction: DocumentActionType.DownloadActions,
                },
                {
                    label: 'action-revoke',
                    icon: 'fa-solid-arrow-left-to-line',
                    groupAction: DocumentActionType.DestructiveActions,

                },
            ];
            break;
        default:
            quickActions = [];
    }

    return quickActions;
};