import { useCallback, useMemo, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import { GetActionTokensModel } from '~api/portals.models';
import QuickActionsModal from '~components/quick-actions-modal';
import QuickActionsTable from '~components/quick-actions-table';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { FlowAction, FlowStatusEnum } from '~constants';
import { useApi } from '~contexts/api';
import { useRedirectFunc } from '~hooks/redirect';
import { AppUrlsUtils } from '~lib/app-urls.utils';
import { LanguageDataLabel } from '~models';
import { SigningSessionListItemModel, SigningSessionTableDataModel } from '~models/portals.models';

export interface SignerPortalQuickActionsProps {
    data: SigningSessionTableDataModel;
}

type ActionFunctions = {
    [key in FlowAction]?: () => Promise<boolean> | Promise<void>;
};

const AdditionalInformationI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
    [FlowAction.Delete]: 'delete-warning-additional-info',
    [FlowAction.SoftDelete]: 'delete-warning-additional-info',
};

const WarningMessageDialogI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
    [FlowAction.Delete]: 'delete-warning-message',
    [FlowAction.SoftDelete]: 'delete-warning-message',
};

function SignerPortalQuickActions({ data }: SignerPortalQuickActionsProps) {
    const portalsApi = useApi(PortalsApi);
    const redirectFunc = useRedirectFunc();
    const [quickActionModalData, setQuickActionModalData] = useState<SigningSessionTableDataModel | undefined>(undefined);
    const quickActions = useMemo((): QuickAction<FlowAction>[][] => {
        switch (data.status.toUpperCase()) {
            case FlowStatusEnum.PendingFormFill:
                return [
                    [
                        {
                            icon: 'fa-solid-eye',
                            type: FlowAction.Preview,
                        },
                        {
                            icon: 'fa-solid-input-text',
                            type: FlowAction.FillOutForms,
                        },
                    ],
                ];
            case FlowStatusEnum.PendingApproval:
                return [
                    [
                        {
                            icon: 'fa-solid-eye',
                            type: FlowAction.Preview,
                        },
                        {
                            icon: 'fa-solid-circle-check',
                            type: FlowAction.Approve,
                        },
                    ],
                ];
            case FlowStatusEnum.PendingSigning:
                return [
                    [
                        {
                            icon: 'fa-solid-eye',
                            type: FlowAction.Preview,
                        },
                        {
                            icon: 'fa-solid-signature',
                            type: FlowAction.Sign,
                        },
                    ],
                ];
            case FlowStatusEnum.Received:
            case FlowStatusEnum.Archived:
            case FlowStatusEnum.Finished:
                return [
                    [
                        {
                            icon: 'fa-solid-download',
                            type: FlowAction.Download,
                        },
                    ],
                    [
                        {
                            icon: 'fa-solid-trash',
                            type: FlowAction.Delete,
                        },
                    ],
                ];
            default:
                return [
                    [
                        {
                            icon: 'fa-solid-eye',
                            type: FlowAction.Preview,
                        },
                    ],
                ];
        }
    }, [data.status]);

    const getTokensAndRedirect = useCallback(
        async (data: SigningSessionListItemModel, actionType: FlowAction) => {
            const { actionId, personId, packageId } = data;
            try {
                const actionMap: any = {
                    [FlowAction.Approve]: 'Approve',
                    [FlowAction.Sign]: 'Sign',
                    [FlowAction.FillOutForms]: 'FormFill',
                    [FlowAction.Preview]: 'Preview',
                };
                const action: string = actionMap[actionType];

                const response: GetActionTokensModel.Result = await portalsApi.getActionTokens({ actionId });

                let url = AppUrlsUtils.getBaseRedirectUrl(action, actionId, personId, response.token, response.expiryTag);

                if (actionType === FlowAction.Preview) {
                    url += '&preview=true';
                } else if (actionType === FlowAction.Download) {
                    url = AppUrlsUtils.getDownloadUrl(packageId, response.token, response.expiryTag);
                }

                redirectFunc(url);
            } catch (error) {
                console.error('Error fetching tokens:', error);
            }
        },
        [portalsApi, redirectFunc],
    );

    const runQuickActionClick = useCallback(
        (actionType: FlowAction) => {
            closeModal();
            const packageId = data.packageId;
            const actionMap: ActionFunctions = {
                [FlowAction.Delete]: () => portalsApi.softDeletePackage({ packageId }),
                [FlowAction.Download]: () => getTokensAndRedirect(data, actionType),
                [FlowAction.Approve]: () => getTokensAndRedirect(data, actionType),
                [FlowAction.FillOutForms]: () => getTokensAndRedirect(data, actionType),
                [FlowAction.Preview]: () => getTokensAndRedirect(data, actionType),
                [FlowAction.Sign]: () => getTokensAndRedirect(data, actionType),
            };
            const actionFunc = actionMap[actionType];

            if (actionFunc) {
                actionFunc().catch((error: string) => console.error('Error executing action:', error));
            } else {
                console.error('Unsupported action type:', actionType);
            }
        },
        [data, getTokensAndRedirect, portalsApi],
    );

    const handleActionClick = (actionType: FlowAction) => {
        if (actionType === FlowAction.Delete) {
            setQuickActionModalData(data);
        } else {
            runQuickActionClick(actionType);
        }
    };

    const closeModal = () => {
        setQuickActionModalData(undefined);

    };

    return (
        <>
            <QuickActionsTable actions={quickActions} handleActionClick={handleActionClick} />
            <QuickActionsModal
                data={quickActionModalData}
                actionType={FlowAction.SoftDelete}
                handleCloseModal={closeModal}
                handleClickConfirm={() => {
                    runQuickActionClick(FlowAction.Delete);
                }}
                additionalInformation={AdditionalInformationI18n[FlowAction.Delete]}
                warningMessage={WarningMessageDialogI18n[FlowAction.Delete]}
            />
        </>
    );
}

export default SignerPortalQuickActions;
