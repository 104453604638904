import { NdsIconFont } from '@gonitro/rcl';
import Typography, { TypographyToken } from '~components/typography';
import { UserGroupsListItemModel } from '~models/user-management.models';
import './user-group-item.scss';

export interface UserGroupItemProps {
    userGroup: UserGroupsListItemModel;
    handleRemoveMemberUserGroup: (id: number) => void;
}

const UserGroupItem = ({ userGroup, handleRemoveMemberUserGroup }: UserGroupItemProps) => {


    return (
        <div className='user-group-item'>
            <Typography
                className='user-group-item__list-item-name'
                token={TypographyToken.UiFormsLabelSm}
                text={`${userGroup.name}`}
            ></Typography>
            <NdsIconFont
                onClick={() => handleRemoveMemberUserGroup(userGroup.userGroupId)}
                className='user-group-item__delete-icon'
                fontName='fa-solid-minus-circle'
            />
        </div>
    );
};

export default UserGroupItem;
