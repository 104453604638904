import { LanguageDataLabel } from '~models';

export enum ContactType {
    Shared = 'shared',
    Personal = 'personal',
    Cloud = 'cloud',
}


export const ContactTypeI18n: Record<ContactType, LanguageDataLabel<'contacts'>> = {
    [ContactType.Shared]: 'contact-type-shared',
    [ContactType.Personal]: 'contact-type-personal',
    [ContactType.Cloud]: 'contact-type-cloud',
};