/* eslint-disable no-console */
import { NdsButton } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { Field } from '@progress/kendo-react-form';
import { useEffect, useState } from 'react';
import { ContactsApi } from '~api/contacts.api';
import { CloudContactProviderEnum } from '~constants/cloud-provider';
import { useApi } from '~contexts/api';
import { useSettings } from '~contexts/settings';
import ReassignCombobox from '../reassign-combobox';
import { ComboboxReassignDataModel } from '../reassignContentQuickAction';
import './selectNewContactReassign.scss';

export interface SelectNewContactReassignProps {
    contacts: ComboboxReassignDataModel[];
    tFlow: any;
    getContactBucket: (searchString: string) => void;
}

const SelectNewContactReassign = ({ contacts, tFlow, getContactBucket }: SelectNewContactReassignProps) => {
    const contactsApi = useApi(ContactsApi);
    const settings = useSettings();
    const [disableSync, setDisableSync] = useState<boolean>(false);
    const { google, microsoft } = settings.cloudSettings;

    useEffect(() => {
        if (!google.oauthSettings.isEnabled || !microsoft.oauthSettings.isEnabled) {
            setDisableSync(true);
        }
    }, [google.oauthSettings.isEnabled, microsoft.oauthSettings.isEnabled]);

    const syncContacts = async () => {
        try {
            if (google.oauthSettings.isEnabled) {
                await contactsApi.syncContacts({ contactsProvider: CloudContactProviderEnum.Google });
            }
            if (microsoft.oauthSettings.isEnabled) {
                await contactsApi.syncContacts({ contactsProvider: CloudContactProviderEnum.Microsoft });
            }
        } catch (e) {
            console.warn(e);
            throw e;
        }
    };

    return (
        <div className='c-select-new-contact-reassign__new-recipient-wrapper'>
            <Field
                name={'contactId'}
                label={tFlow('select-addressbook-label')}
                placeholder={tFlow('select-new-contact-placeholder')}
                component={ReassignCombobox}
                onChange={(e) => getContactBucket(e.value)}
                groupByField={'contactId'}
                additionalInfo={true}
                data={contacts}
                required={true}
                textField={'displayedSelection'}
            />
            <div className='c-select-new-contact-reassign__new-recipient-buttons'>
                <NdsButton
                    size={SizesEnums.XSMALL}
                    label={tFlow('reassign-sync-contacts')}
                    buttonType={NdsButtonTypesEnum.SECONDARY}
                    buttonStyle={NdsButtonStylesEnum.ALT}
                    leftIcon='fa-solid-arrows-repeat'
                    state={disableSync ? StatesEnum.DISABLED : StatesEnum.DEFAULT}
                    onClick={() => syncContacts()}
                />
                <NdsButton
                    size={SizesEnums.XSMALL}
                    label={tFlow('reassign-add-contacts')}
                    buttonType={NdsButtonTypesEnum.SECONDARY}
                    buttonStyle={NdsButtonStylesEnum.ALT}
                    leftIcon='fa-solid-plus'
                    onClick={(e) => {
                        console.log('Add contact clicked');
                    }}
                />
            </div>
        </div>
    );
};

export default SelectNewContactReassign;
