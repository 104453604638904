import { NdsIconFont } from '@gonitro/rcl';
import { ComboBox, ListItemProps } from '@progress/kendo-react-dropdowns';
import classNames from 'classnames';
import { cloneElement, ReactElement, useEffect, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import { UserManagementApi } from '~api/user-management.api';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { Permission } from '~constants';
import { useApi } from '~contexts/api';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useAsyncEffect } from '~hooks/effects';
import { useEnhancedFormContext } from '~hooks/enhanced-form/enhanced-form-context';
import { UserGroupWithMember } from '../edit-user-panel';
import UserGroupItem from './user-group-item/user-group-item';
import './user-groups-tab.scss';


export interface UserGroupsTabProps {
    userId: number;
    setUserGroups: (userGroups: UserGroupWithMember[]) => void;
    userGroups: UserGroupWithMember[];
}

const UserGroupsTab = ({ userId, setUserGroups, userGroups }: UserGroupsTabProps) => {

    const { setError, clearErrors } = useEnhancedFormContext();

    const { t } = useTranslation('user-management');
    const portalsApi = useApi(PortalsApi);
    const userManagementApi = useApi(UserManagementApi);
    const hasEditUserGroupPermission = useHasPermission(Permission.AccessUserManagementPortal_ActionEditUserGRoup);
    const [loading, setLoading] = useState(userGroups.length ? false : true);
    const [hasMembership, setHasMembership] = useState<boolean>();


    useAsyncEffect(() => async (signal) => {
        // TODO: Add lazyload
        if (!userGroups.length) {
            const userGroupResponse = await portalsApi.getUserGroups({
                page: 1,
                maxQuantity: 20,
            });

            const memberUserGroupIdResponse = await userManagementApi.getMemberUserGroups({ userId });
            const memberUserGroups = userGroupResponse.items.map((item) => {
                if (memberUserGroupIdResponse.userGroupIds.includes(item.userGroupId)) {
                    return {
                        ...item,
                        isMember: true,
                    };
                } else {
                    return {
                        ...item,
                        isMember: false,
                    };
                }
            });

            setUserGroups(memberUserGroups);
            setLoading(false);
        }
    }, [
        portalsApi,
        userId,
        userManagementApi,
        setUserGroups,
        userGroups.length,
    ]);

    useEffect(() => {
        if (userGroups.length) {
            const isMember = userGroups.some((userGroup) => userGroup.isMember);

            setHasMembership(isMember);

            if (isMember) {
                clearErrors('userGroups');
            } else {
                setError('userGroups', { message: 'must be member of a user group' });
            }
        }

    }, [clearErrors, setError, userGroups]);

    const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const item = (
            <div className={'user-group-tab__user-group-combo-list-item'}>
                <div className='user-group-tab__combo-list-item-title'>
                    <Typography
                        className='user-group-tab__combo-list-item-name'
                        token={TypographyToken.UiMenuSm}
                        text={`${itemProps.dataItem.name}`}
                    ></Typography>
                </div>
                {itemProps.dataItem.isMember
                    ? <NdsIconFont
                        className='user-group-tab__combo-check-icon'
                        fontName='fa-solid-check'
                    />
                    : null}
            </div>
        );

        return cloneElement(li, li.props, item);
    };

    const handleSelectOrRemoveMemberUserGroup = (id: number) => {
        const updatedUserGroups = userGroups.map((userGroup) => {
            if (id === userGroup.userGroupId) {
                return {
                    ...userGroup,
                    isMember: !userGroup.isMember,
                };
            } else {
                return userGroup;
            }
        });

        setUserGroups(updatedUserGroups);
    };

    return (
        loading
            ? <Loader center /> :
            <div className={classNames('user-group-tab', { 'user-group-tab--disabled': !hasEditUserGroupPermission })}>
                <Typography
                    token={TypographyToken.DesktopDescriptionSm}
                    text={t('user-groups-tab-add-access')}
                    tagName={'label'}
                    className={'user-group-tab__add-substitute-label'}
                />
                <ComboBox
                    className={classNames({ 'user-group-tab__error': !hasMembership })}
                    textField='name'
                    itemRender={itemRender}
                    data={userGroups}
                    onChange={(e) => handleSelectOrRemoveMemberUserGroup(e.target.value.userGroupId)}
                    value={null}
                />
                {!hasMembership &&
                    <Typography
                        className='user-group-tab__error-text'
                        token={TypographyToken.UiFormsLabelXs}
                        text={t('user-groups-tab-select-one-error')}
                    ></Typography>}
                <div className='user-group-tab__item-list-container'>
                    {userGroups.map((userGroup) =>
                        userGroup.isMember &&
                        <UserGroupItem
                            handleRemoveMemberUserGroup={handleSelectOrRemoveMemberUserGroup}
                            userGroup={userGroup}
                            key={userGroup.userGroupId}
                        />)}
                </div>
            </div>
    );
};

export default UserGroupsTab;
