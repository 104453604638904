import { NdsButton } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { useCallback, useRef, useState } from 'react';
import InviteUserOverlayPanel from 'views/portals/user-management/users-view/invite-users-overlay-panel/invite-users-overlay-panel';
import { UsersTableDefinition } from 'views/portals/user-management/users-view/table-definition';
import { PortalsApi } from '~api/portals.api';
import { UserManagementApi } from '~api/user-management.api';
import Content from '~components/content';
import NavTabs from '~components/nav-tabs';
import Typography, { TypographyToken } from '~components/typography';
import UserStatusBadge from '~components/user-status-badge';
import { Actions, Permission, USER_MANAGEMENT_PORTAL_TABS } from '~constants';
import { UserManagementAction } from '~constants/user-management-action';
import { UserStatusEnum } from '~constants/user-status';
import { PortalTableContainer } from '~containers/portal-table';
import { useApi } from '~contexts/api';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { TableContextProvider } from '~contexts/table';
import { FetchTableDataDelegate } from '~contexts/table/table.types';
import useNavTabs from '~hooks/tabs';
import { DateUtil } from '~lib/date.utils';
import { UsersListItemModel, UsersTableDataModel } from '~models/user-management.models';
import DeleteUserModalContent from './delete-user-modal-content/delete-user-modal-content';
import EditUserPanel from './edit-user-panel';
import { useUsersTableFilters } from './use-users-table-filters';
import UsersViewQuickActions from './users-view-quick-actions';
import useFilterTabConditions from '../hooks/useFilterTabConditions';
import UserManagementModal from '../user-management-modal/user-management-modal';
import './user-management-portal.view.scss';

export interface UserManagementViewProps {
}

function UserManagementPortalView() {
    const portalsApi = useApi(PortalsApi);
    const userManagementApi = useApi(UserManagementApi);
    const { t } = useTranslation('user-management');
    const { t: tBase } = useTranslation('base');
    const filters = useUsersTableFilters();

    const userFullNameRef = useRef<string>();
    const userEmailRef = useRef<string>('');

    const [userId, setUserId] = useState<number>();
    const [userDeleteError, setUserDeleteError] = useState<string>();
    const [isEmailPanelVisible, setEmailPanelVisible] = useState<boolean>(false);
    const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState(false);
    const [isReinviteUserModalVisible, setIsReinviteUserModalVisible] = useState(false);
    const [isInvitedUserDelete, setIsInvitedUserDelete] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [quickActionSidePanelVisible, setQuickActionSidePanelVisisble] = useState(false);

    const filterTabConditions = useFilterTabConditions();

    const { filteredTabs } = useNavTabs(USER_MANAGEMENT_PORTAL_TABS, filterTabConditions);


    const fetchData: FetchTableDataDelegate<UsersTableDataModel> = useCallback(async (params, signal?: AbortSignal) => {
        return portalsApi.getUsers({ ...params }, signal);
    }, [portalsApi]);

    const handlePanelOnClose = useCallback(() => {
        setEmailPanelVisible(false);
    }, []);

    const onClickQuickAction = useCallback(async (data: UsersListItemModel, action: UserManagementAction) => {
        const { userId, email, status, firstName, lastName } = data;

        if (action === UserManagementAction.Edit) {
            setUserId(userId);
            setQuickActionSidePanelVisisble(true);
        }
        if (action === UserManagementAction.Delete) {
            setIsDeleteUserModalVisible(true);
            setLoading(false);
            userEmailRef.current = email;

            if (status === UserStatusEnum.Invited) {
                setIsInvitedUserDelete(true);
            } else {

                userFullNameRef.current = `${firstName} ${lastName}`;
                setUserId(userId);

            }
        }
        if (action === UserManagementAction.Reinvite) {
            setLoading(false);
            userEmailRef.current = email;
            setIsReinviteUserModalVisible(true);
        }
    }, []);

    const closePanel = useCallback(() => {
        setUserId(undefined);
        setQuickActionSidePanelVisisble(false);
    }, []);

    const reinviteUserModalActionsHandler = useCallback(async (type: Actions) => {
        switch (type) {
            case Actions.CANCEL:
                setIsReinviteUserModalVisible(false);
                break;
            case Actions.PRIMARY_ACTION:
                try {
                    const response = await userManagementApi.reinviteUser({ email: userEmailRef.current });

                    setIsReinviteUserModalVisible(false);

                    return response;
                } catch (error) {
                    console.warn(error);
                }
                break;
            default:
                break;
        }
    }, [userEmailRef, userManagementApi]);

    const deleteUserModalActionsHandler = useCallback(async (type: Actions) => {

        switch (type) {
            case Actions.CANCEL:
                setUserId(undefined);
                setUserDeleteError(undefined);
                setIsDeleteUserModalVisible(false);
                setIsInvitedUserDelete(false);
                setLoading(false);

                break;
            case Actions.PRIMARY_ACTION:
                // User deletion based on userId works for active/inactive users only
                if (isInvitedUserDelete) {

                    try {
                        const response = await userManagementApi.deleteInvitedUser({ email: userEmailRef.current });

                        setIsDeleteUserModalVisible(false);
                        setIsInvitedUserDelete(false);
                        setLoading(false);

                        return response;
                    } catch (error) {
                        setLoading(false);
                        throw (error);
                    }
                }

                if (userId) {

                    try {
                        const response = await userManagementApi.deleteUser({ userId: userId });

                        setUserId(undefined);
                        setUserDeleteError(undefined);
                        setIsDeleteUserModalVisible(false);
                        setLoading(false);

                        return response;
                    } catch (error: any) {
                        const code = error.body[0]?.ErrorCode;

                        if (code === 'User.StillOwnsPackagesOrDocuments') {

                            setUserDeleteError(t('user-still-owns-one-or-more-packages-or-documents'));
                        }

                        if (code === 'User.StillOwnsTemplates') {

                            setUserDeleteError(t('user-still-owns-one-or-more-templates'));
                        }
                        setLoading(false);
                    }
                }
                break;
            default:
                break;
        }

    }, [
        isInvitedUserDelete,
        userManagementApi,
        userEmailRef,
        userId,
        t,
    ]);

    return (
        <Content noDefaultPadding>
            <TableContextProvider<'email', UsersTableDataModel>
                definition={UsersTableDefinition}
                fetchData={fetchData}
            >
                <PortalTableContainer<'email', UsersTableDataModel>
                    tabs={<NavTabs navTabs={filteredTabs} />}
                    pageTitle={t('user-management-portal-view-title-name')}
                    filtersDefinition={filters}
                    headerButton={<HeaderButton setEmailPanelVisible={setEmailPanelVisible} />}
                    renderColumns={(data) => {
                        const { email, firstName, lastName, status, language, registrationDate } = data;

                        return {
                            email: email,
                            name: `${firstName} ${lastName}`,
                            status: <UserStatusBadge status={status} />,
                            language: language,
                            registrationDate: registrationDate ? DateUtil.format(registrationDate, 'dd/MM/yyyy') : '-',
                            action: <UsersViewQuickActions
                                data={data}
                                onClickQuickAction={onClickQuickAction}
                            />,
                        };
                    }}
                />
                <OverlayPortal type={'panel'} visible={quickActionSidePanelVisible}>
                    {() => (
                        <EditUserPanel
                            userId={userId!}
                            closePanel={closePanel}
                        />
                    )}
                </OverlayPortal>
            </TableContextProvider>


            {
                //This is a fix for a bug described below
                //when you first click on the invite user --> panel appears --> cancel it --> panel disappears --> click on quick action to edit user --> panel doesn’t open --> click again on invite user --> throws the only one panel can be open at a time error
                !userId &&
                <InviteUserOverlayPanel visible={isEmailPanelVisible} onClose={handlePanelOnClose} />
            }
            {
                isReinviteUserModalVisible ?
                    <UserManagementModal
                        withClose
                        loading={loading}
                        isExtraActionModal={false}
                        isModalOpen={isReinviteUserModalVisible}
                        onButtonClick={reinviteUserModalActionsHandler}
                        modalHeaderLabel={t('resend-invitation-modal-header')}
                        modalTitleLabel={t('resend-invitation-modal-title')}
                        cancelButtonLabel={tBase('cancel')}
                        primaryActionButtonLabel={t('resend-invitation-modal-resend-button-label')}
                        modalContentComponent={
                            <div className='reinvite-user-modal-content'>
                                <Typography token={TypographyToken.UiFormsLabelSm}>
                                    {userEmailRef.current}
                                </Typography>
                            </div>
                        }
                    />
                    :
                    <UserManagementModal
                        withClose
                        loading={loading}
                        isExtraActionModal={false}
                        isModalOpen={isDeleteUserModalVisible && (!!userId || isInvitedUserDelete)}
                        onButtonClick={deleteUserModalActionsHandler}
                        modalHeaderLabel={t('delete-user-modal-header')}
                        modalTitleLabel={t('delete-user-modal-title')}
                        cancelButtonLabel={tBase('cancel')}
                        primaryActionButtonLabel={tBase('delete')}
                        modalContentComponent={
                            <DeleteUserModalContent
                                isInvitedUserDelete={isInvitedUserDelete}
                                userFullName={userFullNameRef.current}
                                userDeleteError={userDeleteError}
                                userEmail={userEmailRef.current}
                            />
                        }
                    />
            }
        </Content>
    );
}

interface HeaderButtonProps {
    setEmailPanelVisible: (isVisible: boolean) => void;
}

const HeaderButton = ({ setEmailPanelVisible }: HeaderButtonProps) => {

    const { t } = useTranslation('user-management');
    const hasInvitePermission = useHasPermission(Permission.AccessUserManagementPortal_ActionCreateUser);

    return hasInvitePermission
        ? <NdsButton
            label={t('btn-invite-user')}
            size={SizesEnums.SMALL}
            onClick={() => setEmailPanelVisible(true)}
        />
        :
        null;
};

export default UserManagementPortalView;
