import { ContactType } from '~constants';
import { ContactOrigin } from '~constants/contact-origin';
import { IApi } from '~contexts/api';
import Http from '~lib/http';
import { ContactModel } from '~models/contacts.models';
import {
    CheckStatusCloudProvider,
    CopyContactsToPersonalModel, CreateContactModel, DeleteContactModel,
    DisconnectOrSyncContactsModel,
    EditContactModel, GetContactBucketModel,
    GetContactFullInformationModel,
    GetContactModel,
    GetContactsListModel,
    ImportContactsCSVModel,
    SharePersonalContactModel,
} from './contacts.models';

export class ContactsApi implements IApi {
    constructor(private http: Http) {
    }

    async getContactBucket(
        params: GetContactBucketModel.Params,
        signal?: AbortSignal,
    ): Promise<GetContactBucketModel.Result> {
        try {
            const paramsObj: GetContactBucketModel.Params = {
                maxQuantity: params.maxQuantity,
                page: (params.page || 1) - 1, // TODO Remove || when all lazyloading is complete for this API
                contactTypes: params.contactTypes,
                searchString: params.searchString,
            };

            const response = await this.http
                .get('/portalapi/v1/contacts/contactbucket')
                .withParams(paramsObj)
                .execute(signal);

            const parsed = await response.jsonAs<GetContactBucketModel.Response>(true);

            return {
                nextPage: parseInt(parsed.continuationToken),
                items: parsed.items,
                totalItems: parsed.total,
                itemsPerPage: parsed.maxQuantity,
            };
        } catch (e) {
            console.warn('Fetching contacts error: ', e);
            throw e;
        }
    }

    async getContactsList(
        data: GetContactsListModel.Params,
        signal?: AbortSignal,
    ): Promise<GetContactsListModel.Result> {
        try {
            const response = await this.http
                .get('portalapi/v1/contacts')
                .withParams({
                    sortOrder: data.sortOrder || 'asc',
                    sortField: data.sortField || 'firstName',
                    continuationToken: data.page - 1,
                    maxQuantity: data.maxQuantity,
                    ...data.filterValues,
                })
                .execute(signal);
            const parsed = await response.jsonAs<GetContactsListModel.Response>(true);

            parsed.items.forEach((contact: ContactModel) => {
                switch (contact.origin) {
                    case ContactOrigin.Local:
                        contact.type = contact.isShared ? ContactType.Shared : ContactType.Personal;
                        break;
                    default:
                        contact.type = ContactType.Cloud;
                }
                contact.displayName = `${contact.firstName || ''} ${contact.lastName}`.trim();
            });

            return {
                nextPage: parseInt(parsed.continuationToken),
                items: parsed.items,
                totalItems: parsed.total,
                itemsPerPage: parsed.maxQuantity,
            };
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getContactFullInformation(
        data: GetContactFullInformationModel.Params,
        signal?: AbortSignal,
    ): Promise<GetContactFullInformationModel.Result> {
        try {
            const response = await this.http.get(`/portalapi/v1/contacts/${data.contactId}`).execute(signal);

            return await response.jsonAs<GetContactFullInformationModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async syncContacts(data: DisconnectOrSyncContactsModel.Params, signal?: AbortSignal): Promise<DisconnectOrSyncContactsModel.Result> {
        try {
            const response = await this.http.post(`portalapi/v1/contacts/cloud/${data.contactsProvider}/sync`).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async disconnectCloudProvider(data: DisconnectOrSyncContactsModel.Params, signal?: AbortSignal): Promise<DisconnectOrSyncContactsModel.Result> {
        try {
            const response = await this.http.delete(`/PortalApi/v1/contacts/cloud/${data.contactsProvider}`).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async importContactsCSV(
        data: ImportContactsCSVModel.Params,
        signal?: AbortSignal,
    ): Promise<ImportContactsCSVModel.Result> {
        try {
            const formData = new FormData();

            formData.append('DataFile', data.file);

            const response = await this.http
                .post('portalapi/v1/contacts/import')
                .withFormDataBody(formData)
                .resetHeader('content-type')
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async copyToPersonal(data: CopyContactsToPersonalModel.Params, signal?: AbortSignal): Promise<CopyContactsToPersonalModel.Result> {
        try {
            const response = await this.http
                .post('portalApi/v1/contacts/copy')
                .withJsonBody({ ContactsToCopy: data.ids })
                .execute(signal);

            return await response.jsonAs<CopyContactsToPersonalModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async createContact(data: CreateContactModel.Params, signal?: AbortSignal): Promise<CreateContactModel.Result> {
        try {
            const {
                phoneNumber,
                language,
                emailAddress,
                title,
                firstName,
                lastName,
                birthDate,
                externalReference,
                properties,
                isShared,
            } = data;
            const response = await this.http
                .post('/portalapi/v1/contacts')
                .withJsonBody<CreateContactModel.Body>({
                    PhoneNumber: phoneNumber,
                    Language: language,
                    EmailAddress: emailAddress,
                    Title: title,
                    FirstName: firstName !== '' ? firstName : null,
                    LastName: lastName,
                    BirthDate: birthDate,
                    ExternalReference: externalReference,
                    Properties: properties,
                    IsShared: isShared,
                })
                .execute(signal);

            return await response.jsonAs<CreateContactModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async sharePersonalContact(data: SharePersonalContactModel.Params, signal?: AbortSignal): Promise<SharePersonalContactModel.Result> {
        try {
            const response = await this.http
                .post('portalApi/v1/contacts/share')
                .withJsonBody({ ContactsToShare: data.ids })
                .execute(signal);

            return await response.jsonAs<SharePersonalContactModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async editContact(data: EditContactModel.Params, signal?: AbortSignal): Promise<EditContactModel.Result> {
        try {
            const {
                contactId,
                phoneNumber,
                phoneNumberCountry,
                language,
                emailAddress,
                title,
                firstName,
                lastName,
                birthDate,
                externalReference,
                properties,
                isShared,
            } = data;
            const response = await this.http
                .put(`portalapi/v1/contacts/${contactId}`)
                .withJsonBody<EditContactModel.Body>({
                    ContactId: contactId,
                    PhoneNumber: phoneNumber,
                    PhoneNumberCountry: phoneNumberCountry,
                    Language: language,
                    EmailAddress: emailAddress,
                    Title: title,
                    FirstName: firstName !== '' ? firstName : null,
                    LastName: lastName,
                    BirthDate: birthDate,
                    ExternalReference: externalReference,
                    Properties: properties,
                    IsShared: isShared,
                })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getContact(
        data: GetContactModel.Params,
        signal?: AbortSignal,
    ): Promise<GetContactModel.Result> {
        try {
            const response = await this.http.get(`portalapi/v1/contacts/${data.contactId}`).execute(signal);

            return await response.jsonAs<GetContactModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async deleteContact(data: DeleteContactModel.Params, signal?: AbortSignal): Promise<DeleteContactModel.Result> {
        try {
            const response = await this.http.delete(`portalapi/v1/contacts/${data.id}`).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async checkStatusCloudProvider(data: CheckStatusCloudProvider.Params, signal?: AbortSignal): Promise<CheckStatusCloudProvider.Result> {
        try {
            const response = await this.http.get(`/portalapi/v1/contacts/cloud/${data.contactsProvider}/status`).execute(signal);

            return await response.jsonAs<CheckStatusCloudProvider.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    /// $ADD_API_TEMPLATE_MARKER
}
