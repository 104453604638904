import { NdsButton, NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { TableContextProvider, useTableSelections } from 'contexts/table';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PortalsApi } from '~api/portals.api';
import Content from '~components/content';
import FlowStatusBadge from '~components/flow-status-badge';
import PackageDetailsOverviewPanel from '~components/package-details-overview-panel';
import { DocumentGroupPermission, FlowAction } from '~constants';
import { BaseRoutes, NestedRoutes } from '~constants/routes';
import { PortalTableContainer } from '~containers/portal-table';
import { useApi } from '~contexts/api';
import { useActualWorkspace, useWorkspaceId } from '~contexts/app';
import { useHasDocumentGroupPermissionFunc } from '~contexts/auth';
import { useCurrentLanguage, useTranslation } from '~contexts/i18n';
import { FetchTableDataDelegate } from '~contexts/table/table.types';
import { DateUtil } from '~lib/date.utils';
import { Guid } from '~models';
import { PackagesTableDataModel } from '~models/portals.models';
import { useBulkActionsDefinition } from './bulk-action-definition';
import DocumentPortalBulkModal, { DocumentPortalBulkActionData } from './document-portal-bulk-modal/documentPortalBulkModal';
import DocumentPortalQuickActions from './document-portal-quick-actions';
import NoDocumentsCreatedYet from './no-documents-created-yet';
import { PackagesTableDefinition } from './table-definition';
import { usePackagesTableFilters } from './use-package-table-filters';
import PortalBulkActionsHeaderButton from '../../../components/portal-header-button';


export interface DocumentPortalViewProps {
}

export default function DocumentPortalView() {
    const workspace = useActualWorkspace()!;
    const portalsApi = useApi(PortalsApi);
    const { languageCode } = useCurrentLanguage();
    const { t } = useTranslation('portals');

    const fetchData: FetchTableDataDelegate<PackagesTableDataModel> = useCallback(async (params, signal?: AbortSignal) => {
        return portalsApi.getPackagesList({
            workspaceId: workspace.workspaceId,
            language: languageCode,
            ...params,
        }, signal);
    }, [languageCode, portalsApi, workspace]);

    const filters = usePackagesTableFilters();
    const [bulkActionData, setBulkActionData] = useState<DocumentPortalBulkActionData>();

    const onBulkActionClick = useCallback((action: FlowAction, ids: Guid[]) => {
        setBulkActionData({
            action,
            ids,
        });
    }, []);

    const onQuickActionClick = useCallback((data: PackagesTableDataModel, action: FlowAction) => {
        if (action === FlowAction.Revoke) {
            // Revoke modal open
        } else if (action === FlowAction.Extend) {
            // open extend modal
        }
    }, []);

    const pageTitle = useMemo(() => {
        if (workspace.isDefault) {
            return t('title-my-documents');
        } else {
            return workspace.workspaceName;
        }
    }, [t, workspace.isDefault, workspace.workspaceName]);

    const renderSharedWith = useCallback((stakeholderNames: string[] | undefined) => {
        // this check is just for env's still on v7 remove when eveyone is updated
        if (!stakeholderNames) {
            return '-';
        }
        if (stakeholderNames.length === 1) {
            return stakeholderNames[0];
        }
        if (!stakeholderNames.length) {
            return '';
        }
        if (stakeholderNames.length > 1) {
            return <Tooltip
                content={
                    () => stakeholderNames.map((stakeholder, idx) =>
                        <div
                            style={{ padding: '0.25rem' }}
                            key={idx}
                        >{stakeholder}
                        </div>)}
                anchorElement='target'
                position='left'
                parentTitle={true}
                style={{ maxWidth: '260px' }}
            >
                <div>
                    {stakeholderNames.length} {t('portal-table-shared-with-people')}
                </div>
            </Tooltip>;
        }
    }, [t]);

    return (
        <Content noDefaultPadding>
            <TableContextProvider<'packageId', PackagesTableDataModel>
                definition={PackagesTableDefinition}
                fetchData={fetchData}
            >
                <DocumentPortalBulkModal
                    bulkActionData={bulkActionData}
                    onModalClosed={() =>
                        setBulkActionData(undefined)
                    }
                />
                <PortalTableContainer<'packageId', PackagesTableDataModel>
                    pageTitle={pageTitle}
                    filtersDefinition={filters}
                    headerButton={
                        <HeaderButton onBulkActionClick={onBulkActionClick} />
                    }
                    renderColumns={(data) => {

                        const { status, createdOn, isActionNeeded, stakeholderNames } = data;

                        return {
                            sharedWith: renderSharedWith(stakeholderNames),
                            createdOn: DateUtil.format(createdOn, 'dd/MM/yyyy'),
                            status: <><FlowStatusBadge status={status} />{isActionNeeded && <ActionNeededTooltip />}</>,
                            reassignedAndSubstitutes: (
                                <>
                                    {data.isReassigned && (
                                        <NdsIconFont fontName={'fa-solid-turn-down-left'} size={SizesEnums.SMALL} />
                                    )}
                                    {data.hasActiveSubstitutes && (
                                        <NdsIconFont fontName={'fa-solid-calendar-clock'} size={SizesEnums.SMALL} />
                                    )}
                                </>
                            ),
                            action: <DocumentPortalQuickActions
                                data={data}
                                onClickQuickAction={(data, actionType) => onQuickActionClick(data, actionType)}
                            />,


                        };
                    }}
                    renderNoItemsYet={<NoDocumentsCreatedYet />}
                />
                <PackageDetailsOverviewPanel />
            </TableContextProvider>
        </Content>
    );
}

const HeaderButton = ({ onBulkActionClick }: { onBulkActionClick: (type: FlowAction, ids: Guid[]) => void; }) => {
    const { selectedRows } = useTableSelections();
    const bulkActionsDef = useBulkActionsDefinition();
    const { t } = useTranslation('portals');
    const workspaceId = useWorkspaceId()!;
    const hasDocGroupPermission = useHasDocumentGroupPermissionFunc();

    return selectedRows.length
        ? (
            <PortalBulkActionsHeaderButton<'packageId', PackagesTableDataModel>
                actionsDef={bulkActionsDef!}
                onClick={onBulkActionClick}
            />
        )
        : (
            hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)
                ? (
                    <Link
                        to={`${BaseRoutes.Package}/${NestedRoutes.Documents}`}
                        state={{ workspaceId }}
                    >
                        <NdsButton
                            label={t('btn-upload-documents')}
                            size={SizesEnums.SMALL}
                        />
                    </Link>
                )
                : null
        );

};

const ActionNeededTooltip = () => {
    const { t } = useTranslation('flow');

    return (
        <Tooltip
            anchorElement='target'
            position='left'
            parentTitle={true}
            style={{ maxWidth: '260px' }}
        >
            <div title={t('need-action-modal-description')}>
                <NdsIconFont
                    size={SizesEnums.SMALL}
                    fontName='fa-solid-info-circle'
                />
            </div>
        </Tooltip>
    );
};
