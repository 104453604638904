import { DateTime } from 'luxon';

export namespace ValidateUtils {
    export function email(value: string): { email?: true; } {
        if (value && emailRegex.test(value)) {
            return { email: true };
        }

        return {};
    }

    //TODO understand if it's necessary the '\ before [\]'
    // eslint-disable-next-line no-useless-escape
    export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    export const passwordMinLength = 8;
    export const passwordMaxLength = 127;
    export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d])/;
    export const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(18[0-9]{2}|19[0-9]{2}|20[0-9]{2}|2100)$/;
    export const hourRegex = /^(0[0-9]|1[0-9]|2[0-3])$/;
    export const minuteRegex = /^([0-5][0-9])$/;

    export function dateNotInPast(date: Date, time?: Date): { dateNotInPast?: true; } {
        if (!date) {
            return {};
        }
        let now = DateTime.now();

        let dateTime = DateTime.fromJSDate(date);

        if (!dateTime.isValid) {
            return {};
        }

        if (time) {
            dateTime = dateTime.set({
                hour: time.getHours(),
                minute: time.getMinutes(),
            }).startOf('minute');
            now = now.startOf('minute');
        } else {
            dateTime = dateTime.startOf('day');
            now = now.startOf('day');
        }

        if (dateTime >= now) {
            return { dateNotInPast: true };
        }

        return {};
    }

    export type DateOrDateTime = Date | { date?: Date, time?: Date };

    export function dateGreaterThanDate(dateBefore: DateOrDateTime, dateAfter: DateOrDateTime): {
        dateGreaterThanDate?: true;
    } {
        if (!dateBefore || !dateAfter) {
            return {};
        }
        let before: DateTime;
        let after: DateTime;

        if (dateBefore instanceof Date) {
            before = DateTime.fromJSDate(dateBefore).startOf('minute');
        } else {
            if (!dateBefore.date || !dateBefore.time) {
                return {};
            }
            before = DateTime.fromJSDate(dateBefore.date).set({
                hour: dateBefore.time.getHours(),
                minute: dateBefore.time.getMinutes(),
            }).startOf('minute');
        }

        if (dateAfter instanceof Date) {
            after = DateTime.fromJSDate(dateAfter).startOf('minute');
        } else {
            if (!dateAfter.date || !dateAfter.time) {
                return {};
            }
            after = DateTime.fromJSDate(dateAfter.date).set({
                hour: dateAfter.time.getHours(),
                minute: dateAfter.time.getMinutes(),
            }).startOf('minute');
        }

        if (before < after) {
            return { dateGreaterThanDate: true };
        }

        return {};
    }
    export const documentNameMinLength = 1;
    export const documentNameMaxLength = 150;
}
