import { Language } from './language.models';
import { Guid } from './type';

export enum StakeholderType {
    Undecided = 'Undecided',
    Person = 'Person',
    Group = 'Group',
}

export type StakeholderUndecidedModel = {
    id: Guid;
    type: StakeholderType.Undecided;
}
export type StakeholderPersonModel = {
    id: Guid | null;
    type: StakeholderType.Person;
    emailAddress: string;
    title?: string;
    firstName?: string;
    lastName: string;
    language: Language;
    phoneNumber?: string;
    birthDate: string;
    properties: Record<string, string>;
}

export type StakeholderContactModel = StakeholderPersonModel & {
    contactId: number;
    isShared: boolean;
    permission: 'Edit' | 'View';
}
export type StakeholderGroupModel = {
    id: Guid | null;
    type: StakeholderType.Group;
    name: string;
    members: StakeholderGroupMembersModel[];
}
export type StakeholderContactGroupModel = StakeholderGroupModel & {
    contactGroupId: number;
    isShared: boolean;
    permission: 'Edit' | 'View';
}

export type StakeholderModel =
    StakeholderUndecidedModel
    | StakeholderPersonModel
    | StakeholderContactModel
    | StakeholderGroupModel
    | StakeholderContactGroupModel;

export type StakeholderGroupMembersModel = {
    contactId: number;
    isShared?: boolean;
    title?: string;
    emailAddress: string;
    firstName?: string;
    lastName: string;
    language: Language;
    phoneNumber?: string;
    birthDate: string;
    properties: Record<string, string>;
}
