import { useContext } from 'react';
import { FlowContext } from './flow.context';

export const useFlowContext = () => {
    return useContext(FlowContext);
};

export const useFlowState = () => {
    return useContext(FlowContext).flowState;
};

export const useSetFlowState = () => {
    return useContext(FlowContext).setFlowState;
};

export const useFlowProcessing = () => {
    const ctx = useContext(FlowContext);

    return {
        isProcessing: ctx.isProcessing,
        setProcessing: ctx.setProcessing,
    };
};
