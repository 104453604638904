import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { PropsWithChildren, Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useMatch } from 'react-router-dom';
import Loader from '~components/loader';
import { ApiErrors } from '~constants';
import { useAsyncEffect } from '~hooks/effects';
import { useLocationState } from '~hooks/router';
import { HttpError } from '~lib/http/http.error';
import { FlowDetailDocument } from '~models';
import { useFlowApiWrapper } from './flow.api-wrapper';
import { FlowContext, FlowContextType, FlowDocumentViewerConfigType, FlowStateType } from './flow.context';
import { useFlowDispatcher } from './flow.dispatcher';
import { useFlowInternalStakeholderColors } from './flow.hooks-internal';
import {
    useFlowInternalErrors,
    useFlowInternalFocusedEntity,
    useFlowInternalNavigateToStep,
    useFlowInternalTypeAndStep,
} from './flow.hooks.internal';
import { flowReducer } from './flow.reducer';
import { FlowActionType, FlowStateAction, FlowStep } from './flow.types';
import { useLastWorkspaceId } from '../app';

export interface FlowContextProviderProps extends PropsWithChildren {
}


export function FlowContextProvider({ children }: FlowContextProviderProps) {
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(false);
    const [isProcessing, setProcessing] = useState<boolean>(false);

    const historyState = useLocationState<{ isActionNeeded?: boolean }>();

    const { flowType, flowStep } = useFlowInternalTypeAndStep();
    const { typePath, id } = useMatch('/:typePath/:stepPath/:id?')!.params;

    const [isActionNeeded, setIsActionNeeded] = useState<boolean>(false);
    const [visibleDocument, setVisibleDocument] = useState<FlowDetailDocument>();

    const lastWorkspaceId = useLastWorkspaceId()!; // if null it means there is no permission so page cannot be entered = it's always there

    const [flowState, dispatch] = useReducer<Reducer<FlowStateType, FlowStateAction>>(flowReducer, {
        flowId: id,
        workspaceId: lastWorkspaceId,
        infoDocuments: [],
        deletedDocuments: [],
        detailDocuments: [],
        isLegalNoticeEnabled: false,
        legalNotices: [],
        flow: [],
        elements: [],
        stakeholders: [],
    });

    const api = useFlowApiWrapper(flowType, flowState.flowId);
    const setFlowState = useFlowDispatcher(dispatch);

    const { errors, setError, clearError, hasError } = useFlowInternalErrors();

    const navigateToStep = useFlowInternalNavigateToStep({
        flowState,
        flowTypePath: typePath!,
    });

    const [documentViewerConfig, setDocumentViewerConfig] = useState<FlowDocumentViewerConfigType>({
        zoomLevel: 100,
        justifyPages: true,
        adjustViewerToScreen: true,
    });
    const setDocumentViewerConfigCallback = useCallback((config: Partial<FlowDocumentViewerConfigType>) => {
        setDocumentViewerConfig(current => ({
            ...current,
            ...config,
        }));
    }, []);

    const stakeholderColors = useFlowInternalStakeholderColors(flowState.flowId, flowState.stakeholders);

    const {
        focusedEntityId,
        focusedEntityType,
        setFocusedEntity,
    } = useFlowInternalFocusedEntity();

    useEffect(() => {
        setIsReady(false);
    }, [flowStep, historyState.isActionNeeded]);

    useEffect(() => {
        if (id && id !== flowState.flowId) {
            setFlowState(FlowActionType.SetFlowId, id);
            setIsReady(false);
        }
        if (id || flowState.flowId) {
            setIsNew(false);
        }
    }, [flowState.flowId, id, setFlowState]);

    useEffect(() => {
        if (!flowState.flowId && flowStep) {
            setIsNew(true);
            setIsReady(true);
        }
    }, [flowState.flowId, flowStep]);

    const fetchBasicData = useCallback(async (signal?: AbortSignal) => {
        if (flowStep === FlowStep.Documents) {
            try {
                const info = await api.getBasicInfo(signal);

                setFlowState(FlowActionType.SetDocuments, info.documents);
                setIsReady(true);
            } catch (e) {
                if (signal?.aborted) {
                    return;
                }
                if (e instanceof HttpError && e.getErrorCode() === ApiErrors.PackageInvalidStatusProcessing) {
                    navigateToStep(FlowStep.Processing);

                    return;
                }
                throw e;
            }

        } else if (flowStep === FlowStep.Detail) {
            if (historyState.isActionNeeded) {
                setIsActionNeeded(true);
                setIsReady(true);

                return;
            } else {
                setIsActionNeeded(false);
            }
            try {
                const result = await Promise.all([
                    api.getDetails(signal),
                    api.getAvailableLegalNotices(signal),
                    api.getStakeholders(signal),
                    api.getWorkflow(signal),
                    api.getElements(signal),
                ]);

                setFlowState(FlowActionType.SetInitialDetails, result[0]);
                setFlowState(FlowActionType.SetInitialLegalNoticeConfig, result[1]);
                setFlowState(FlowActionType.SetInitialFlow, {
                    stakeholders: result[2],
                    workflow: result[3].queryResult,
                    elements: result[4],
                });

                setIsReady(true);
            } catch (e) {
                if (signal?.aborted) {
                    return;
                }
                const code = e instanceof HttpError && e.getErrorCode();

                if (code === ApiErrors.PackageInvalidStatusProcessingFailed) {
                    navigateToStep(FlowStep.Documents);
                } else {
                    throw e;
                }
            }

        } else {
            setIsReady(true);
        }
    }, [
        api,
        flowStep,
        historyState.isActionNeeded,
        navigateToStep,
        setFlowState,
    ]);

    useAsyncEffect(() => async (signal: AbortSignal) => {
        if (isReady || !flowState.flowId) {
            return;
        }
        await fetchBasicData(signal);
    }, [fetchBasicData, flowState.flowId, isReady]);

    const contextValue = useMemo<FlowContextType>(() => ({
        isNew,
        isProcessing,
        setProcessing,
        isActionNeeded,
        flowState,
        setFlowState,
        api,
        errors,
        setError,
        clearError,
        hasError,
        step: flowStep,
        navigateToStep,
        refreshData: fetchBasicData,
        focusedEntityId,
        focusedEntityType,
        setFocusedEntity,
        visibleDocument,
        setVisibleDocument,
        documentViewerConfig,
        setDocumentViewerConfig: setDocumentViewerConfigCallback,
        stakeholderColors,
    }), [
        isNew,
        isProcessing,
        isActionNeeded,
        flowState,
        setFlowState,
        api,
        errors,
        setError,
        clearError,
        hasError,
        flowStep,
        navigateToStep,
        fetchBasicData,
        focusedEntityId,
        focusedEntityType,
        setFocusedEntity,
        visibleDocument,
        setVisibleDocument,
        documentViewerConfig,
        setDocumentViewerConfigCallback,
        stakeholderColors,
    ]);

    return (
        <FlowContext.Provider value={contextValue}>
            {!isReady && <Loader center size={SizesEnums.XLARGE} />}
            {isReady && children}
        </FlowContext.Provider>
    );
}
