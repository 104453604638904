import { ReactNode, useMemo, useRef } from 'react';
import Http, { HttpHeadersManager, HttpRequest } from '~lib/http';
import { HttpMethod } from '~lib/http/http.enums';
import { ApiContext } from './api.context';
import { ApiConstructor } from './api.models';


export interface ApiContextProviderProps {
    children: ReactNode;
    apiConstructors: ApiConstructor[];
    baseUrl?: string;
}

export function ApiContextProvider({ children, apiConstructors, baseUrl }: ApiContextProviderProps) {
    const errorCounter = useRef(0);

    const http = useMemo(() => {
        return new Http((request: HttpRequest) => {
            if (request.method === HttpMethod.Get) {
                request.addResponseProcessor(response => {
                    const csrfToken = response.headers[HttpHeadersManager.CSRFToken.toLowerCase()];

                    if (csrfToken) {
                        HttpHeadersManager.setCommonHeader(HttpHeadersManager.CSRFToken, csrfToken);
                    }
                });
            }
            request.addErrorProcessor(() => {
                errorCounter.current++; // just for now
            });

            return request;
        }, baseUrl);
    }, [baseUrl]);

    const contextValue = useMemo(() => {
        const apis = [];

        for (const apiCtor of apiConstructors) {
            apis.push(new apiCtor(http));
        }

        return apis;
    }, [http, apiConstructors]);

    return (
        <ApiContext.Provider value={contextValue}>
            {children}
        </ApiContext.Provider>
    );
}
