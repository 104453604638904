import { Button, SplitButton, SplitButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { useCallback, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import FileDropzone from '~components/files/file-dropzone';
import FileUpload from '~components/files/file-upload';
import { FileUploadRef } from '~components/files/file-upload/fileUpload';
import Typography, { TypographyToken } from '~components/typography';
import { FileType } from '~constants';
import { CloudProvider } from '~constants/cloud-provider';
import { useSetFlowState } from '~contexts/flow';
import { FlowActionType } from '~contexts/flow/flow.types';
import { useCurrentLanguage, useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { useCloudStorageProviders } from '~hooks/cloud-provider';
import { CloudStorageSelectedFile } from '~lib/cloud-storage/cloud-storage.scenario';
import { useFlowDocumentUploadButtonItems } from './flowDocumentUpload.hooks';
import './flowDocumentsUpload.scss';

export interface FlowDocumentsUploadProps {

}

function FlowDocumentsUpload(props: FlowDocumentsUploadProps) {
    const { t } = useTranslation('flow');
    const { documentConversionSettings: { defaultTargetFormat, allowedSourceFormats } } = useSettings();
    const { languageCode } = useCurrentLanguage();
    const inputRef = useRef<FileUploadRef>();
    const btnRef = useRef(null);
    const setFlowState = useSetFlowState();

    const uploadBtnItems = useFlowDocumentUploadButtonItems();

    const fileTypes = useMemo(() => {
        const extensions: string[] = [];
        const mime: string[] = [];

        for (const source of allowedSourceFormats) {
            const fileType = FileType.find(source);

            extensions.push(...fileType.extensions);
            mime.push(fileType.mimeType);
        }

        return {
            extensions,
            mime,
        };
    }, [allowedSourceFormats]);

    const onCloudFileUpload = useCallback((files: CloudStorageSelectedFile[]) => {
        setFlowState(FlowActionType.AddDocuments, files.map(file => ({
            conversionTargetFormat: defaultTargetFormat,
            documentName: file.name,
            size: file.size,
            isOptional: false,
            isNew: true,
            isCloud: true,
            fileUrl: file.url,
            fileToken: file.token,
            localId: uuid(),
        })));
    }, [defaultTargetFormat, setFlowState]);

    const cloudProviders = useCloudStorageProviders(fileTypes.mime, true, onCloudFileUpload);

    const onLocalFileUpload = useCallback((files: File[]) => {
        setFlowState(FlowActionType.AddDocuments, files.map(file => ({
            conversionTargetFormat: defaultTargetFormat,
            documentName: file.name,
            size: file.size,
            isOptional: false,
            isNew: true,
            file,
            localId: uuid(),
        })));
    }, [defaultTargetFormat, setFlowState]);

    const onCloudUploadClickCallback = useCallback((event: SplitButtonItemClickEvent) => {
        const provider = event.item.provider as CloudProvider | undefined;

        if (!provider) {
            inputRef.current?.openPrompt();

            return;
        }
        cloudProviders.providers[provider]?.showFilePicker(languageCode);
    }, [cloudProviders, languageCode]);

    return (
        <div className={'c-flow-documents-upload'}>
            <FileDropzone inputRef={inputRef}>
                <div className={'c-flow-documents-upload__dropzone-inner'}>
                    <Typography
                        token={TypographyToken.DesktopHeaderMd}
                        tagName={'h2'}
                        text={t('drop-your-files-here')}
                    />
                    <Typography token={TypographyToken.DesktopDescriptionLg} text={t('or')} />
                    {uploadBtnItems.length
                        ? (
                            <SplitButton
                                ref={btnRef}
                                themeColor={'secondary'}
                                text={t('upload-a-file')}
                                size={'large'}
                                iconClass={'fa-regular fa-file-arrow-up'}
                                popupSettings={{ popupClass: 'c-flow-documents-upload__upload-dropdown' }}
                                items={uploadBtnItems}
                                onButtonClick={() => inputRef.current?.openPrompt()}
                                onItemClick={onCloudUploadClickCallback}

                            />
                        )
                        : (
                            <Button
                                ref={btnRef}
                                themeColor={'secondary'}
                                size={'large'}
                                iconClass={'fa-regular fa-file-arrow-up'}
                                onClick={() => inputRef.current?.openPrompt()}
                            >{t('upload-a-file')}</Button>
                        )}
                    <FileUpload
                        onUpload={onLocalFileUpload}
                        inputRef={inputRef}
                        mimeTypes={fileTypes.mime}
                        multiple
                    />
                    <Typography
                        token={TypographyToken.DesktopDescriptionMd}
                        className={'c-flow-documents-upload__dropzone-hint'}
                    >
                        <div>{t('supported-formats-list', { formats: fileTypes.extensions.join(', ') })}</div>
                    </Typography>
                </div>
            </FileDropzone>
        </div>
    );
}

export default FlowDocumentsUpload;
