import { NdsButton } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { useCallback } from 'react';
import { PortalsApi } from '~api/portals.api';
import Content from '~components/content';
import NavTabs from '~components/nav-tabs';
import { Permission, USER_MANAGEMENT_PORTAL_TABS } from '~constants';
import { UserManagementAction } from '~constants/user-management-action';
import { PortalTableContainer } from '~containers/portal-table';
import { useApi } from '~contexts/api';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { TableContextProvider } from '~contexts/table';
import { FetchTableDataDelegate } from '~contexts/table/table.types';
import useNavTabs from '~hooks/tabs';
import { DocumentGroupsListTableDataModel, UserGroupsListItemModel } from '~models/user-management.models';
import DocumentGroupsViewQuickActions from './document-groups-view-quick-actions/document-groups-view-quick-actions';
import { DocumentGroupsTableDefinition } from './table-definition';
import { useDocumentGroupsTableFilters } from './use-document-groups-table-filter';
import useFilterTabConditions from '../hooks/useFilterTabConditions';

export interface DocumentGroupsPortalViewProps {
}

const DocumentGroupsPortalView = () => {
    const portalsApi = useApi(PortalsApi);
    const { t } = useTranslation('user-management');
    const filters = useDocumentGroupsTableFilters();

    const filterTabConditions = useFilterTabConditions();

    const { filteredTabs } = useNavTabs(USER_MANAGEMENT_PORTAL_TABS, filterTabConditions);

    const fetchData: FetchTableDataDelegate<DocumentGroupsListTableDataModel> = useCallback(async (data, signal) => {
        return portalsApi.getDocumentGroups(data, signal);
    }, [portalsApi]);

    const onClickQuickAction = useCallback((data: UserGroupsListItemModel, action: UserManagementAction) => {
        return;
    }, []);

    return (
        <Content noDefaultPadding>
            <TableContextProvider<'documentGroupId', DocumentGroupsListTableDataModel>
                definition={DocumentGroupsTableDefinition}
                fetchData={fetchData}
            >
                <PortalTableContainer<'documentGroupId', DocumentGroupsListTableDataModel>
                    tabs={<NavTabs navTabs={filteredTabs} />}
                    pageTitle={t('user-management-portal-view-title-name')}
                    filtersDefinition={filters}
                    headerButton={<HeaderButton />}
                    renderColumns={(data) => {
                        return {
                            name: data.name,
                            action: <DocumentGroupsViewQuickActions
                                data={data}
                                onClickQuickAction={onClickQuickAction}
                            />,
                        };
                    }}
                />
            </TableContextProvider>
        </Content>
    );
};

const HeaderButton = () => {

    const { t } = useTranslation('user-management');
    const hasInvitePermission = useHasPermission(Permission.AccessUserManagementPortal_ActionCreateDocumentGroup);

    return hasInvitePermission
        ? <NdsButton
            label={t('document-groups-portal-header-button')}
            size={SizesEnums.SMALL}
            onClick={() => console.warn('TODO: and create document group logic ')}
        />
        :
        null;
};

export default DocumentGroupsPortalView;
