import {
    CloudAccountProviderRedirectUrl,
    ConvertToCloudPasswordConfirm,
    GetCurrentDocumentGroupsModel,
    GetCurrentUserModel,
    GetRegisterCloudUserInfo,
    GetRegisterUserInfo,
    InitPasswordReset,
    LoginUserModel,
    LogoutUserModel,
    PasswordReset,
    RegisterCloudAccount,
    RegisterLocalAccount,
} from '~api/auth.models';
import { IApi } from '~contexts/api';
import { PromiseUtils } from '~lib';
import Http from '~lib/http';

export class AuthApi implements IApi {
    constructor(private http: Http) {
    }

    async getCurrentUser(signal?: AbortSignal): Promise<GetCurrentUserModel.Result> {
        try {
            const response = await this.http
                .get('/portalapi/v1/myaccount')
                .execute(signal);

            return await response.jsonAs<GetCurrentUserModel.Result>();
        } catch (e) {
            if (signal?.aborted) {
                return PromiseUtils.forever();
            }
            throw e;
        }
    }

    async login({
        email,
        password,
    }: LoginUserModel.Params, signal?: AbortSignal): Promise<LoginUserModel.Result> {
        const response = await this.http
            .post('/portalapi/v1/myaccount/login')
            .withJsonBody<LoginUserModel.Body>({
                Email: email,
                Password: password,
            })
            .execute(signal);

        return response.ok;
    }

    async logout(signal?: AbortSignal): Promise<LogoutUserModel.Result> {
        await this.http.post('/portalapi/v1/myaccount/logout').execute(signal);

        return true;
    }

    async getCurrentDocumentGroups(signal?: AbortSignal): Promise<GetCurrentDocumentGroupsModel.Result> {
        // TODO backend could return all at once - CEP-13171
        console.warn('[BE] check CEP-13171');
        const response = await this.http
            .get('/portalapi/v1/myaccount/documentgroups')
            .withParams({ ContinuationToken: 0 })
            .execute(signal);

        return await response.jsonAs<GetCurrentDocumentGroupsModel.Result>();
    }

    async getCloudAccountProviderRedirectUrl({
        providerName,
        language,
        token,
        redirectUrl,
    }: CloudAccountProviderRedirectUrl.Params, signal?: AbortSignal): Promise<CloudAccountProviderRedirectUrl.Result> {
        try {
            const response = await this.http.get(`/portalapi/v1/myaccount/externallogin/${providerName}${token ? `/${token}` : ''}`)
                .withParams({
                    language,
                    redirectUrl,
                })
                .execute(signal);

            return response.json();
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async initPasswordReset({ email }: InitPasswordReset.Params, signal?: AbortSignal): Promise<InitPasswordReset.Result> {
        const response = await this.http.post('/portalapi/v1/myaccount/password/forgot')
            .withJsonBody<InitPasswordReset.Body>({ Email: email }).execute(signal);

        return response.ok;
    }

    async passwordReset(data: PasswordReset.Params, signal?: AbortSignal): Promise<PasswordReset.Result> {
        try {
            const response = await this.http.post('/portalapi/v1/myaccount/password/reset')
                .withJsonBody<PasswordReset.Body>({
                    NewPassword: data.password,
                    ExpiryTag: data.expiryTag,
                    UserId: data.userId,
                    Token: data.token,
                }).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getRegisterUserInfo({ token }: GetRegisterUserInfo.Params, signal?: AbortSignal): Promise<GetRegisterUserInfo.Result> {
        try {
            const response = await this.http
                .get(`/portalapi/v1/myaccount/invitationemail/${token}`)
                .execute(signal);

            return response.jsonAs<GetRegisterUserInfo.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getRegisterCloudUserInfo({ state }: GetRegisterCloudUserInfo.Params, signal?: AbortSignal): Promise<GetRegisterCloudUserInfo.Result> {
        try {
            const response = await this.http
                .get(`portalapi/v1/myaccount/externalloginclaims/${state}`)
                .execute(signal);

            return response.jsonAs<GetRegisterCloudUserInfo.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async registerLocalAccount(data: RegisterLocalAccount.Params, signal?: AbortSignal): Promise<RegisterLocalAccount.Result> {
        try {
            const response = await this.http.post('/portalapi/v1/myaccount/register')
                .withJsonBody<RegisterLocalAccount.Body>({
                    Company: data.company,
                    ConfirmPassword: data.confirmPassword,
                    Email: data.email,
                    FirstName: data.firstName,
                    Language: data.language,
                    LastName: data.lastName,
                    Password: data.password,
                    Token: data.token,
                }).execute(signal);

            return response.ok;
        } catch (e) {
            throw e;
        }
    }

    async registerCloudAccount({
        token,
        conversionToken,
        data,
    }: RegisterCloudAccount.Params, signal?: AbortSignal): Promise<RegisterCloudAccount.Result> {
        try {
            const response = await this.http.post(`/portalapi/v1/myaccount/externalaccountregistration${token ? `/${token}` : ''}`)
                .withHeader('AccountConversionToken', conversionToken || '')
                .withJsonBody<RegisterCloudAccount.Body>({
                    State: data.state,
                    Company: data.company,
                    Email: data.email,
                    FirstName: data.firstName,
                    Language: data.language,
                    LastName: data.lastName,
                    Avatarb64: data.avatarb64,
                    IsTermsAndConditionsAccepted: data.consent,
                }).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async convertToCloudPasswordConfirm(data: ConvertToCloudPasswordConfirm.Params, signal?: AbortSignal): Promise<ConvertToCloudPasswordConfirm.Result> {
        const response = await this.http.post('/portalapi/v1/myaccount/convertlocaltocloudaccountpasswordconfirmation')
            .withJsonBody<ConvertToCloudPasswordConfirm.Body>({
                State: data.state,
                Password: data.password,
            }).execute(signal);

        return await response.jsonAs<ConvertToCloudPasswordConfirm.Result>();
    }

    /// $ADD_API_TEMPLATE_MARKER
}
