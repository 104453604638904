import { ReactNode, useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Guid } from '~models';
import { OverlayContext, OverlayContextType } from './overlay.context';
import {
    HideModalFunction,
    HideOverlayElementFunction,
    ModalElement,
    ModalOptions,
    NotificationOptions,
    OverlayElement,
    PanelElement,
    PanelOptions,
    ShowModalFunction,
    ShowNotificationFunction,
    ShowOverlayElementFunction,
    ShowPanelFunction,
} from './overlay.types';

export interface OverlayContextProviderProps {
    children: ReactNode;
}

export function OverlayContextProvider({ children }: OverlayContextProviderProps) {
    const [elements, setElements] = useState<OverlayElement[]>([]);
    const [modals, setModals] = useState<ModalElement[]>([]);
    const [panel, setPanel] = useState<PanelElement>();
    const [notifications, setNotifications] = useState<NotificationOptions[]>([]);

    const hideElement = useCallback<HideOverlayElementFunction>((id: Guid, keepNext: boolean = false) => {
        setElements((current) => {
            const existing = current.findIndex((el) => el.options.id === id);

            if (existing > -1) {
                current.splice(existing, keepNext ? 1 : current.length - existing);
            }

            return current.slice();
        });
    }, []);

    const showElement = useCallback<ShowOverlayElementFunction>((Component, options) => {
        let existing = -1;

        setElements((current) => {
            existing = current.findIndex((el) => el.options.id === options.id);

            if (existing === -1) {
                return [
                    ...current,
                    {
                        Component,
                        options,
                    },
                ];
            }

            return current;
        });
    }, []);


    const hideModal = useCallback<HideModalFunction>((id: Guid) => {
        setModals((current: ModalElement[]) => {
            const existing = current.findIndex((el) => el.options.id === id);

            if (existing > -1) {
                current.splice(existing, 1);
            }

            return current.slice();
        });
    }, []);

    const showModal = useCallback<ShowModalFunction>((content: ReactNode, options: ModalOptions) => {
        setModals((current: ModalElement[]) => {
            const existing = current.findIndex((el) => el.options.id === options.id);

            if (existing > -1) {
                current.splice(existing, 1, {
                    content,
                    options,
                });

                return current.slice();
            }

            return [
                ...current,
                {
                    content,
                    options,
                },
            ];
        });

        function hideModalLocal() {
            hideModal(options.id);
        }

        hideModalLocal.id = options.id;

        return hideModalLocal;
    }, [hideModal]);

    const hidePanel = useCallback(() => {
        setPanel(undefined);
    }, []);

    const showPanel = useCallback<ShowPanelFunction>((content: ReactNode, options: PanelOptions) => {
        setPanel((current) => {
            if (current && current.options.id !== options.id) {
                throw new Error('Only one panel can be open at a time');
            }

            return {
                content,
                options,
            };
        });

        return hidePanel;
    }, [hidePanel]);

    const showNotification = useCallback<ShowNotificationFunction>((options: NotificationOptions) => {
        if (!options.id) {
            options.id = uuid();
        }

        setNotifications((current: NotificationOptions[]) => {
            if (current.find(el => el.id === options.id)) {
                throw new Error('Notification of that id already exists. For repetitive notifications use uuid package instead of useId hook (if you\'re using it already');
            }

            return [...current, options];
        });

        let timeoutId: NodeJS.Timeout;

        if (options.hideAfterMs) {
            timeoutId = setTimeout(() => {
                setNotifications((current) => {
                    return current.filter(el => el.id !== options.id);
                });
            }, options.hideAfterMs);
        }

        function hideNotification() {
            clearTimeout(timeoutId);
            setNotifications((current) => {
                return current.filter(el => el.id !== options.id);
            });
        }

        hideNotification.id = options.id;

        return hideNotification;

    }, []);

    const contextValue = useMemo<OverlayContextType>(() => ({
        elements,
        showElement,
        hideElement,
        modals,
        showModal,
        hideModal,
        panel,
        showPanel,
        hidePanel,
        notifications,
        showNotification,
    }), [
        elements,
        showElement,
        hideElement,
        hideModal,
        hidePanel,
        modals,
        notifications,
        panel,
        showModal,
        showNotification,
        showPanel,
    ]);

    return (
        <OverlayContext.Provider value={contextValue}>
            {children}
        </OverlayContext.Provider>
    );
}
