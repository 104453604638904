import { NdsIconFont } from '@gonitro/rcl';
import { NavLink } from 'react-router-dom';
import Typography, { TypographyToken } from '~components/typography';
import { Translation } from '~contexts/i18n';
import { I18nextNamespaceName, NavTabModel } from '~models';
import './navTabs.scss';


type Errors = {
    [key: string]: boolean;
};
export interface NavTabsProps<TNamespaceName extends I18nextNamespaceName = any> {
    navTabs: NavTabModel<TNamespaceName>[];
    selectTabHandler?: (type: string) => void;
    currentTab?: NavTabModel<TNamespaceName>;
    errors?: Errors | null;
}

function NavTabs({ navTabs, selectTabHandler, currentTab, errors }: NavTabsProps) {

    return (
        <div className={'c-nav-tabs'}>
            {navTabs.map((navTab: NavTabModel, index: number) => (
                navTab.url ?
                    <NavLink key={`${index}-${navTab}`} to={navTab.url} className={'c-nav-tabs__button-wrapper'}>
                        {({ isActive }) => (
                            <>
                                <Typography
                                    tagName={'div'}
                                    token={TypographyToken.DesktopDescriptionMd}
                                    className={'c-nav-tabs__button-label'}
                                >
                                    <Translation namespace={navTab.i18n.ns} label={navTab.i18n.key} />
                                </Typography>
                                {isActive && <div className={'c-nav-tabs__border'}></div>}
                            </>
                        )}
                    </NavLink>
                    :
                    <div key={`${index}-${navTab}`} className={'c-nav-tabs__button-wrapper'}>
                        <>
                            <Typography
                                tagName={'div'}
                                token={TypographyToken.DesktopDescriptionMd}
                                className={'c-nav-tabs__button-label'}
                                onClick={() => selectTabHandler && selectTabHandler(navTab.key)}
                            >
                                <Translation namespace={navTab.i18n.ns} label={navTab.i18n.key} />
                                {errors && errors[navTab.key] && <NdsIconFont className='c-nav-tabs__warning-icon' fontName='fa-solid-exclamation-triangle' />}
                            </Typography>
                            {navTab.i18n.key === currentTab?.i18n.key
                                && <div className={'c-nav-tabs__border'} />}
                        </>
                    </div>
            ))}
        </div>
    );
}

export default NavTabs;
