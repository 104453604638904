import { Guid } from '~models';
import { FlowStateType } from './flow.context';
import {
    FlowReducerCommonActionPayloads,
    FlowReducerDetailsActionPayloads,
    FlowReducerDocumentActionPayloads,
    FlowReducerEditorInitActionPayloads,
    FlowReducerPageObjectActionPayloads,
    FlowReducerTemplateActionPayloads,
} from './reducers';
import { FlowReducerElementActionPayloads } from './reducers/elements.reducers';

export enum FlowType {
    Package = 'Package',
    Template = 'Template',
}

export enum FlowStep {
    Documents = 'Documents',
    Processing = 'Processing',
    Detail = 'Detail',
}

export enum FlowActionType {
    SetFlowId,
    SetDocuments, // overrides whole document list
    AddDocuments,
    DeleteDocument,
    UpdateDocument,
    SetTemplate,
    RemoveTemplate,
    SetTemplateName,
    SetInitialDetails,
    SetInitialLegalNoticeConfig,
    SetInitialFlow,
    UpdateProperties,
    UpdatePackageName,
    UpdateTemplateName,
    MovePageObject,
    DeleteElement
    // SetStakeholders,
    // UpdateStakeholder,
    // AddStakeholder,
    // RemoveStakeholder,
}

export type FlowReducerActionPayloads =
    FlowReducerCommonActionPayloads
    & FlowReducerDocumentActionPayloads
    & FlowReducerTemplateActionPayloads
    & FlowReducerDetailsActionPayloads
    & FlowReducerEditorInitActionPayloads
    & FlowReducerPageObjectActionPayloads
    & FlowReducerElementActionPayloads
    ;

export interface FlowStateAction<T extends FlowActionType = FlowActionType> {
    type: T,
    payload: FlowReducerActionPayloads[T],
}

export type FlowReducerFunc<T extends FlowActionType> = (state: FlowStateType, payload: FlowReducerActionPayloads[T]) => FlowStateType;

export type FlowNavigateToStep = (
    step: FlowStep,
    params?: {
        state?: any;
        flowId?: Guid;
        replace?: boolean;
    },
) => void;

export enum FlowEntityType {
    Package,
    SigningField,
    TextField,
    CheckboxField,
    RadioField,
    Stakeholder,
    Approver,
}

export type SetFlowFocusedEntity = (entityType: FlowEntityType, entityId?: Guid) => void;
