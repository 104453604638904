import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PortalsApi } from '~api/portals.api';
import QuickActionsTable from '~components/quick-actions-table';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { DocumentGroupPermission, FlowAction, FlowStatusEnum } from '~constants';
import { BaseRoutes, NestedRoutes } from '~constants/routes';
import { useApi } from '~contexts/api';
import { useWorkspaceId } from '~contexts/app';
import { useHasDocumentGroupPermissionFunc } from '~contexts/auth';
import { useRedirectFunc } from '~hooks/redirect';
import { UrlUtil } from '~lib';
import { AppUrlsUtils } from '~lib/app-urls.utils';
import { LanguageDataLabel } from '~models';
import { PackagesTableDataModel } from '~models/portals.models';
import QuickActionsModal from '../../../../components/quick-actions-modal';
import buildQueryParams = UrlUtil.buildQueryParams;


export interface DocumentPortalQuickActionsProps {
    data: PackagesTableDataModel;
    onClickQuickAction: (data: PackagesTableDataModel, action: FlowAction) => void;
}

type ActionFunctions = {
    [key in FlowAction]?: () => Promise<boolean> | void | Promise<void>;
};

const AdditionalInformationI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
    [FlowAction.Delete]: 'delete-warning-additional-info',
    [FlowAction.SoftDelete]: 'delete-warning-additional-info',
    [FlowAction.SendToSigners]: 'send-to-signers-warning-additional-info',
    [FlowAction.SendReminder]: 'send-reminder-warning-additional-info',
    [FlowAction.Revoke]: 'revoke-warning-additional-info',
};

const WarningMessageDialogI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
    [FlowAction.End]: 'end-warning-message',
    [FlowAction.Delete]: 'delete-warning-message',
    [FlowAction.SoftDelete]: 'delete-warning-message',
    [FlowAction.SendToSigners]: 'send-to-signers-warning-message',
    [FlowAction.SendReminder]: 'send-reminder-warning-message',
    [FlowAction.Revoke]: 'revoke-warning-message',
};

function DocumentPortalQuickActions({ data, onClickQuickAction }: DocumentPortalQuickActionsProps) {
    const portalsApi = useApi(PortalsApi);
    const redirectFunc = useRedirectFunc();
    const workspaceId = useWorkspaceId()!;
    const hasDocGroupPermission = useHasDocumentGroupPermissionFunc();
    const navigate = useNavigate();
    const [quickActionModalData, setQuickActionModalData] = useState<{
        data: PackagesTableDataModel | undefined, actionType: FlowAction | undefined;
    }>({
        data: undefined,
        actionType: undefined,
    });
    const quickActions = useMemo((): QuickAction<FlowAction>[][] => {
        // Pending status has different actions based on conditions (permissions, amount of signers).
        //  The group array help build the list
        const pendingParentGroup: QuickAction<FlowAction>[][] = [];

        const pendingGroup2: QuickAction<FlowAction>[] = [];

        const pendingGroup3: QuickAction<FlowAction>[] = [];

        switch (data.status.toUpperCase()) {
            case FlowStatusEnum.Processing:
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    pendingParentGroup.push([
                        {
                            icon: 'fa-solid-edit',
                            type: FlowAction.Edit,
                        },
                    ]);
                }

                return pendingParentGroup;
            case FlowStatusEnum.ProcessingFailed:
            case FlowStatusEnum.Draft:
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    pendingParentGroup.push([
                        {
                            icon: 'fa-solid-edit',
                            type: FlowAction.Edit,
                        },
                    ]);
                }
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Delete)) {
                    pendingParentGroup.push([
                        {
                            icon: 'fa-solid-trash',
                            type: FlowAction.Delete,
                        },
                    ]);
                }

                return pendingParentGroup;
            case FlowStatusEnum.PendingApproval:
            case FlowStatusEnum.PendingFormFill:
                pendingParentGroup.push([
                    {
                        icon: 'fa-solid-eye',
                        type: FlowAction.Preview,
                    },
                ]);

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    pendingGroup2.push({
                        type: FlowAction.RequestAuditTrail,
                        icon: 'fa-solid-file-lines',
                    });
                }
                pendingParentGroup.push(pendingGroup2);

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    if (data.totalSigner && data.totalSigner > 0) {
                        pendingGroup3.push({
                            type: FlowAction.SendToSigners,
                            icon: 'fa-solid-paper-plane',
                        });
                    } else {
                        pendingGroup3.push({
                            type: FlowAction.End,
                            icon: 'fa-solid-circle-stop',
                        });
                    }

                    pendingParentGroup[0].push(
                        {
                            icon: 'fa-solid-bell',
                            type: FlowAction.SendReminder,
                        },
                        {
                            type: FlowAction.Reassign,
                            icon: 'fa-solid-arrow-right',
                        },
                    );

                    pendingGroup3.push({
                        type: FlowAction.Revoke,
                        icon: 'fa-solid-arrow-left-to-line',
                    });
                    pendingParentGroup.push(pendingGroup3);
                }

                return pendingParentGroup;
            case FlowStatusEnum.PendingSigning:
                pendingParentGroup.push([
                    {
                        icon: 'fa-solid-eye',
                        type: FlowAction.Preview,
                    },
                ]);

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    pendingGroup3.push({
                        type: FlowAction.End,
                        icon: 'fa-solid-circle-up',
                    }, {
                        type: FlowAction.Revoke,
                        icon: 'fa-solid-arrow-left-to-line',
                    });
                    pendingParentGroup[0].push(
                        {
                            icon: 'fa-solid-bell',
                            type: FlowAction.SendReminder,
                        },
                        {
                            type: FlowAction.Reassign,
                            icon: 'fa-solid-arrow-right',
                        },
                    );
                }
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Sign)) {
                    pendingParentGroup[0].push({
                        type: FlowAction.Sign,
                        icon: 'fa-solid-pen-nib',
                    });
                }
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    pendingGroup2.push({
                        type: FlowAction.RequestAuditTrail,
                        icon: 'fa-solid-file-lines',
                    });
                }
                if (pendingGroup2.length) {
                    pendingParentGroup.push(pendingGroup2);
                }

                if (pendingGroup3.length) {
                    pendingParentGroup.push(pendingGroup3);
                }


                return pendingParentGroup;
            case FlowStatusEnum.Archived:
            case FlowStatusEnum.Finished: {
                const group: QuickAction<FlowAction>[] = [];

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Download)) {
                    group.push({
                        icon: 'fa-solid-download',
                        type: FlowAction.Download,
                    });
                }

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    group.push({
                        type: FlowAction.RequestAuditTrail,
                        icon: 'fa-solid-file-lines',
                    });
                }
                if (group.length) {
                    pendingParentGroup.push(group);
                }

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Delete)) {
                    pendingParentGroup.push([
                        {
                            icon: 'fa-solid-trash',
                            type: FlowAction.Delete,
                        },
                    ]);
                }

                return pendingParentGroup;
            }
            case FlowStatusEnum.Expired:
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    pendingParentGroup.push(
                        [
                            {
                                icon: 'fa-solid-calendar-circle-plus',
                                type: FlowAction.Extend,
                            },
                        ],
                        [
                            {
                                type: FlowAction.Revoke,
                                icon: 'fa-solid-arrow-left-to-line',
                            },
                        ],
                    );
                }
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    pendingParentGroup.splice(1, 0, [
                        {
                            type: FlowAction.RequestAuditTrail,
                            icon: 'fa-solid-file-lines',
                        },
                    ]);
                }

                return pendingParentGroup;
            case FlowStatusEnum.Rejected:
            case FlowStatusEnum.Revoked:
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    pendingParentGroup.push([
                        {
                            type: FlowAction.RequestAuditTrail,
                            icon: 'fa-solid-file-lines',
                        },
                    ]);
                }

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Delete)) {
                    pendingParentGroup.push([
                        {
                            icon: 'fa-solid-trash',
                            type: FlowAction.Delete,
                        },
                    ]);
                }

                return pendingParentGroup;
            case FlowStatusEnum.Failed:
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    pendingParentGroup.push([
                        {
                            type: FlowAction.RequestAuditTrail,
                            icon: 'fa-solid-file-lines',
                        },
                    ]);
                }

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    pendingParentGroup.push([
                        {
                            type: FlowAction.Revoke,
                            icon: 'fa-solid-arrow-left-to-line',
                        },
                    ]);
                }

                return pendingParentGroup;
            default:
                return [[]];
        }
    }, [data.status, data.totalSigner, hasDocGroupPermission, workspaceId]);

    const getTokensAndRedirect = useCallback(
        async (packageId: string, actionType: FlowAction) => {
            try {
                const response = await portalsApi.getPackageDetails({ packageId });
                const { token, expiryTag, previewToken, previewExpiryTag } = response;

                const urlParams: UrlUtil.QueryParams = { packageId };

                switch (actionType) {
                    case FlowAction.Sign:
                        if (token && expiryTag) {
                            urlParams.token = token;
                            urlParams.expiryTag = expiryTag;
                            urlParams.f2f = 'true';
                        }
                        break;
                    case FlowAction.Preview:
                        if (previewToken && previewExpiryTag) {
                            urlParams.token = previewToken;
                            urlParams.expiryToken = previewExpiryTag;
                            urlParams.preview = 'true';
                        }
                        break;
                    default:
                        console.error('Invalid action type');

                        return;
                }

                redirectFunc(`signinit?${buildQueryParams(urlParams)}`);
            } catch (error) {
                console.error('Failed to get tokens:', error);
            }
        },
        [portalsApi, redirectFunc],
    );

    const downloadFunction = useCallback(
        async (packageId: string) => {
            try {
                const response = await portalsApi.getDownloadPackageToken({ packageId });

                if (response) {
                    const { token, expiryTag } = response;

                    redirectFunc(AppUrlsUtils.getDownloadUrl(packageId, token, expiryTag));
                } else {
                    console.error('Required token information is missing');
                }
            } catch (error) {
                console.error(error);
            }
        },
        [portalsApi, redirectFunc],
    );

    const handleActionClick = useCallback(
        (actionType: FlowAction) => {
            closeModal();
            const packageId = data.packageId;

            const actionFunctions: ActionFunctions = {
                [FlowAction.Download]: () => downloadFunction(packageId),
                [FlowAction.SendReminder]: () => portalsApi.sendPackageReminder({ packageId }),
                [FlowAction.Reassign]: () => onClickQuickAction(data, actionType),
                [FlowAction.Revoke]: () => portalsApi.revokePackage({ packageId }),
                [FlowAction.End]: () => portalsApi.endPackage({
                    packageId,
                    endPackage: true,
                }),
                [FlowAction.Edit]: () => {
                    switch (data.status) {
                        case FlowStatusEnum.ProcessingFailed:
                            navigate(`${BaseRoutes.Package}/${NestedRoutes.Documents}/${packageId}`);
                            break;
                        case FlowStatusEnum.Processing:
                            navigate(`${BaseRoutes.Package}/${NestedRoutes.Processing}/${packageId}`);
                            break;
                        case FlowStatusEnum.Draft:
                        default:
                            navigate(`${BaseRoutes.Package}/${NestedRoutes.Flow}/${packageId}`, { state: { isActionNeeded: data.isActionNeeded } });
                            break;
                    }
                },
                [FlowAction.Delete]: () => portalsApi.deletePackage({ packageId }),
                [FlowAction.Preview]: () => getTokensAndRedirect(packageId, actionType),
                [FlowAction.SendToSigners]: () => portalsApi.endPackage({ packageId }),
                [FlowAction.Sign]: () => getTokensAndRedirect(packageId, actionType),
                [FlowAction.RequestAuditTrail]: () =>
                    redirectFunc(`portalapi/v1/packages/${encodeURIComponent(packageId)}/audittrail`),
            };

            const actionFunc = actionFunctions[actionType];

            if (actionFunc) {
                actionFunc();
            } else {
                console.error('Unsupported action type:', actionType);
            }
        },
        [
            data,
            downloadFunction,
            getTokensAndRedirect,
            navigate,
            onClickQuickAction,
            portalsApi,
            redirectFunc,
        ],
    );

    const QuickActionsNoModal = [
        FlowAction.Edit,
        FlowAction.Download,
        FlowAction.RequestAuditTrail,
        FlowAction.Preview,
        FlowAction.Sign,
    ];

    const openQuickActionsModal = (actionType: FlowAction) => {
        if (QuickActionsNoModal.includes(actionType)) {
            handleActionClick(actionType);
        } else {
            setQuickActionModalData({
                data: data,
                actionType: actionType,
            });
        }
    };

    const closeModal = () => {
        setQuickActionModalData({
            data: undefined,
            actionType: undefined,
        });
    };

    return (
        <>
            <QuickActionsTable actions={quickActions} handleActionClick={openQuickActionsModal} />
            <QuickActionsModal
                data={quickActionModalData.data}
                actionType={quickActionModalData.actionType}
                handleCloseModal={closeModal}
                handleClickConfirm={() => {
                    handleActionClick(quickActionModalData.actionType!);
                }}
                additionalInformation={AdditionalInformationI18n[quickActionModalData.actionType!]}
                warningMessage={WarningMessageDialogI18n[quickActionModalData.actionType!]}
            />
        </>
    );
}

export default DocumentPortalQuickActions;
