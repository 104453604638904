import { SidePanel } from '@gonitro/rcl';
import { Fade, Slide } from '@progress/kendo-react-animation';
import classNames from 'classnames';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import Overlay from '~components/overlay';
import { OverlayElement, useOverlayContext } from '~contexts/overlay';
import './overlay.container.scss';

export interface OverlayContainerProps {

}

const ANIMATION_DURATION = 300;

export const OverlayContainer = () => {
    const { elements, hideElement, modals, panel, notifications } = useOverlayContext();

    const [animations, setAnimations] = useState<('modal' | 'panel')[]>([]);
    const prevElements = useRef<OverlayElement[]>([]);

    const onClosePanel = useCallback(() => {
        panel?.options.onClose();
    }, [panel?.options]);

    const renderedPanel = (
        <>
            <Fade
                transitionEnterDuration={ANIMATION_DURATION}
                transitionExitDuration={ANIMATION_DURATION}
                exit={true}
            >
                {panel ? <Overlay onClick={onClosePanel} /> : null}
            </Fade>
            <Slide
                direction={'left'}
                exit={true}
                transitionEnterDuration={ANIMATION_DURATION}
                transitionExitDuration={ANIMATION_DURATION}
                className={'ct-overlay__panel-animation'}
            >
                {panel && <SidePanel
                    className={classNames('ct-overlay__panel')}
                    onClosePanel={onClosePanel}
                    footer={panel?.options.footer}
                >
                    {panel?.content}
                </SidePanel>}
            </Slide>
        </>
    );
    const renderedModals = (
        <>
            <Fade
                transitionEnterDuration={ANIMATION_DURATION}
                transitionExitDuration={ANIMATION_DURATION}
                exit={true}
            >
                {modals.length > 0 && <Overlay />}
            </Fade>
            <Fade
                transitionEnterDuration={ANIMATION_DURATION}
                transitionExitDuration={ANIMATION_DURATION}
                exit={true}
            >
                {modals.map(({ content, options }, index) => {
                    return (
                        <div
                            key={options.id}
                            className={classNames('ct-overlay__modal', { '.ct-overlay__modal--overlayed': index < modals.length - 1 })}
                        >
                            {content}
                        </div>
                    );
                })}
            </Fade>
        </>
    );

    // animations needs to be mounted till end of animation when removing element
    useEffect(() => {
        if (prevElements.current === elements) {
            return;
        }
        if (prevElements.current.length < elements.length) {
            setAnimations(elements.map(el => el.options.type));
            prevElements.current = elements;
        } else if (prevElements.current.length > elements.length) {
            let timeout: any;
            const callback = () => {
                setAnimations(prevElements.current.map(el => el.options.type));
                prevElements.current = elements;
                timeout = undefined;
            };

            timeout = setTimeout(callback, ANIMATION_DURATION);

            return () => {
                if (timeout) {
                    clearTimeout(timeout);
                    callback();
                }
            };
        }
    }, [elements]);

    const renderedElements = (
        <>
            <Fade
                transitionEnterDuration={ANIMATION_DURATION}
                transitionExitDuration={ANIMATION_DURATION}
                exit={true}
            >
                {elements.length > 0 && <Overlay />}
            </Fade>

            {animations.map((el, index) => {
                const { Component, options } = elements[index] || {};

                if (el === 'modal') {
                    return (
                        <Fade
                            key={index}
                            appear
                            transitionEnterDuration={ANIMATION_DURATION}
                            transitionExitDuration={ANIMATION_DURATION}
                            exit={true}
                        >
                            {Component && (
                                <div
                                    key={options.id}
                                    className={classNames('ct-overlay__modal', { 'ct-overlay__modal--overlayed': index < elements.length - 1 })}
                                    style={{ '--shadow-level': (elements.length - index - 1) / 10 } as CSSProperties}
                                >
                                    <Component id={options.id} close={() => hideElement(options.id)} />
                                </div>
                            )}
                        </Fade>
                    );
                } else if (el === 'panel') {
                    return (
                        <Slide
                            key={index}
                            direction={'left'}
                            exit={true}
                            appear
                            transitionEnterDuration={ANIMATION_DURATION}
                            transitionExitDuration={ANIMATION_DURATION}
                            className={'ct-overlay__panel-animation'}
                        >
                            {Component && (
                                <div
                                    key={options.id}
                                    className={classNames('ct-overlay__panel', { 'ct-overlay__panel--overlayed': index < elements.length - 1 })}
                                    style={{ '--shadow-level': (elements.length - index - 1) / 10 } as CSSProperties}
                                >
                                    <Component id={options.id} close={() => hideElement(options.id)} />
                                </div>
                            )}
                        </Slide>
                    );
                }

                return null;
            })}

        </>
    );

    return (
        <>
            <div className={'ct-overlay'}>
                {renderedPanel}
                {renderedModals}
                {renderedElements}
            </div>
            {notifications.length > 0 && (
                <div>notification</div>
            )}
        </>
    );
};
