import { NdsIconFont } from '@gonitro/rcl';
import './flowEditorTextFieldProperties.scss';

export interface FlowEditorTextFieldPropertiesProps {

}

function FlowEditorTextFieldProperties(props: FlowEditorTextFieldPropertiesProps) {
    return (
        <div className={'c-flow-editor-text-field-properties c-flow-editor-right-pane-component'}>
            flow-editor-text-field-properties
        </div>
    );
}

FlowEditorTextFieldProperties.Header = () => {
    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                <NdsIconFont fontName={'fa-regular-input-text'} />
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                Text field
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}></div>
        </div>
    );
};
export default FlowEditorTextFieldProperties;
