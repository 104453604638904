import { useCallback, useMemo } from 'react';
import QuickActionsTable from '~components/quick-actions-table';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { FlowAction, Permission } from '~constants';
import { useHasPermission } from '~contexts/auth';

export interface ContactQuickActionsProps {
    onEditClick: () => void;
    onDeleteClick: () => void;
    isShared: boolean;
}

function ContactQuickActions({ onEditClick, onDeleteClick, isShared }: ContactQuickActionsProps) {
    const hasDeleteSharedContactPermission = useHasPermission(Permission.General_ActionDeleteSharedContact);
    const hasEditSharedContactPermission = useHasPermission(Permission.General_ActionEditSharedContact);

    const quickActions = useMemo((): QuickAction<FlowAction>[][] => {
        const actions: QuickAction<FlowAction>[] = [];

        // Only push the edit action if the permission exists or it's not shared
        if (hasEditSharedContactPermission || !isShared) {
            actions.push({
                icon: 'fa-solid-edit',
                type: FlowAction.Edit,
            });
        }

        // Only push the delete action if the permission exists or it's not shared
        if (hasDeleteSharedContactPermission || !isShared) {
            actions.push({
                icon: 'fa-solid-trash',
                type: FlowAction.Delete,
            });
        }

        return [actions];
    }, [hasDeleteSharedContactPermission, hasEditSharedContactPermission, isShared]);

    const openQuickActionsModal = useCallback((actionType: FlowAction) => {
        switch (actionType) {
            case FlowAction.Edit:
                return onEditClick();
            case FlowAction.Delete:
                return onDeleteClick();
        }
    }, [onDeleteClick, onEditClick]);

    return (
        <div className={'c-contact-quick-actions'}>
            <QuickActionsTable actions={quickActions} handleActionClick={openQuickActionsModal} />
        </div>
    );
}

export default ContactQuickActions;
