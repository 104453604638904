import classNames from 'classnames';
import { isEqual } from 'lodash';
import {
    ForwardedRef,
    forwardRef,
    HTMLAttributes,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { CommonUtils } from '~lib';
import { Guid } from '~models';
import DocumentViewerPageObjects from './document-viewer-page-objects';
import DocumentViewerPages from './document-viewer-pages';
import DocumentViewerPagination from './document-viewer-pagination';
import {
    useDocumentViewerPagePositions,
    useDocumentViewerPagesInit,
    useDocumentViewerRenderIndexes,
    useDocumentViewerResize,
    useDocumentViewerScrollToPageCallback,
    useDocumentViewerScrollTrackerHandler,
    useScrollTracker,
} from './hooks';
import {
    DocumentViewerDocumentModel,
    DocumentViewerHeaderComponent,
    DocumentViewerPageObjectModel,
    DocumentViewerPaginatorComponent,
    OnVisiblePageChangeParams,
} from './types';
import './documentViewer.scss';


const PADDINGS = {
    top: '2.5rem',
    right: '2.5rem',
    bottom: '4.5rem', // bigger because of pagination
    left: '2.5rem',
};

const DEBOUNCE_TIME = 200;

export interface DocumentViewerRef {
    /**
     * @param index if number it's an absolute index of pages, if object it should be document index or id and page index
     */
    scrollToPage: (index: number | { document: number | string; page: number }) => void;
}

export interface DocumentViewerProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * Documents list
     */
    documents: DocumentViewerDocumentModel[];
    /**
     * Distance between two pages, in px or rem, starts after first page
     * @default 1rem
     */
    pageGutter?: number | string;
    /**
     * Distance before every page, in px or rem, useful to make space for PageHeaderComponent
     * @default 0
     */
    prePageGutter?: number | string;
    /**
     * Distance before every document, in px or rem
     * Can be useful for some extra header block before each document (like in WYSIWYS) - not implemented
     * @default 0
     */
    preDocumentGutter?: number | string;
    /**
     * 'Recommended' number of pages that should be rendered at a time.
     * Algorithm can extend this value if provided number is too low
     * e.g. when pages are small so a lot of them can fit in viewport
     * and/or provided number is relatively low
     * @default 8
     */
    renderPagesQuantity?: number;
    /**
     * 'Recommended' number of pages that should be fetched at once.
     * Can't be lower than renderPagesQuantity and should be a multiple of 4
     * @default 16
     */
    requestPagesQuantity?: number;

    /**
     * Visible pages change handler, sends ordered value with all pages that are in viewport and all fully visible pages
     * @param data @see OnVisiblePageChangeParams
     */
    onVisiblePageChange?: (data: OnVisiblePageChangeParams) => void;

    /**
     * Callback used to fetch document pages
     * @param documentId document id
     * @param startIndex 0 based index
     * @param amount number of pages to be fetched
     * @param signal AbortSignal
     */
    fetchDocumentPages: (documentId: Guid, startIndex: number, amount: number, signal?: AbortSignal) => Promise<string[]>;

    Paginator: DocumentViewerPaginatorComponent;

    /**
     * Zoom percentage value (only number)
     */
    zoom?: number; // zoom percent value

    justifyPages?: boolean;
    adjustToScreen?: boolean;

    /**
     * Allows to set custom paddings of viewer
     */
    paddings?: typeof PADDINGS;

    pageObjects?: DocumentViewerPageObjectModel[];

    PageHeaderComponent?: DocumentViewerHeaderComponent;
    DocumentHeaderComponent?: DocumentViewerHeaderComponent;
}

function DocumentViewer({
    documents,
    preDocumentGutter = 0,
    pageGutter = '1rem',
    prePageGutter = 0,
    onVisiblePageChange,
    requestPagesQuantity: requestPagesQuantityProp = 16,
    renderPagesQuantity: renderPagesQuantityProp = 8,
    fetchDocumentPages,
    Paginator,
    zoom = 100,
    justifyPages = true,
    adjustToScreen = false,
    paddings = PADDINGS,
    PageHeaderComponent,
    DocumentHeaderComponent,
    pageObjects,
    className,
    ...props
}: DocumentViewerProps, ref: ForwardedRef<DocumentViewerRef>) {
    const { preDocumentGutterPx, pageGutterPx, prePageGutterPx, paddingsPx } = useMemo(() => {
        return {
            preDocumentGutterPx: CommonUtils.toPx(preDocumentGutter),
            pageGutterPx: CommonUtils.toPx(pageGutter),
            prePageGutterPx: CommonUtils.toPx(prePageGutter),
            paddingsPx: {
                top: CommonUtils.toPx(paddings.top ?? PADDINGS.top),
                right: CommonUtils.toPx(paddings.right ?? PADDINGS.right),
                bottom: CommonUtils.toPx(paddings.bottom ?? PADDINGS.bottom),
                left: CommonUtils.toPx(paddings.left ?? PADDINGS.left),
            },
        };
    }, [
        preDocumentGutter,
        paddings.bottom,
        paddings.left,
        paddings.right,
        paddings.top,
        pageGutter,
        prePageGutter,
    ]);

    const containerRef = useRef<HTMLDivElement>(null);
    const contentGhostRef = useRef<HTMLDivElement>(null);
    const [visiblePageIndexes, setVisiblePageIndexes] = useState<number[]>([]);
    const [fullyVisiblePageIndexes, setFullyVisiblePageIndexes] = useState<number[]>([]);

    /** --- PAGE CALCULATIONS GROUP START --- */
    // almost static, calculated only on useOriginalSizes change as documents can't change
    const { pages, contentWidth } = useDocumentViewerPagesInit(documents, justifyPages);

    const scrollWidth = containerRef.current ? containerRef.current.offsetWidth - containerRef.current.clientWidth : 0;

    // dynamic, based on screen width
    const { scale, ghostWidth, leftOffset } = useDocumentViewerResize(
        containerRef,
        contentWidth,
        zoom,
        paddingsPx.left + paddingsPx.right,
        adjustToScreen,
        DEBOUNCE_TIME,
    );

    // dynamic and heavy
    const { pagePositions, contentHeight } = useDocumentViewerPagePositions(
        contentGhostRef,
        pages,
        Math.max(paddingsPx.left, leftOffset), // either left padding or if zoom < 100 calculated distance
        scale,
        preDocumentGutterPx,
        pageGutterPx,
        prePageGutterPx,
        justifyPages,
    );
    /** --- PAGE CALCULATIONS GROUP END --- */

    /** --- SCROLLING GROUP START --- */
    // useEffect together with onScroll callback below are to limit number of onVisiblePageChange calls to minimum
    useEffect(() => {
        if (visiblePageIndexes.length) {
            onVisiblePageChange?.({
                visiblePages: visiblePageIndexes.map(idx => pages[idx]),
                fullyVisiblePages: fullyVisiblePageIndexes.map(idx => pages[idx]),
            });
        }
    }, [fullyVisiblePageIndexes, onVisiblePageChange, pages, visiblePageIndexes]);

    // onScroll callback together with useEffect above are to limit number of onVisiblePageChange calls to minimum
    const onScroll = useCallback((inViewportIndexes: number[], fullyVisibleIndexes: number[]) => {
        setVisiblePageIndexes((prevState) => {
            if (isEqual(prevState, inViewportIndexes)) {
                return prevState;
            }

            return inViewportIndexes;
        });
        setFullyVisiblePageIndexes((prevState) => {
            if (isEqual(prevState, fullyVisibleIndexes)) {
                return prevState;
            }

            return fullyVisibleIndexes;
        });
    }, []);

    const scrollTrackerHandler = useDocumentViewerScrollTrackerHandler(
        containerRef,
        pagePositions,
        onScroll,
        pageGutterPx,
    );

    useScrollTracker(containerRef, scrollTrackerHandler, DEBOUNCE_TIME);

    /** --- SCROLLING GROUP END --- */

    // if there is zoom > 100% limit number of rendered or requested pages
    const renderPagesQuantity = useMemo(() => Math.round(renderPagesQuantityProp / (Math.max(zoom, 100) / 100)), [zoom, renderPagesQuantityProp]);
    const requestPagesQuantity = useMemo(() => Math.round(requestPagesQuantityProp / (Math.max(zoom, 100) / 100)), [zoom, requestPagesQuantityProp]);

    const [firstRenderIndex, middleRenderIndex, lastRenderIndex] = useDocumentViewerRenderIndexes(
        visiblePageIndexes,
        renderPagesQuantity,
        pages.length - 1,
    );

    const scrollToPage = useDocumentViewerScrollToPageCallback(
        containerRef,
        pages,
        pagePositions,
        pageGutterPx,
    );

    useImperativeHandle(ref, () => {
        return { scrollToPage };
    }, [scrollToPage]);

    return (
        <div className={classNames('c-document-viewer', className)} {...props}>
            <div
                className={'c-document-viewer__content'}
                ref={containerRef}
                style={{
                    paddingLeft: paddingsPx.left,
                    paddingRight: paddingsPx.right,
                    paddingTop: paddingsPx.top,
                    paddingBottom: paddingsPx.bottom,
                }}
            >
                <div
                    className={'c-document-viewer__content-ghost'}
                    ref={contentGhostRef}
                    style={{
                        height: `${contentHeight}px`,
                        width: `${ghostWidth - scrollWidth - 0.1}px`, // .1px won't be noticed, but it fixes visible scrollbar for zoom=100 and browser zoom != 100
                    }}
                />

                {pagePositions.length && middleRenderIndex > -1 && (
                    <DocumentViewerPages
                        pages={pages}
                        pagePositions={pagePositions}
                        firstRenderIndex={firstRenderIndex}
                        middleRenderIndex={middleRenderIndex}
                        lastRenderIndex={lastRenderIndex}
                        requestPagesQuantity={requestPagesQuantity}
                        fetchDocumentPages={fetchDocumentPages}
                        PageHeaderComponent={PageHeaderComponent}
                        DocumentHeaderComponent={DocumentHeaderComponent}
                    />
                )}
                {pageObjects?.length && middleRenderIndex > -1 && (
                    <DocumentViewerPageObjects
                        containerRef={containerRef}
                        pages={pages}
                        pageObjects={pageObjects}
                        pagePositions={pagePositions}
                        firstRenderIndex={firstRenderIndex}
                        middleRenderIndex={middleRenderIndex}
                        lastRenderIndex={lastRenderIndex}
                        scale={scale}
                        justifyPages={justifyPages}
                    />
                )}
            </div>
            {pagePositions.length > 1 && pages.length > fullyVisiblePageIndexes.length && (
                <DocumentViewerPagination
                    totalPages={pages.length}
                    currentPage={(fullyVisiblePageIndexes[0] ?? visiblePageIndexes[0] ?? 0) + 1}
                    Paginator={Paginator}
                    onPageChange={(val) => scrollToPage(val - 1, 'auto')}
                    style={{ marginBottom: zoom > 100 ? `${scrollWidth}px` : undefined }}
                />
            )}
        </div>
    );
}

export default forwardRef<DocumentViewerRef, DocumentViewerProps>(DocumentViewer);
