import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { BaseRoutes } from '~constants/routes';
import { SimpleLayoutContainer } from '~containers/layout';
import { useAuthContext } from '~contexts/auth';
import { OverlayContextProvider } from '~contexts/overlay';

export interface UnauthorizedPageContainerProps extends PropsWithChildren {

}

export function UnauthorizedPageContainer({ children, ...props }: UnauthorizedPageContainerProps) {
    const authContext = useAuthContext();

    if (authContext.isAuthenticated) {
        return <Navigate to={window.history.state?.usr?.from ?? BaseRoutes.Index} replace />;
    }

    // This Container can cover unauthorized logic which will be quite simple
    return (
        <OverlayContextProvider>
            <SimpleLayoutContainer>
                {children}
            </SimpleLayoutContainer>
        </OverlayContextProvider>
    );
}