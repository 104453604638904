import { useMemo } from 'react';
import { AppLocalStorage } from '~lib';
import { FlowStakeholderModel, Guid, StakeholderType } from '~models';

const COLORS_QUANTITY = 10;
type StakeholderColorStoredModel = { lastUsed: number, colors: Record<Guid, number> };
export const useFlowInternalStakeholderColors = (flowId: Guid | undefined, stakeholders: FlowStakeholderModel[]) => {

    return useMemo(() => {
        if (!flowId || !stakeholders.length) {
            return {};
        }
        // localIds are temporary, ids are permanent so use them first
        const ids = stakeholders.filter((s) => s.type !== StakeholderType.Undecided).map(s => s.id ?? s.localId);

        const stored = AppLocalStorage.get<Record<Guid, StakeholderColorStoredModel>>('stakeholderColors') ?? {};
        const current = stored[flowId]
            ? {
                ...stored[flowId],
                lastUsed: Date.now(),
            }
            : stored[flowId] = {
                lastUsed: Date.now(),
                colors: {},
            };

        // storing up to 5 maps so remove 5th one to make place for current flow
        if (!stored[flowId] && Object.keys(stored).length >= 5) {
            let id: Guid | null = null;
            let time: number = Date.now();
            for (const [key, value] of Object.entries(stored)) {
                if (value.lastUsed < time) {
                    id = key;
                }
                time = value.lastUsed;
            }
            if (id) {
                delete stored[id];
            }
            stored[flowId] = current;
        }

        // remove colors from non-existing stakeholders
        for (const id of Object.keys(current.colors)) {
            if (!ids.includes(id)) {
                delete current.colors[id];
            }
        }
        let usedColors: number[] = [];

        // leaving for reference, it's better to keep duplicated color instead changing to not confuse user

        // // remove duplicates from existing. It won't work well for more stakeholders than COLORS_QUANTITY.
        // // It should check if duplicated colors aren't necessary and should stay
        // for (const [id, color] of Object.entries(current.colors).slice(usedColors.length)) {
        //     if (!usedColors.includes(color)) {
        //         usedColors.push(color);
        //     } else {
        //         for (let i = 0; i < COLORS_QUANTITY; i++) {
        //             if (!usedColors.includes(i)) {
        //                 usedColors.push(i);
        //                 current.colors[id] = i;
        //                 break;
        //             }
        //         }
        //     }
        //     if (usedColors.length === COLORS_QUANTITY) {
        //         usedColors = [];
        //     }
        // }

        for (const id of ids) {
            // stakeholder has color assigned
            if (current.colors[id] !== undefined) {
                continue;
            }
            for (let i = 0; i < COLORS_QUANTITY; i++) {
                if (!usedColors.includes(i)) {
                    usedColors.push(i);
                    current.colors[id] = i;
                    break;
                }
            }
            if (usedColors.length === COLORS_QUANTITY) {
                usedColors = [];
            }
        }

        AppLocalStorage.set('stakeholderColors', stored);
        const ret: Record<Guid, number> = {};
        for (const [id, color] of Object.entries(current.colors)) {
            ret[stakeholders.find(s => s.id === id)?.localId ?? id] = color;
        }

        return ret;
    }, [flowId, stakeholders]);
};
