import { NdsButton } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PortalsApi } from '~api/portals.api';
import Content from '~components/content';
import Loader from '~components/loader';
import { DocumentGroupPermission, FlowAction, FlowStatusEnum } from '~constants';
import { BaseRoutes, NestedRoutes } from '~constants/routes';
import { PortalTableContainer } from '~containers/portal-table';
import { useApi } from '~contexts/api';
import { useActualWorkspace, useWorkspaceId } from '~contexts/app';
import { useHasDocumentGroupPermissionFunc } from '~contexts/auth';
import { useCurrentLanguage, useTranslation } from '~contexts/i18n';
import { TableContextProvider } from '~contexts/table';
import { FetchTableDataDelegate } from '~contexts/table/table.types';
import { TemplateListTableDataModel } from '~models/portals.models';
import NoTemplatesCreatedYet from './no-documents-created-yet';
import { TemplateTableDefinition } from './table-definition-template';
import TemplatePortalQuickActions from './template-portal-quick-actions';
import { useTemplateTableFilters } from './use-template-table-filters';

export interface TemplatePortalViewProps {
}

function TemplatePortalView() {
    const { t: tPortals } = useTranslation('portals');
    const workspace = useActualWorkspace()!;
    const workspaceId = useWorkspaceId()!;
    const hasDocGroupPermission = useHasDocumentGroupPermissionFunc();

    const portalsApi = useApi(PortalsApi);
    const { languageCode } = useCurrentLanguage();

    const fetchData: FetchTableDataDelegate<TemplateListTableDataModel> = useCallback(async (params, signal?: AbortSignal) => {
        return portalsApi.getTemplatesList({
            workspaceId: workspace.workspaceId,
            language: languageCode,
            ...params,
        }, signal);
    }, [languageCode, portalsApi, workspace.workspaceId]);

    const onQuickActionClick = useCallback((actionType: FlowAction, data: TemplateListTableDataModel) => {
        // eslint-disable-next-line no-console
        console.log(actionType, data);
    }, []);

    const filters = useTemplateTableFilters();

    const pageTitle = useMemo(() => {
        if (workspace.isDefault) {
            return tPortals('title-my-templates');
        } else {
            return workspace.workspaceName;
        }
    }, [tPortals, workspace.isDefault, workspace.workspaceName]);

    return (
        <Content noDefaultPadding>
            <TableContextProvider<'templateId', TemplateListTableDataModel>
                definition={TemplateTableDefinition}
                fetchData={fetchData}
            >
                <PortalTableContainer<'templateId', TemplateListTableDataModel>
                    pageTitle={pageTitle}
                    filtersDefinition={filters}
                    headerButton={
                        hasDocGroupPermission(workspaceId, DocumentGroupPermission.CreateTemplate) && (

                            <Link
                                to={`${BaseRoutes.Template}/${NestedRoutes.Documents}`}
                                state={{ workspaceId }}
                            >
                                <NdsButton size={SizesEnums.SMALL} label={tPortals('btn-create-new-template')} />
                            </Link>
                        )
                    }
                    renderColumns={(data) => {
                        return {
                            createdOn: 'Missing Response',
                            code: data.code,
                            action: data.status.toUpperCase() === FlowStatusEnum.Processing ?
                                <Loader size={SizesEnums.MEDIUM} />
                                : <TemplatePortalQuickActions data={data} onClick={onQuickActionClick} />,
                        };
                    }}
                    renderNoItemsYet={<NoTemplatesCreatedYet />}
                />
            </TableContextProvider>
        </Content>
    );
}

export default TemplatePortalView;
