import { ConfirmModal, Modal, ModalContent, ModalFooter, NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { BkgModesEnum } from '@gonitro/ui-design-system/dist/lit';
import { useCallback, useRef, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import { ReassignPackageModel } from '~api/portals.models';
import Error from '~components/error';
import Loader from '~components/loader';
import { FlowAction } from '~constants';
import { UserManagementAction } from '~constants/user-management-action';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { ModalPortal } from '~contexts/overlay';
import { DateUtil } from '~lib/date.utils';
import { Guid, LanguageDataLabel } from '~models';
import { PackageListItemModel, SigningSessionListItemModel, TemplateListItemModel } from '~models/portals.models';
import QuickActionExtend from './quick-action-extend';
import ReassignContentQuickAction from './reassign-content-quick-action';
import './quickActionsModal.scss';

const ButtonI18n: Partial<Record<FlowAction | UserManagementAction, LanguageDataLabel<'flow'>>> = {
    [FlowAction.End]: 'action-end-package',
    [FlowAction.Delete]: 'action-delete',
    [FlowAction.SendToSigners]: 'action-send-to-signers',
    [FlowAction.SendReminder]: 'action-send-reminder',
    [FlowAction.Revoke]: 'action-revoke',
    [FlowAction.Extend]: 'action-extend',
    [FlowAction.Reassign]: 'action-reassign',
    [FlowAction.SoftDelete]: 'action-delete',
};

type ListItemModel = PackageListItemModel | SigningSessionListItemModel | TemplateListItemModel | undefined;

export interface QuickActionsModalProps {
    data: ListItemModel;
    actionType: FlowAction | UserManagementAction | undefined;
    handleCloseModal: () => void;
    handleClickConfirm: () => void;
    additionalInformation?: LanguageDataLabel<'flow'>;
    warningMessage?: LanguageDataLabel<'flow'>;
}

export default function QuickActionsModal({
    data,
    actionType,
    handleCloseModal,
    handleClickConfirm,
    additionalInformation,
    warningMessage,
}: QuickActionsModalProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const { t: tFlow } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');
    const formRef = useRef<HTMLFormElement>(null);
    const portalsApi = useApi(PortalsApi);

    const isPackageListItemModel = (data: any): data is PackageListItemModel => {
        return (data as PackageListItemModel).documentName !== undefined;
    };

    const handleSubmitExtend = useCallback(
        (expirationTimestamp: string, packageId: Guid) => {
            portalsApi.extendPackage({
                packageId,
                expirationTimestamp,
            });

            handleCloseModal();
        },
        [handleCloseModal, portalsApi],
    );

    const handleSubmitReassign = useCallback(
        async (formData: ReassignPackageModel.Params) => {
            setIsLoading(true);
            try {
                await portalsApi.reassignPackage(formData);
                handleCloseModal();
            } catch (error) {
                setError('something-went-wrong');
            } finally {
                setIsLoading(false);
            }
        },
        [handleCloseModal, portalsApi],
    );

    // Check to see if the data model is the template model
    const isTemplateListItemModel = (data: ListItemModel): data is TemplateListItemModel => {
        return (data as TemplateListItemModel).name !== undefined;
    };

    // Function to determine which modal to render
    return (
        <>
            <ModalPortal visible={data !== undefined}>
                {actionType && data && (
                    <>
                        {warningMessage && (
                            <ConfirmModal
                                bkgMode={BkgModesEnum.LIGHT}
                                className={'c-quick-actions-modal'}
                                size={SizesEnums.MEDIUM}
                                confirmLabel={tFlow(ButtonI18n[actionType]!)}
                                description={additionalInformation && tFlow(additionalInformation)}
                                cancelLabel={tBase('cancel')}
                                title={warningMessage && tFlow(warningMessage)}
                                onCloseClick={handleCloseModal}
                                onConfirmClick={handleClickConfirm}
                            >
                                <div className='c-quick-actions-modal__confirm-content'>
                                    <div className='c-quick-actions-modal__confirm-item'>
                                        <div>
                                            {isPackageListItemModel(data)
                                                ? data.documentName
                                                : isTemplateListItemModel(data)
                                                    ? data.name
                                                    : data.packageName}
                                        </div>
                                        <div>
                                            {data.createdOn
                                                ? DateUtil.format(data.createdOn, 'dd/MM/yyyy')
                                                : 'Missing response'}
                                        </div>
                                    </div>
                                </div>
                            </ConfirmModal>
                        )}
                        {!warningMessage && !isTemplateListItemModel(data) && (
                            <Modal
                                headerLabel={tFlow(ButtonI18n[actionType]!)}
                                bkgMode={BkgModesEnum.LIGHT}
                                size={SizesEnums.SMALL}
                                onCloseClick={handleCloseModal}
                                withClose={true}
                                className={'c-quick-actions-modal'}
                                renderContent={
                                    <ModalContent>
                                        {actionType === FlowAction.Extend
                                            ? (
                                                <QuickActionExtend
                                                    onSubmit={(expirationIso: string) => {
                                                        data.packageId && handleSubmitExtend(expirationIso, data.packageId);
                                                    }}
                                                    formRef={formRef}
                                                />
                                            )
                                            : (
                                                isPackageListItemModel(data) && (
                                                    <>
                                                        {error && <Error
                                                            className='c-quick-actions-modal__error-text'
                                                            i18nKey={error}
                                                        />}
                                                        <ReassignContentQuickAction
                                                            data={data}
                                                            submitForm={handleSubmitReassign}
                                                            formRef={formRef}
                                                        />
                                                    </>
                                                )
                                            )}
                                    </ModalContent>
                                }
                                renderFooter={
                                    <ModalFooter>
                                        <div className={'c-quick-actions-modal__extend-reassign-footer-buttons'}>
                                            <NdsButton
                                                size={SizesEnums.SMALL}
                                                label={tBase('cancel')}
                                                buttonType={NdsButtonTypesEnum.NEUTRAL}
                                                onClick={handleCloseModal}
                                            />
                                            {isLoading
                                                ? (
                                                    <div className='c-quick-actions-modal__loader'>
                                                        <Loader size={SizesEnums.SMALL} />
                                                    </div>
                                                )
                                                : (
                                                    <NdsButton
                                                        state={StatesEnum.DEFAULT}
                                                        size={SizesEnums.SMALL}
                                                        label={tFlow(ButtonI18n[actionType]!)}
                                                        onClick={() =>
                                                            actionType === FlowAction.Extend
                                                                ? formRef.current?.requestSubmit()
                                                                : formRef.current?.onSubmit()
                                                        }
                                                    />
                                                )}
                                        </div>
                                    </ModalFooter>
                                }
                            />
                        )}
                    </>
                )}
            </ModalPortal>
        </>
    );
}
