import { NdsIconFont } from '@gonitro/rcl';
import { Input } from '@progress/kendo-react-inputs';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { useFlowState, useSetFlowState } from '~contexts/flow';
import { FlowActionType } from '~contexts/flow/flow.types';
import './flowEditorPackageProperties.scss';

export interface FlowEditorPackagePropertiesProps {

}

function FlowEditorPackageProperties(props: FlowEditorPackagePropertiesProps) {
    const { packageName } = useFlowState();
    const setFlowState = useSetFlowState();

    return (
        <div className={'c-flow-editor-package-properties c-flow-editor-right-pane-component'}>
            <PanelBar keepItemsMounted>
                <PanelBarItem
                    title={'Package name'}
                    expanded
                >
                    <Input
                        value={packageName}
                        onChange={(e) => setFlowState(FlowActionType.UpdatePackageName, e.value)}
                    />
                </PanelBarItem>

                <PanelBarItem
                    title={'language'}
                    expanded
                >input</PanelBarItem>
                <PanelBarItem
                    title={'workspace'}
                    expanded
                >input</PanelBarItem>
                <PanelBarItem
                    title={'theme'}
                    expanded
                >input</PanelBarItem>
                <PanelBarItem
                    title={'advanced settings'}
                >many inputs</PanelBarItem>
                <PanelBarItem
                    title={'email sent to signers'}
                    expanded
                >input</PanelBarItem>
            </PanelBar>
        </div>
    );
}

FlowEditorPackageProperties.Header = () => {
    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                <NdsIconFont fontName={'fa-regular-box'} />
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                Package properties
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}></div>
        </div>
    );
};

export default FlowEditorPackageProperties;
