import { AuthorizedPageContainer } from '~containers/page';

export interface ProfileViewProps {}

function ProfileView () {
    return (
        <AuthorizedPageContainer>
            ProfileView view
        </AuthorizedPageContainer>
    );
}

export default ProfileView;