import { Checkbox, NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useAsyncEffect } from '~hooks/effects';
import { PackageDocument } from '~models/package-document.model';
import './bulkActionsModalItem.scss';

export interface BulkActionsModalItemProps {
    actionId: string;
    name: string;
    onSelectionChange: (actionId: string) => void;
    isSelected: boolean;
}

function BulkActionsModalItem({ actionId, name, onSelectionChange, isSelected }: BulkActionsModalItemProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const portalsApi = useApi(PortalsApi);
    const [documents, setDocuments] = useState<PackageDocument[]>([]);

    const toggleExpand = () => {
        setIsExpanded((prevIsExpanded) => !prevIsExpanded);
    };

    useAsyncEffect(() => async (signal) => {
        if (isExpanded && !documents.length) {
            try {
                const res = await portalsApi.getDocumentsFromPackages(
                    { actionId },
                    signal,
                );

                setDocuments(res.documents);
            } catch (error) {
                console.error('Error fetching documents from package:', error);
            }
        }
    }, [actionId, documents.length, isExpanded, portalsApi]);

    return (
        <div className={'c-bulk-actions-modal-item'}>
            <div className={'c-bulk-actions-modal-item__header'}>
                <div>
                    <Checkbox
                        value={isSelected}
                        size={SizesEnums.SMALL}
                        name={actionId}
                        onChange={() => {
                            onSelectionChange(actionId);
                        }}
                    />
                </div>
                <div onClick={toggleExpand} className={'c-bulk-actions-modal-item__icon-container arrow-icon'}>
                    {isExpanded
                        ? (
                            <NdsIconFont fontName='fa-solid-chevron-up' />
                        )
                        : (
                            <NdsIconFont fontName='fa-solid-chevron-down' />
                        )}
                </div>
                <Typography
                    className={'c-bulk-actions-modal-item__ellipsis-text'}
                    text={name}
                    token={TypographyToken.DesktopDescriptionSm}
                />
            </div>
            {isExpanded &&
                documents.map((document: PackageDocument) => (
                    <div className={'c-bulk-actions-modal-item__additional-information'} key={document.id}>
                        <div className={'c-bulk-actions-modal-item__header'}>
                            <div className={'c-bulk-actions-modal-item__icon-container'}>
                                <NdsIconFont fontName='fa-light-file' />
                            </div>
                            <Typography
                                className={'c-bulk-actions-modal-item__ellipsis'}
                                text={document.name}
                                token={TypographyToken.DesktopDescriptionSm}
                            />
                        </div>
                    </div>
                ))}
        </div>
    );
}

export default BulkActionsModalItem;
