import { SizesEnums } from '@gonitro/rcl/lib/_types';
import i18next from 'i18next';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { AuthApi } from '~api/auth.api';
import { ContactsApi } from '~api/contacts.api';
import { PackageApi } from '~api/package.api';
import { PortalsApi } from '~api/portals.api';
import { SettingsApi } from '~api/settings.api';
import { TemplateApi } from '~api/template.api';
import { UserManagementApi } from '~api/user-management.api';
import Loader from '~components/loader';
import { SERVER_URL } from '~constants';
import { ApiContextProvider } from '~contexts/api';
import { AuthContextProvider } from '~contexts/auth';
import { I18nContextProvider } from '~contexts/i18n';
import { SettingsContextProvider } from '~contexts/settings';
import { rootRoutes } from './routes';


export interface AppContainerProps {
}

const availableApis = [
    AuthApi,
    PortalsApi,
    ContactsApi,
    PackageApi,
    SettingsApi,
    TemplateApi,
    UserManagementApi,
];

const routerOptions = { basename: '/' };

const router = createHashRouter(rootRoutes, routerOptions);


export function AppContainer() {
    return (
        <ApiContextProvider apiConstructors={availableApis} baseUrl={SERVER_URL}>
            <AuthContextProvider>
                <SettingsContextProvider>
                    <I18nContextProvider i18next={i18next}>
                        <RouterProvider
                            router={router}
                            fallbackElement={<Loader size={SizesEnums.XLARGE} center />}
                            future={{ v7_startTransition: true }}
                        />
                    </I18nContextProvider>
                </SettingsContextProvider>
            </AuthContextProvider>
        </ApiContextProvider>
    );
}
