export namespace DomUtils {
    export const findParent = (
        node: HTMLElement,
        filterCallback: (current: HTMLElement) => boolean,
        stopOnNode?: HTMLElement,
    ): HTMLElement | undefined => {
        let currentNode = node;
        let found = false;

        while (currentNode && currentNode.parentNode && currentNode !== stopOnNode) {
            found = filterCallback(currentNode);

            if (found) {
                return currentNode;
            }

            currentNode = currentNode.parentNode as HTMLElement;
        }

        return undefined;
    };
}
