import { useMemo } from 'react';
import { PackageApi } from '~api/package.api';
import { SettingsApi } from '~api/settings.api';
import { TemplateApi } from '~api/template.api';
import { FlowStatusEnum } from '~constants';
import { useActiveUserLanguage } from '~hooks/activeUserLanguage';
import {
    ActionNeededValueType,
    FieldModel,
    FlowActionsDocument,
    FlowInfoModel,
    Guid,
    WorkflowModel,
    WorkflowValidationModel,
} from '~models';
import { LegalNoticeModel } from '~models/legal-notice.models';
import { PackageDraftDetails } from '~models/package.models';
import { StakeholderModel } from '~models/stakeholder.models';
import { FlowStateType } from './flow.context';
import { FlowType } from './flow.types';
import { useApi } from '../api';

export interface FlowApiWrapper {
    getBasicInfo: (signal?: AbortSignal) => Promise<FlowInfoModel>;
    getDetails: (signal?: AbortSignal) => Promise<PackageDraftDetails>;
    getStatus: (signal?: AbortSignal) => Promise<{ status: FlowStatusEnum, isActionNeeded?: boolean }>;
    getStakeholders: (signal?: AbortSignal) => Promise<StakeholderModel[]>;
    getElements: (signal?: AbortSignal) => Promise<FieldModel[]>;
    getWorkflow: (signal?: AbortSignal) => Promise<{
        validationResult: WorkflowValidationModel,
        queryResult: WorkflowModel,
    }>;
    getAvailableLegalNotices: (signal?: AbortSignal) => Promise<{
        isLegalNoticeEnabled: boolean;
        legalNotices: LegalNoticeModel[]
    }>;
    createFlow?: never; // specific for each flow type
    deleteDocuments: (flowId: Guid, documentIds: Guid[]) => Promise<boolean>;
    saveDocuments: (flowId: Guid, param: FlowStateType['infoDocuments'], signal?: AbortSignal) => Promise<{
        success: boolean;
        erroredDocsLocalIds: Guid[];
    }>;
    getDocumentActions: (signal?: AbortSignal) => Promise<{ documents: FlowActionsDocument[] }>;
    saveDocumentActions: (values: ActionNeededValueType[], signal?: AbortSignal) => Promise<boolean>;
    getDocumentPages: (documentId: Guid, startIndex: number, amount: number, signal?: AbortSignal) => Promise<string[]>;
}

// this hook unifies different flow type apis into one that can be used in provider
// not all calls are used there, some are too specific and stays in view or other flowType specific component
// when some data or entire flowState is needed pass it to specific method, it's wrong to have flowState as dependency of memo

export const useFlowApiWrapper = (flowType: FlowType, flowId?: Guid): FlowApiWrapper => {
    const settingsApi = useApi(SettingsApi);
    const packageApi = useApi(PackageApi);
    const templateApi = useApi(TemplateApi);
    const userLanguage = useActiveUserLanguage();

    const api: PackageApi | TemplateApi = useMemo(() => {
        switch (flowType) {
            case FlowType.Package:
                return packageApi;
            case FlowType.Template:
                return templateApi;
        }
    }, [flowType, packageApi, templateApi]);

    // there is extra abstraction layer to do some extra mappings for different flow type apis

    return useMemo<FlowApiWrapper>(() => {
        return {
            async getBasicInfo(signal?: AbortSignal) {
                return await api.getBasicInfo({ flowId: flowId! }, signal);
            },
            async getDetails(signal?: AbortSignal) {
                return await api.getDetails({ flowId: flowId! }, signal);
            },
            async getStatus(signal?: AbortSignal) {
                return await api.getFlowStatus({ flowId: flowId! }, signal);
            },
            async getStakeholders(signal?: AbortSignal) {
                return await api.getStakeholders({ flowId: flowId! }, signal);
            },
            async getWorkflow(signal?: AbortSignal) {
                return await api.getWorkflow({ flowId: flowId! }, signal);
            },
            async getElements(signal?: AbortSignal) {
                return await api.getElements({ flowId: flowId! }, signal);
            },
            async deleteDocuments(flowId: Guid, documentIds: Guid[], signal?: AbortSignal) {
                try {
                    for (const documentId of documentIds) {
                        await api.deleteDocument({
                            flowId,
                            documentId,
                        }, signal);
                    }

                    return true;
                } catch (e) {
                    if (signal?.aborted) {
                        return false;
                    }

                    throw e;
                }
            },
            async saveDocuments(flowId: Guid, infoDocuments, signal?: AbortSignal) {
                let savedFiles: number = 0;
                let orderIndex: number = 0;
                const erroredDocsLocalIds: Guid[] = [];

                for (const doc of infoDocuments) {
                    if (doc.templateFile) {
                        orderIndex++;
                        savedFiles++;
                    } else if (doc.isNew) {
                        try {
                            if ('file' in doc) {
                                await api.uploadDocument({
                                    flowId: flowId!,
                                    orderIndex: ++orderIndex,
                                    documentName: doc.documentName,
                                    conversionTargetFormat: doc.conversionTargetFormat,
                                    isOptional: doc.isOptional,
                                    language: userLanguage,
                                    file: doc.file,
                                }, signal);
                            } else if ('isCloud' in doc && doc.isCloud) {
                                await api.uploadCloudDocument({
                                    flowId: flowId!,
                                    orderIndex: ++orderIndex,
                                    documentName: doc.documentName,
                                    conversionTargetFormat: doc.conversionTargetFormat,
                                    isOptional: doc.isOptional,
                                    language: userLanguage,
                                    fileUrl: doc.fileUrl,
                                    fileToken: doc.fileToken,
                                }, signal);
                            }
                            savedFiles++;
                        } catch (e) {
                            orderIndex--;
                            erroredDocsLocalIds.push(doc.localId);
                        }
                    } else {
                        try {
                            await api.updateDocument({
                                flowId: flowId!,
                                documentId: doc.id,
                                orderIndex: ++orderIndex,
                                documentName: doc.documentName,
                                isOptional: doc.isOptional,
                            }, signal);
                        } catch (e) {
                            orderIndex--;
                            erroredDocsLocalIds.push(doc.localId);
                        }
                    }
                }

                return {
                    success: savedFiles === infoDocuments.length,
                    erroredDocsLocalIds,
                };
            },
            async getDocumentActions(signal?: AbortSignal) {
                return api.getDocumentActions({ flowId: flowId! }, signal);
            },
            async saveDocumentActions(values: ActionNeededValueType[], signal?: AbortSignal) {
                return api.saveDocumentActions({
                    flowId: flowId!,
                    values: values.map(val => ({
                        documentId: val.documentId,
                        keepSignatures: val.keepSignatures ?? false,
                        keepFormFields: val.keepFormFields ?? false,
                        keepSigningFields: val.keepSigningFields ?? false,
                    })),
                }, signal);
            },
            async getAvailableLegalNotices(signal?: AbortSignal) {
                return settingsApi.getAvailableLegalNotices(signal);
            },
            async getDocumentPages(documentId, startIndex, amount, signal?: AbortSignal) {
                return (await api.getDocumentPages({
                    documentId,
                    startIndex,
                    amount,
                }, signal)).pages;
            },
        };
    }, [api, flowId, settingsApi, userLanguage]);
};
