import {
    ComboBoxChangeEvent,
    ComboBoxFilterChangeEvent,
    ComboBoxPageChangeEvent,
    ComboBoxProps,
    ListItemProps,
} from '@progress/kendo-react-dropdowns';
import { Loader } from '@progress/kendo-react-indicators';
import { cloneElement, ComponentType, ReactElement, RefObject, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { CommonUtils } from '~lib';
import { PageableListParams, PageableListResult } from '~models/pageable-list.models';

export type VirtualizedComboboxParams<T extends object> = {
    /** size of virtual page, MUST be a double of visible items so depends on height */
    pageSize: number;
    /** debounce used when typing in textbox to filter values, defaults to 300ms*/
    debounceTimeMs?: number;
    /** key used to filter values by backend, usually 'name' or 'searchString' */
    apiFilterKey: string;
    /** fetch data function that returns data of PageableListResult type */
    fetchData: (data: PageableListParams, signal?: AbortSignal) => Promise<PageableListResult<T>>;
    /** emptyItem is used to show something when data is still loading
     * by default it makes object {[valueKey]: null, [labelKey]: KendoIndicator, __empty: true }
     * which can be altered by custom empty item object provided here.
     * '__empty' property is reserved for internal use and shouldn't be part of data
     * be careful when setting this */
    emptyItem?: T;
    /** aka kendo's combobox 'dataItemKey', key in data object used to differentiate items */
    valueKey: NonNullable<ComboBoxProps['dataItemKey']>;
    /** aka kendo's combobox 'textField', key in data object used to show item label and selected item label */
    labelKey: NonNullable<ComboBoxProps['textField']>;
    /** value - currently selected item */
    value: ComboBoxProps['value'];
    /** change event handler that passes selected value */
    onChange: (value: T | null) => void;
    /** custom item renderer, like combobox' itemRender, but it's simplified to be typical component */
    RenderItem?: ComponentType<ListItemProps>;
    /** optional ref to allow resetting the list */
    ref?: RefObject<{ reset: () => void; }>;
};
export type VirtualizedComboboxReturn = Pick<
    ComboBoxProps,
    'virtual' | 'onPageChange' | 'filterable' | 'onFilterChange'
    | 'loading' | 'data' | 'dataItemKey' | 'textField'
    | 'onClose' | 'value' | 'onChange' | 'filter'
    | 'adaptive' | 'itemRender'
>;

type PageRequest = {
    abortController: AbortController;
    promise: Promise<any>;
    pending: boolean;
};

const EmptyItemLoader = () => (
    <span className={'k-list-item-text'} style={{ height: '21px' }}>
        <Loader type='pulsing' themeColor={'info'} />
    </span>
);

export const useVirtualizedCombobox = <T extends object>({
    pageSize,
    debounceTimeMs = 300,
    fetchData,
    apiFilterKey,
    emptyItem,
    valueKey,
    labelKey,
    value,
    onChange,
    RenderItem,
    ref,
}: VirtualizedComboboxParams<T>): VirtualizedComboboxReturn => {
    const filterDebounceRef = useRef(CommonUtils.delayFunc({ debounce: debounceTimeMs }));
    const dataCache = useRef<T[]>([]);
    const pageRequests = useRef<(PageRequest | undefined)[]>([]);

    const [loadings, setLoadings] = useState(0);
    const [filter, setFilter] = useState<string | undefined>(undefined);
    const [total, setTotal] = useState<number>(0);
    const [data, setData] = useState<T[]>([]);

    const skipRef = useRef(0);

    const emptyItemList = useMemo(() => {
        const list: T[] = [];
        while (list.length < pageSize) {
            list.push({
                [valueKey]: null,
                [labelKey]: <EmptyItemLoader />,
                __empty: true,
                ...emptyItem,
            } as T);
        }

        return list;
    }, [emptyItem, labelKey, pageSize, valueKey]);

    const itemRender = useCallback((
        li: ReactElement<HTMLLIElement>,
        itemProps: ListItemProps,
    ) => {
        const itemChildren = (
            itemProps.dataItem.__empty
                ? itemProps.dataItem[labelKey]
                : RenderItem
                    ? <RenderItem {...itemProps} /> // customized
                    : (
                        <span className={'k-list-item-text'}>{itemProps.dataItem[labelKey]}</span> // default combobox render
                    )
        );

        return cloneElement(li, li.props, itemChildren);
    }, [RenderItem, labelKey]);

    const fetchPage = useCallback((page: number, filter?: string) => {
        if (pageRequests.current[page]) {
            return pageRequests.current[page]!.promise;
        }

        const abortController = new AbortController();

        setLoadings(v => ++v);
        const promise = fetchData({
            page: page + 1,
            maxQuantity: pageSize,
            filterValues: { [apiFilterKey]: filter },
        }, abortController.signal).then((response: PageableListResult<T>) => {
            for (let i = 0; i < response.items.length; i++) {
                dataCache.current[pageSize * page + i] = response.items[i];
            }
            setTotal(response.totalItems);
            setLoadings(v => --v);

            if (pageRequests.current[page]) {
                pageRequests.current[page]!.pending = false;
            }

            return response;
        }, (e) => {
            if (!abortController.signal.aborted) {
                console.error(e);
            }
            setLoadings(v => --v);
            pageRequests.current[page] = undefined;

            return null;
        });

        pageRequests.current[page] = {
            abortController,
            promise,
            pending: true,
        };

        return promise;
    }, [apiFilterKey, fetchData, pageSize]);

    const fetchDataCallback = useCallback(async (skip: number, filter?: string) => {
        let pageStart: number | undefined = undefined;
        let pageEnd: number | undefined = undefined;

        if (!dataCache.current[skip]) {
            pageStart = Math.floor(skip / pageSize);
        }
        if (!dataCache.current[skip + pageSize]) {
            pageEnd = Math.ceil(skip / pageSize);
        }

        pageRequests.current
            .filter((el: PageRequest | undefined, idx: number) => el && el.pending && idx !== pageStart && idx !== pageEnd)
            .forEach((el) => el?.abortController.abort());

        const fetches = [];

        if (pageStart !== undefined) {
            fetches.push(fetchPage(pageStart, filter));
        }
        if (pageEnd !== undefined && pageEnd !== pageStart) {
            fetches.push(fetchPage(pageEnd, filter));
        }

        const proms = await Promise.all(fetches);

        if (proms.length && proms.every(el => el)) {
            setData(dataCache.current.slice(skipRef.current, skipRef.current + pageSize));
        }
    }, [fetchPage, pageSize]);

    useImperativeHandle(ref, () => {
        return {
            reset() {
                skipRef.current = 0;
                dataCache.current = [];
                pageRequests.current = [];
                setData([]);
                setTotal(0);
                setFilter(undefined);
                fetchDataCallback(0);
            },
        };
    }, [fetchDataCallback]);

    useEffect(() => {
        fetchDataCallback(0, filter);

        const pageRequestRef = pageRequests;

        return () => {
            pageRequestRef.current.forEach(el => el && el.pending && el.abortController.abort());
            pageRequestRef.current.length = 0;
        };
    }, [fetchDataCallback, filter]);

    const onFilterChange = useCallback(({ filter }: ComboBoxFilterChangeEvent) => {
        filterDebounceRef.current(() => {
            const newValue = filter.value?.length ? filter.value : undefined;

            setFilter((current) => {
                if (newValue !== current) {
                    pageRequests.current.forEach(el => el && el.pending && el.abortController.abort());
                    pageRequests.current.length = 0;
                    dataCache.current.length = 0;
                    skipRef.current = 0;
                    setData(emptyItemList);
                }

                return newValue;
            });
        });
    }, [emptyItemList]);

    const onClose = useCallback(() => {
        if (filter) {
            onFilterChange({ filter: { value: undefined } } as ComboBoxFilterChangeEvent);
        }
    }, [filter, onFilterChange]);

    const getCachedData = useCallback((skip: number) => {
        const data: Array<T> = [];
        for (let i = 0; i < pageSize; i++) {
            data.push(dataCache.current[i + skip] || { ...emptyItemList[0] });
        }

        return data;
    }, [emptyItemList, pageSize]);


    const onPageChange = useCallback((event: ComboBoxPageChangeEvent) => {
        skipRef.current = event.page.skip;
        const newSkip = event.page.skip;

        const data = getCachedData(newSkip);

        setData(data);

        fetchDataCallback(skipRef.current, filter);
    }, [fetchDataCallback, filter, getCachedData]);

    const onChangeCallback = useCallback((event: ComboBoxChangeEvent) => {
        if (event.value?.__empty) {
            return;
        }
        onChange?.(event.value);
    }, [onChange]);


    return {
        virtual: {
            pageSize,
            total: total,
            skip: skipRef.current,
        },
        loading: loadings > 0,
        onFilterChange,
        onPageChange,
        onClose,
        filterable: true,
        data: data,
        dataItemKey: valueKey,
        textField: labelKey,
        adaptive: false,
        value: value,
        onChange: onChangeCallback,
        itemRender,
    };
};
