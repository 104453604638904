import { useMemo } from 'react';
import { CloudProvider } from '~constants/cloud-provider';
import { useSettings } from '~contexts/settings';
import { CloudStorageScenario } from '~lib/cloud-storage/cloud-storage.scenario';
import DropboxScenario from '~lib/cloud-storage/dropbox.scenario';
import GoogleDriveScenario from '~lib/cloud-storage/google-drive.scenario';
import OneDriveScenario from '~lib/cloud-storage/one-drive.scenario';

export const useCloudStorageProviderState: () => Record<CloudProvider, boolean> = () => {
    const { cloudSettings } = useSettings();

    return useMemo(() => {
        const {
            dropBox,
            google: { drive: googleDrive },
            microsoft: { oneDrive },
        } = cloudSettings;

        return {
            [CloudProvider.Dropbox]: dropBox.isEnabled && !!dropBox.dropBoxKey,
            [CloudProvider.Google]: googleDrive.isEnabled && !!googleDrive.key && !!googleDrive.clientId,
            [CloudProvider.Microsoft]: oneDrive.isEnabled && !!oneDrive.key,
        };
    }, [cloudSettings]);
};

export const useCloudStorageProviders = (
    mimeTypes: string[],
    allowMultipleFiles: boolean = false,
    onCloudFileUpload: (files: any) => void,
) => {
    const { cloudSettings } = useSettings();
    const states = useCloudStorageProviderState();

    return useMemo(() => {
        const providers: Partial<Record<CloudProvider, CloudStorageScenario>> = {};

        if (states[CloudProvider.Dropbox]) {
            providers[CloudProvider.Dropbox] = new DropboxScenario(
                mimeTypes,
                allowMultipleFiles,
                cloudSettings,
                onCloudFileUpload,
            );
        }
        if (states[CloudProvider.Microsoft]) {
            providers[CloudProvider.Microsoft] = new OneDriveScenario(
                mimeTypes,
                allowMultipleFiles,
                cloudSettings,
                onCloudFileUpload,
            );
        }
        if (states[CloudProvider.Google]) {
            providers[CloudProvider.Google] = new GoogleDriveScenario(
                mimeTypes,
                allowMultipleFiles,
                cloudSettings,
                onCloudFileUpload,
            );
        }

        return { providers };
    }, [
        allowMultipleFiles,
        cloudSettings,
        mimeTypes,
        onCloudFileUpload,
        states,
    ]);
};