import { Button, SplitButton } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import FlowActionNeededModal from '~components/flow/flow-action-needed-modal';
import FlowEditorWrapper from '~components/flow/flow-editor/flow-editor-wrapper';
import FlowViewWrapper from '~components/flow/flow-view-wrapper';
import Typography, { TypographyToken } from '~components/typography';
import { useAppContext } from '~contexts/app';
import { useFlowContext } from '~contexts/flow';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import './package-editor.scss';

export default function PackageEditorView() {
    const navigate = useNavigate();
    const context = useFlowContext();
    const { isActionNeeded, flowState: { packageName } } = context;
    const { lastPortalPath } = useAppContext();

    const onCloseClick = useCallback(() => {
        navigate(lastPortalPath);
    }, [lastPortalPath, navigate]);

    return (
        <FlowViewWrapper
            scrollable
            blurred={isActionNeeded}
            className={'v-package-editor'}
            headerCenter={
                isActionNeeded ?
                    <Skeleton shape={'text'} animation={{ type: 'wave' }} style={{ width: '10rem' }} />
                    : <Typography token={TypographyToken.UiButtonsSm} text={packageName} />
            }
            onCloseClick={onCloseClick}
            footer={(
                <div className={'v-package-editor__footer'}>
                    <SplitButton
                        fillMode={'outline'}
                        themeColor={'secondary'}
                        text={'Save as draft'}
                        size={'medium'}
                        items={['aa']}
                        onButtonClick={() => alert('save draft')}
                        onItemClick={() => alert('item click')}
                    />
                    <Button themeColor={'primary'} size={'medium'}>Confirm and send</Button>
                </div>
            )}
        >
            <FlowEditorWrapper />
            <OverlayPortal type={'modal'} visible={isActionNeeded}>
                {({ close }) => (
                    <FlowActionNeededModal
                        onCloseClick={() => {
                            close();
                            onCloseClick();
                        }}
                        context={context}
                    />
                )}
            </OverlayPortal>
        </FlowViewWrapper>
    );
}
