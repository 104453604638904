import { NdsIconFont } from '@gonitro/rcl';
import { useFlowContext } from '~contexts/flow';
import { StakeholderType } from '~models';
import './flowEditorContactProperties.scss';

export interface FlowEditorContactPropertiesProps {

}

function FlowEditorContactProperties(props: FlowEditorContactPropertiesProps) {
    return (
        <div className={'c-flow-editor-contact-properties c-flow-editor-right-pane-component'}>
            flow-editor-contact-properties
        </div>
    );
}

const Header = () => {
    const { focusedEntityId, flowState: { stakeholders } } = useFlowContext();
    const found = stakeholders.find(el => el.localId === focusedEntityId);

    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                {found?.type === StakeholderType.Person && (
                    <NdsIconFont fontName={'fa-solid-user'} />
                )}
                {found?.type === StakeholderType.Group && (
                    <NdsIconFont fontName={'fa-regular-users'} />
                )}
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                {found?.type === StakeholderType.Person && `${found.firstName ?? ''} ${found.lastName}`}
                {found?.type === StakeholderType.Group && found.name}
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}>
                Edit
            </div>
        </div>
    );
};

FlowEditorContactProperties.Header = Header;
export default FlowEditorContactProperties;
