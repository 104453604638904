import { SvgIcon } from '@progress/kendo-react-common';
import { AutoComplete, AutoCompleteChangeEvent } from '@progress/kendo-react-dropdowns';
import { InputPrefix } from '@progress/kendo-react-inputs';
import { searchIcon } from '@progress/kendo-svg-icons';
import './flowEditorLeftPaneSearch.scss';

export interface FlowEditorLeftPaneSearchProps {
    placeholder?: string;
    data: string[];
    onChange: (ev: AutoCompleteChangeEvent) => void;
}

function FlowEditorLeftPaneSearch({ placeholder, data, onChange }: FlowEditorLeftPaneSearchProps) {

    return (
        <div className={'c-flow-editor-left-pane-search'}>
            <AutoComplete
                placeholder={placeholder}
                data={data}
                rounded={null}
                onChange={onChange}
                popupSettings={{ popupClass: 'c-flow-editor-left-pane-search__popup' }}
                opened={data.length === 0 ? false : undefined}
                listNoDataRender={() => null}
                prefix={() =>
                    <InputPrefix orientation='horizontal'>
                        <SvgIcon
                            icon={searchIcon}
                        />
                    </InputPrefix>}
            />
        </div>
    );
}

export default FlowEditorLeftPaneSearch;
