import { NdsButton } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { useCallback } from 'react';
import { PortalsApi } from '~api/portals.api';
import Content from '~components/content';
import NavTabs from '~components/nav-tabs';
import { Permission, USER_MANAGEMENT_PORTAL_TABS } from '~constants';
import { UserManagementAction } from '~constants/user-management-action';
import { PortalTableContainer } from '~containers/portal-table';
import { useApi } from '~contexts/api';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { TableContextProvider } from '~contexts/table';
import { FetchTableDataDelegate } from '~contexts/table/table.types';
import useNavTabs from '~hooks/tabs';
import { UserGroupsListItemModel, UserGroupsListTableDataModel } from '~models/user-management.models';
import { UserGroupsTableDefinition } from './table-definition';
import { useUserGroupsTableFilters } from './use-user-groups-table-filter';
import UserGroupsViewQuickActions from './user-groups-view-quick-actions/user-groups-view-quick-actions';
import useFilterTabConditions from '../hooks/useFilterTabConditions';

export interface UserGroupsPortalViewProps { }

const UserGroupsPortalView = () => {
    const portalsApi = useApi(PortalsApi);
    const { t } = useTranslation('user-management');
    const filters = useUserGroupsTableFilters();

    const filterTabConditions = useFilterTabConditions();
    const userCanCreateUserGroup = useHasPermission(Permission.AccessUserManagementPortal_ActionCreateUserGroup);

    const { filteredTabs } = useNavTabs(USER_MANAGEMENT_PORTAL_TABS, filterTabConditions);

    const fetchData: FetchTableDataDelegate<UserGroupsListTableDataModel> = useCallback(async (params, signal?: AbortSignal) => {
        return portalsApi.getUserGroups({ ...params }, signal);
    }, [portalsApi]);

    const onClickQuickAction = useCallback((data: UserGroupsListItemModel, action: UserManagementAction) => {
        return;
    }, []);

    return (
        <Content noDefaultPadding>
            <TableContextProvider<'userGroupId', UserGroupsListTableDataModel>
                definition={UserGroupsTableDefinition}
                fetchData={fetchData}>
                <PortalTableContainer<'userGroupId', UserGroupsListTableDataModel>
                    tabs={<NavTabs navTabs={filteredTabs} />}
                    pageTitle={t('user-management-portal-view-title-name')}
                    filtersDefinition={filters}
                    headerButton={userCanCreateUserGroup && <NdsButton label={t('user-groups-portal-header-button')} size={SizesEnums.SMALL} />}
                    renderColumns={(data) => {
                        return {
                            name: data.name,
                            action: <UserGroupsViewQuickActions
                                data={data}
                                onClickQuickAction={onClickQuickAction}
                            />,
                        };
                    }}
                />
            </TableContextProvider>
        </Content>
    );
};

export default UserGroupsPortalView;
