import { LanguageDataLabel } from '~models';
import { BaseRoutes, NestedRoutes } from './routes';

export enum UserManagementTabsEnum {
  Profile = 'Profile',
  UserGroups = 'UserGroups',
  Signature = 'Signature',
  OutOfOffice = 'OutOfOffice'
}

export const UserManagementTabsI18n: Record<UserManagementTabsEnum, LanguageDataLabel<'user-management'>> = {
    [UserManagementTabsEnum.Profile]: 'user-management-edit-user-panel-tab-profile',
    [UserManagementTabsEnum.UserGroups]: 'user-management-edit-user-panel-tab-user-groups',
    [UserManagementTabsEnum.Signature]: 'user-management-edit-user-panel-tab-signature',
    [UserManagementTabsEnum.OutOfOffice]: 'user-management-edit-user-panel-tab-out-of-office',
};

export enum UserManagementPortalTabsEnum {
    Users = 'Users',
    UserGroups = 'UserGroups',
    DocumentGroups = 'DocumentGroups'
  }

export const editUserPanelTabs = [
    {
        key: UserManagementTabsEnum.Profile,
        i18n: {
            ns: 'user-management',
            key: UserManagementTabsI18n.Profile,
        },
    },
    {
        key: UserManagementTabsEnum.UserGroups,
        i18n: {
            ns: 'user-management',
            key: UserManagementTabsI18n.UserGroups,
        },
    },
    {
        key: UserManagementTabsEnum.Signature,
        i18n: {
            ns: 'user-management',
            key: UserManagementTabsI18n.Signature,
        },
    },
    {
        key: UserManagementTabsEnum.OutOfOffice,
        i18n: {
            ns: 'user-management',
            key: UserManagementTabsI18n.OutOfOffice,
        },
    },
];

export const USER_MANAGEMENT_PORTAL_TABS = [
    {
        key: UserManagementPortalTabsEnum.Users,
        i18n: {
            ns: 'user-management',
            key: 'user-management-portal-users-nav-tab',
        },
        url: BaseRoutes.Settings + '/' + NestedRoutes.Users,
    },
    {
        key: UserManagementPortalTabsEnum.UserGroups,
        i18n: {
            ns: 'user-management',
            key: 'user-management-portal-user-groups-nav-tab',
        },
        url: BaseRoutes.Settings + '/' + NestedRoutes.UserGroups,
    },
    {
        key: UserManagementPortalTabsEnum.DocumentGroups,
        i18n: {
            ns: 'user-management',
            key: 'user-management-portal-document-groups-nav-tab',
        },
        url: BaseRoutes.Settings + '/' + NestedRoutes.DocumentGroups,
    },
];
