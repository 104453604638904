import { createContext } from 'react';
import { PermissionCodes } from '~constants';
import { AbsencePeriod, DocumentGroup, UserInfo } from './auth.types';

export type AuthContextType = {
    isAuthenticated: boolean;
    loginUser: (email: string, password: string, signal?: AbortSignal) => Promise<boolean>;
    logoutUser: (signal?: AbortSignal) => Promise<boolean>;
    absences: AbsencePeriod[],
    documentGroups: DocumentGroup[],
    info: UserInfo | null,
    permissions: PermissionCodes,
}

export const AuthContext = createContext<AuthContextType | null>(null);

export default AuthContext;