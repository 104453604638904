import { NdsIconFont } from '@gonitro/rcl';
import './flowEditorApproverProperties.scss';

export interface FlowEditorApproverPropertiesProps {

}

function FlowEditorApproverProperties(props: FlowEditorApproverPropertiesProps) {
    return (
        <div className={'c-flow-editor-approver-properties c-flow-editor-right-pane-component'}>
            flow-editor-approver-properties
        </div>
    );
}

FlowEditorApproverProperties.Header = () => {
    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                <NdsIconFont fontName={'fa-regular-circle-check'} />
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                Approval
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}></div>
        </div>
    );
};
export default FlowEditorApproverProperties;
