import { useContext, useMemo } from 'react';
import { AppContext } from './app.context';
import { Workspace } from './app.types';

export const useAppContext = () => {
    return useContext(AppContext);
};

export const useNavWorkspaces = () => {
    const {
        expandedNavWorkspaces,
        toggleNavWorkspace,
        myWorkspace,
        workspaces,
        sharedWorkspaces,
    } = useContext(AppContext);

    return {
        expandedNavWorkspaces,
        toggleNavWorkspace,
        myWorkspace: myWorkspace,
        workspaces: workspaces,
        sharedWorkspaces: sharedWorkspaces,
    };
};

export const useWorkspaceId = (): number | undefined => {
    const { actualWorkspaceId } = useContext(AppContext);

    return actualWorkspaceId;
};

export const useActualWorkspace = (): Workspace | undefined => {
    const { actualWorkspaceId, workspaces } = useContext(AppContext);

    return workspaces.find(({ workspaceId }) => workspaceId === actualWorkspaceId);
};

export const useIsFeatureEnabled = (featureName: string) => {
    const { features } = useContext(AppContext);

    return useMemo(() => features.find((f) => f.name === featureName), [features, featureName]);
};

export const useLastWorkspaceId = () => {
    return useContext(AppContext).lastWorkspaceId;
};
