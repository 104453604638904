import { NdsIconFont } from '@gonitro/rcl';
import './flowEditorRadioFieldProperties.scss';

export interface FlowEditorRadioFieldPropertiesProps {

}

function FlowEditorRadioFieldProperties(props: FlowEditorRadioFieldPropertiesProps) {
    return (
        <div className={'c-flow-editor-radio-field-properties c-flow-editor-right-pane-component'}>
            flow-editor-radio-field-properties
        </div>
    );
}

FlowEditorRadioFieldProperties.Header = () => {
    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                <NdsIconFont fontName={'fa-regular-circle-dot'} />
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                Radio group
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}></div>
        </div>
    );
};
export default FlowEditorRadioFieldProperties;
